import React from "react";
import FaqTab from "../../Components/FaqTab/FaqTab";
import styles from "./styles/Faq.module.css";
function Faq() {
  const faqs = [
    {
      question: "What is RealtySwap about?",
      answer:
        "Realty Swap aims to simplify the listing of Homes for Sale on the BlockChain.With Realty Swap, you can register your home on the blockchain and start accepting offers in minutes. It’s the perfect way to find your dream home or invest in a property.",
    },
    {
      question: "Why should I Create an  NFT for my Property",
      answer:
        "Do you own a home ?. Then dont miss out the new WEB3.0 Revolution of Registering physical assets on the BlockChain.RealtySwap is a decentralized market place for NFT (Non-Fungible Tokens) created on the Ethereum blockchain with support for other Major Chains in the works",
        },
    {
      question: "Can I list my home as an NFT?",
      answer:
        "Yes you can. With only a few clicks, we make it easy to register on the BloackChain.Keep in mind that selling the NFT rights will not grant the buyer access to sellers physical property.Legal Closing of the Property will be done off chain . It's advisable to confirm what your state laws are.",
    },
    {
      question: "How do I create an NFT digital asset for my property",
      answer:
      "Its as easy as 1.2.3. Once your wallet is connected, Click on 'Create' an NFT. Enter your property address. We will pull all publicly available information and your can edit the information as needed. Once you are ready go ahead and Click Create. Validate transaction via your wallet.And you are good to go.",
      },
      {
      question: "How do I connect my wallet",
      answer:
      "Once your crypto wallet is set up, connecting it to RealtySwap is easy. Click the wallet icon in the top right corner of the RealtySwap site and it’ll walk you through connecting your wallet. From here, you can fill out the rest of your RealtySwap profile.Any time you try to take an action that gets recorded on the blockchain, like purchasing an NFT, you’ll be prompted to connect your wallet and authorize the action. You must have your wallet connected in order to buy, sell, create, or transfer NFTs using RealtySwap.There is a feature at the top right corner that enables you to connect your wallet",
      },
      {
      question: "How do I list my NFT?",
      answer:
      "Once you have minted your NFT, you as an owner of the NFT can list your NFT for Sale. You can set a minimum bidding price at which you can start accepting offers."
      },
      {
        question: "How do I Bid for an  NFT?",
        answer:
        "NFT's listed for sale will have the Make An Offer Listing available. You can then start placing bids. This does not gurantee Ownership of the Physical Property. This only sends offers to Sellers. Seller has the right to accept offer and close the Listing. Final closing will happen offchain and outside of the Platform based on Governing Laws of the Land"
        },
  ];
  return (
    <div className="container">
      <div className={styles.faq}>
        <h1>Frequently Asked Questions</h1>
        <h2>
          Have more questions about our plans? Reach out to our support team
          today.
        </h2>
        <div className={styles.faqCol}>
          {faqs.map((elem, idx) => {
            return <FaqTab {...elem} key={"faq" + idx} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
