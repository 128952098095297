import React, { useEffect, useState } from "react";
import Select from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUnlock,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../../pinata";
//import Marketplace from "../../REALTYSWAPMarketPlace.json";
import Marketplace from "../../RealtySwap.json";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import isEmpty from "../../utils/is-empty";
import styles from "./styles/Create.module.css";
import Card from "../../Components/Card/Card";
import cardBg from "../../Assets/card-img.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import NotifBox from "../../Components/NotifBox/NotifBox";
import ReactGA from 'react-ga4';

function Create() {


  const [toggleSwitchBid, setToggleSwitchBid] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [selectedImagesFiles, setSelectedImagesFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(false);
  const [nftAddress, setNftAddress] = useState(false);
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");
  const [dropdownContent, setDropdownContent] = useState("Single Family");
  const [progress, setProgress] = useState("");
  const [formParams, updateFormParams] = useState({
    name: "",
    description: "",
    price: "",
    beds: "",
    bath: "",
    sqft: "",
    lotSqft: "",
    yearBuilt: "",
    externalLink: "",
    
  });
  const [fileURLs, setFileURLs] = useState([]);
  const ethers = require("ethers");
  const [message, updateMessage] = useState("");

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [placeAddress, setPlaceAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const { address } = useSelector((state) => state.wallet);
 
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (!isEmpty(address) && address !== "0x") {
     axios
        .get("/profile/" + address)
        .then((res) => {
          //setUserState(res.data);
          if(isEmpty(res.data)){
            toast("Please Create a Profile first by Visting Profile Page.");
            navigate("/Profile");
          }
        })
        .catch((err) => {
          console.log(err);
         toast.error(
            "Uh Oh! Something went wrong while fetching your account"
          );
        })
        .finally(() => {
       
        });
    }
  }, []);

 
  const navigate = useNavigate();
  //This function uploads the NFT image to IPFS
  async function OnChangeFile(e) {
    var { files } = e.target;
    //check for file extension
    setSelectedImagesFiles(files);
    const filesArr = [];
    Array.from(files).forEach((el) => {
      filesArr.push(URL.createObjectURL(el));
    });

    setSelectedImages(filesArr);
  }

  const onImgsUpload = async () => {
    try {
      setProgress("0%");
      // upload the file to IParFS
      setIsFormLoading(true);

      const imgsArr = Array.from(selectedImagesFiles);
      let i = 1;
      for (const file of imgsArr) {
        try {
          const response = await uploadFileToIPFS(file, address);
          if (response.success === true) {
            console.log("Uploaded image to Pinata: ", response.url);
            setFileURLs((prevState) => [...prevState, response.url]);
            // setUploadedImages(URL.createObjectURL(e.target.files[0]));
          }
          setProgress(`${parseFloat((20 / imgsArr.length) * i).toFixed(2)}%`);
        } catch (err) {
          console.log("Error during file upload", err);
        }

        i++;
      }
      toast("Images Uploaded Succesfully!!");
      // Array.from(selectedImagesFiles).forEach(async (file) => {
      // });
    } catch (e) {
      console.log("Error during file upload", e);
    } finally {
      setIsFormLoading(false);
    }
  };

  // async function OnChangeFile(e) {
  //   var file = e.target.files[0];
  //   //check for file extension

  //   try {
  //     setProgress("");
  //     //upload the file to IParFS
  //     setIsFormLoading(true);
  //     const response = await uploadFileToIPFS(file, address);
  //     if (response.success === true) {
  //       // console.log("Uploaded image to Pinata: ", response.url);
  //       setFileURL(response.url);

  //       setUploadedImage(URL.createObjectURL(e.target.files[0]));
  //     }
  //   } catch (e) {
  //     console.log("Error during file upload", e);
  //   } finally {
  //     setIsFormLoading(false);
  //   }
  // }

  //This function uploads the metadata to IPFS
  async function uploadMetadataToIPFS() {
    const { name, description, price } = formParams;
    //Make sure that none of the fields are empty
    // if (!name || !description || !price || !fileURL) return;
    if (isEmpty(fileURLs)) {
      toast("Image URL Not Valid. Please select a Valid Image");
      return;
    }

    if (!formParams.beds) {
      toast("Please enter a Valid Value for Beds");
      return;
    }
    const nftJSON = {
      name,
      description,
      price,
      image: fileURLs[0],
      propertyDtls: {
        attributes: {
          beds: formParams.beds,
          bath: formParams.bath,
          sqft: formParams.sqft,
          lotSqft: formParams.lotSqft,
          yearBuilt: formParams.yearBuilt,
          type: dropdownContent,
        },
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        address: nftAddress,
        origin: "RSWAP",
        creator: address
      },
    };

    try {
      //upload the metadata JSON to IPFS
      const response = await uploadJSONToIPFS(nftJSON);
      if (response.success === true) {
        // console.log("Uploaded JSON to Pinata: ", response);
        return response.pinataURL;
      }
    } catch (e) {
      console.log("error uploading JSON metadata:", e);
    }
  }

  async function listNFT(e) {
    e.preventDefault();
    setIsFormLoading(true);
    setProgress("20%");
    //Upload data to IPFS
    try {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const network = await provider.getNetwork();
      const networkName = network.name;
      if(networkName != "homestead"){
        setNotif("failed");
        setMessage("Thank you for Visiting us. Please connect wallet to Ethereum Mainnet!!");
        return;
      }
      if (isEmpty(fileURLs)) {
          toast("Image URL Not Valid. Please  Upload a Valid Image to Proceed");
          return;
      }
      const metadataURL = await uploadMetadataToIPFS();
      updateMessage("Please wait.. uploading (upto 5 mins)");
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );
      //massage the params to be sent to the create NFT request
      let mintingPrice = await contract.getMintPrice();
      mintingPrice = mintingPrice.toString();
      //actually create the NFT
      let transaction = await contract.mint(address, metadataURL, {
        value: mintingPrice,
      });
      setProgress("50%");
      //sending the nft to the server for it to be stored
      let txReceipt = await transaction.wait();
      console.log(txReceipt.transactionHash);
      const [transferEvent] = txReceipt.events;
      const { tokenId } = transferEvent.args;
      var chainLink = "";
      chainLink ="https://etherscan.io/tx/" + txReceipt.transactionHash;
   
      //let trans = await contract.getLatestIdToListedToken();
      const mintedTokenId = parseInt(tokenId._hex, 16);
     const data = {
        address: nftAddress,
        bath: formParams.bath,
        beds: formParams.beds,
        blockExplorerLink: chainLink,
        city: "",
        contractId: contract.address,
        description: formParams.description,
        imageUrl: fileURLs[0],
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        lotSqft: formParams.lotSqft,
        name: formParams.name,
        propId: "0",
        shortDescription: "",
        sqft: formParams.sqft,
        state: "",
        tokenId: mintedTokenId,
        type: dropdownContent,
        walletAddress: address,
        walletChain: "ether",
        yearBuilt: formParams.yearBuilt,
        ipfs: metadataURL,
        externalLink: formParams.externalLink,
        displayImageUrl: fileURLs,
        txHash: txReceipt.transactionHash
      };
      setProgress("70%");

      try {
        await axios.post("/asset", data, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              console.log(progressEvent.loaded + " " + progressEvent.total);
              //this.updateProgressBarValue(progressEvent);
            }
          },
        });
      } catch (err) {
        console.log("Error Message " + err);
        setNotif("failed");
        setMessage( "Uh Oh! Something went wrong while storing asset to our servers after creation");
        return;
      } finally {
        setProgress("100%");
      }
      // .then((res) => {
      //   // console.log(res.data);
      //   // window.location.replace("/");
      //   //toast.success("Successfully listed your NFT!");
      //   //navigate("/");
      //   setProgress("100%");
      // })
      // .catch((err) => {
      //   console.log(err);
      //   toast.error(
      //     "Uh Oh! Something went wrong while storing asset to our servers after creation"
      //   );
      // });
      setNotif("success");
      setMessage("Successfully Minted your NFT on the BlockChain!");
      updateMessage("");
      updateFormParams({
        name: "",
        description: "",
        price: "",
        beds: "",
        bath: "",
        lotSqft: "",
        sqft: "",
        yearBuilt: "",
      });
      //navigate("/Profile");
    } catch (e) {
      setNotif("failed");
      setMessage("Unable to List NFT on the MarketPlace!.Pls try agian Later");
     
    } finally {
      setIsFormLoading(false);
      setProgress("");
     }

  }
  // when user selects an address from the drop down this api gets the data for the nft of that address ------------
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setPlaceAddress(value);
    setCoordinates(ll);
    setNftAddress(results[0].formatted_address);

    setIsFormLoading(true);

    try {
      const res = await axios.post("/propertyInfo", {
        addressLine: results[0].formatted_address,
        requestType: "BY_ADDRESS_LINE",
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      });
      if (res.data.propertyList.length > 0) {
        const filesArr = [];
        if(res.data.propertyList[0].asset){
          setNotif("failed");
          setMessage("Asset with Address Id already Registered on MarketPlace!.Pls reach out to Support.");
          updateFormParams({
            name: "",
            description: "",
            price: "",
            beds: "",
            bath: "",
            lotSqft: "",
            sqft: "",
            yearBuilt: "",
          });
          setSelectedImages([]);
          return;
        }
        setUploadedImages(res.data.propertyList[0].images[0]);
         filesArr.push(res.data.propertyList[0].images[0]);

        setSelectedImages(filesArr);
        // setUploadedImage(res.data.propertyList[0].images[0]);
        fileURLs.push(res.data.propertyList[0].images[0]);
        setFileURLs((prevState) => [
          ...prevState,
          res.data.propertyList[0].images[0],
        ]);
        updateFormParams((prevState) => {
          return {
            ...prevState,
            beds: res.data.propertyList[0].bedrooms,
            bath: res.data.propertyList[0].bathrooms,
            description: res.data.propertyList[0].description,
            yearBuilt: res.data.propertyList[0].yearBuilt,
            // name: res.data.propertyList[0].streetAddress,
            name: results[0].formatted_address,
          };
        });
        setDropdownContent(res.data.propertyList[0]?.homeType);
        setDropdownContent("Single Family");
      } else {
        setDropdownContent("Single Family");
        setUploadedImages("");
        // setUploadedImage("");
        updateFormParams({
          name: "",
          description: "",
          price: "",
          beds: "",
          bath: "",
          lotSqft: "",
          sqft: "",
          yearBuilt: "",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsFormLoading(false);
    }
  };

  
  return (
    <div
      style={{ marginBottom: "150px", minHeight: "100vh" }}
      className="container"
    >
      {isFormLoading && <Loading progress={progress} />}
      <div className={styles.createHeader}>
        <h1>Create New NFT.</h1>
      </div>
      {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }
      <div className={styles.createBottom}>
        <div className={styles.leftBottom}>
          <form onSubmit={listNFT}>
            <PlacesAutocomplete
              value={placeAddress}
              onChange={setPlaceAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className={`${styles.inputCol} ${styles.relative}`}>
                  <div className={styles.inputCol}>
                    <h2>Home Address</h2>
                    <input
                      {...getInputProps({
                        placeholder: "Enter your Home Address ...",
                        className: "location-search-input",
                      })}
                      required
                    />
                  </div>

                  {suggestions.length > 0 && (
                    <div
                      style={{ zIndex: 6, height: "auto" }}
                      className={styles.stateDrop}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            // className={styles.searchedItem}
                            key={suggestion.id}
                            {...getSuggestionItemProps(suggestion, {
                              className: `${className} ${styles.searchedItem}`,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>


            {!isEmpty(fileURLs) || !isEmpty(selectedImages) ? (
              <>
                {" "}
                <div className={styles.selectedImgsList}>
                  {selectedImages.map((el, idx) => {
                    return (
                      <img
                        key={"selected-img" + idx}
                        // className={}
                        src={el}
                        alt="uploaded"
                      />
                    );
                  })}
                </div>
                <div className={styles.uploadBtn}>
                  <button
                    type={"button"}
                    className="cta-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setFileURLs([]);
                      setSelectedImages([]);
                      setSelectedImagesFiles([]);
                    }}
                  >
                    Discard
                  </button>
                  <> </>
                  <button
                    type={"button"}
                    className="cta-btn"
                    onClick={onImgsUpload}
                  >
                    Upload
                  </button>
                </div>
              </>
            ) : (
              // {fileURL || uploadedImage ? (
              //   <div className="w-100 p-relative">
              //     <img
              //       className={styles.uploadedImage}
              //       src={uploadedImage}
              //       alt="uploaded"
              //     />
              //     <button
              //       className="cta-btn centered"
              //       onClick={(e) => {
              //         e.preventDefault();
              //         setFileURL("");
              //         setUploadedImage("");
              //       }}
              //     >
              //       DISCARD
              //     </button>
              //   </div>
              // ) : (
              <div className={styles.inputCol}>
                <h2 htmlFor="upload">Upload File(s)</h2>
                <input
                  multiple
                  onChange={OnChangeFile}
                  id="upload"
                  type="file"
                  className={styles.file}
                />
                <div className={styles.uploadDiv}>
                  <h4>PNG, JPG, GIF or WEBP. Max 200mb.</h4>
                  <label htmlFor="upload">
                    <h6 style={{ width: "fit-content" }} className="cta-btn">
                      Browse
                    </h6>
                  </label>
                </div>
              </div>
            )}
            <div className={styles.inputCol}>
              <div className={styles.toggleRow}>
                <div className={styles.leftToggle}>
                  <h2>
                    <FontAwesomeIcon icon={faUnlock} /> Open for Offers
                  </h2>
                  <p>Unlock for listing your Home on the Marketplace.</p>
                </div>
                <label className="switch">
                  <input
                    value={toggleSwitchBid}
                    onChange={() => setToggleSwitchBid((prev) => !prev)}
                    type="checkbox"
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>


            <div className={styles.inputCol}>
              <h2>Title</h2>
              <input
                onChange={(e) =>
                  updateFormParams({ ...formParams, name: e.target.value })
                }
                value={formParams.name}
                type="text"
                required
                placeholder="e.g. 1234 Market Place Road, NY, US 09999"
              />
            </div>
            <div className={styles.inputCol}>
              <h2>Description</h2>
              <textarea
                type="text"
                required
                placeholder="e.g. 'Most Stunning Single Family House'"
                onChange={(e) =>
                  updateFormParams({
                    ...formParams,
                    description: e.target.value,
                  })
                }
                value={formParams.description}
              ></textarea>
            </div>
            <div className={styles.inputCol}>
              <h2>External Link</h2>
              <input
                onChange={(e) =>
                  updateFormParams({
                    ...formParams,
                    externalLink: e.target.value,
                  })
                }
                value={formParams.externalLink}
                type="text"
                placeholder="e.g. External Link"
              />
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputCol}>
                <h2>Beds</h2>
                <input
                  onChange={(e) =>
                    updateFormParams({ ...formParams, beds: e.target.value })
                  }
                  required
                  value={formParams.beds}
                  type="text"
                  placeholder="Beds"
                />
              </div>
              <div className={styles.inputCol}>
                <h2>Bath</h2>
                <input
                  onChange={(e) =>
                    updateFormParams({ ...formParams, bath: e.target.value })
                  }
                  required
                  value={formParams.bath}
                  type="text"
                  placeholder="Bath"
                />
              </div>
              <div className={styles.inputCol}>
                <h2>Sqft</h2>
                <input
                  onChange={(e) =>
                    updateFormParams({ ...formParams, sqft: e.target.value })
                  }
                  value={formParams.sqft}
                  type="text"
                  required
                  placeholder="Sqft"
                />
              </div>
              <div className={styles.inputCol}>
                <h2>LotSqft</h2>
                <input
                  onChange={(e) =>
                    updateFormParams({ ...formParams, lotSqft: e.target.value })
                  }
                  value={formParams.lotSqft}
                  type="text"
                  required
                  placeholder="lotSqft"
                />
              </div>
              <div className={styles.inputCol}>
                <h2>YearBuilt</h2>
                <input
                  onChange={(e) =>
                    updateFormParams({
                      ...formParams,
                      yearBuilt: e.target.value,
                    })
                  }
                  required
                  value={formParams.yearBuilt}
                  type="text"
                  placeholder="YearBuilt"
                />
              </div>
            </div>
            <div className={styles.dropDown}>
              <h6
                onClick={() => {
                  setOpenDrop((prev) => {
                    return !prev;
                  });
                }}
              >
                Type: {dropdownContent} <FontAwesomeIcon icon={faChevronDown} />
              </h6>
              <div
                className={`${styles.mainDrop} ${
                  openDrop && styles.activeDrop
                }`}
              >
                {/* drop down ---------------------------------- */}
                <ul>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Single Family
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Condo
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Townhome
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Single Family
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Multi-Family
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Land
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Mobile/Manufactured
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Other
                  </li>
                 
                </ul>{" "}
                {/* drop down ends---------------------------------- */}
              </div>
            </div>

            <button type="submit" className="cta-btn">
              Create
            </button>
          </form>
        </div>
        <div className={styles.rightBottom}>
          <h1>Preview item</h1>
          <Card
            imageUrl={selectedImages[0] || cardBg}
            bath={formParams.bath}
            beds={formParams.beds}
            sqft={formParams.sqft}
            yearBuilt={formParams.yearBuilt}
            name={formParams.name}
            address={nftAddress}
            pointerNone={true}
          />
        </div>
      </div>
    </div>
  );
}

export default Create;
