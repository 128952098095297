import React from "react";
import Blog from "./Blog";
// import Category from "./Category";
import Hero from "./Hero";
import Links from "./Links";
import Pricing from "./Pricing";
import styles from "./styles/Home.module.css";
import TopCollection from "./TopCollection";
import TrendingCollection from "./TrendingCollection";
import Update from "./Update";
function Home() {
  return (
    <div className={styles.home}>
     
      <Hero />
      <TopCollection />
      <Pricing />
      <TrendingCollection />
   <Blog />
      <Update />
     
      <Links />
    </div>
  );
}

export default Home;
