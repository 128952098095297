import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Links.module.css";

function Links() {
  return (
    <div className={"container links-cont"}>
      <div className={styles.links}>
        <div className={styles.col}>
          <h2>MarketPlace</h2>
          <Link to="/Explore">All NFT's</Link>
           <Link to="/Pricing">Pricing</Link>
           <Link to="/Explore">Explore</Link>
          <a href="#">Trending</a>
          <a href="#">Top Collection</a>
         
         </div>
        <div className={styles.col}>
          <h2>My Profile</h2>
          <Link to="/Profile">Account Settings</Link>
          <Link to="/Profile">Minted NFT's</Link>
          <Link to="/Profile">Activity</Link>
           <Link to="/Contact">Message</Link>
        </div>
        <div className={styles.col}>
          <h2>Resources</h2>
          <Link to="/Contact">Contact Us</Link>
          <a href="#">About Us</a>
          <a href="/terms">Terms</a>
          <Link to="/house-on-blockchain">Blog</Link>
          <a href="#">NewsLetter</a>
      
        </div>
        <div className={styles.col}>
          <h2>NewsLetter</h2>
          <p>Signup for our newsletter to get the latest news in your inbox.</p>
          <div className={styles.inputDiv}>
            <input type="text" placeholder="enter your email" />
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <p>Your email is safe with us. We don't spam.</p>
        </div>
      </div>
    </div>
  );
}

export default Links;
