import React from "react";
import { Link } from "react-router-dom";
import PriceBox from "../../Components/PriceBox/PriceBox";
import styles from "./styles/Pricing.module.css";
import useAnalyticsEventTracker from "../../Components/Loading/useAnalyticsEventTracker";

function Pricing() {
  const gaEventTracker = useAnalyticsEventTracker('Pricing');
  gaEventTracker("Pricing","Pricing");
  return (
    <div className="container">
      <div className={styles.pricing}>
        <div className={styles.row}>
          <PriceBox
            head={"Transparent"}
            desc={"Real Estate Transactions Made Simpler and Transparent."}
          />
          <PriceBox
            head={"Secure"}
            desc={"Block Chain Advantage for Real Estate with Security."}
          />
          <PriceBox
            head={"Tokenized"}
            desc={"Modern makeover with the use of #NFTs."}
           />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
