import React from "react";
import styles from "./styles/Comp.module.css";
import comp from "../../Assets/comp.png";
function Comp() {
  return (
    <div className="container">
      <div className={styles.comp}>
        <h1>Realty Swap is an exciting new NFT marketplace for real estate listings. It allows sellers to list their property on the blockchain and accept offers  </h1>
        <div className={styles.imgRow}>
       
        </div>
      </div>
    </div>
  );
}

export default Comp;
