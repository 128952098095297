import styles from "./MakeOffer.module.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import Marketplace from "../../RealtySwap.json";
import Loading from "../Loading/Loading";
import img from "../../Assets/review-collection.webp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NotifBox from "../../Components/NotifBox/NotifBox";
import isEmpty from "../../utils/is-empty";

const MakeOffer = ({
  closeModal,
  id,
  ownerAddress,
  name,
  contract,
  tokenId,
  propAddressId,
  imageUrl,
  minBidPrice
}) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [termsCheckBox, setTermsCheckBox] = useState(false);
  const [openDrop1, setOpenDrop1] = useState(false);
  const [openDrop2, setOpenDrop2] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [dropVal1, setDropVal1] = useState("ETH");
  const [dropVal2, setDropVal2] = useState("3 Days");
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");
  const ethers = require("ethers");
  const [progress, setProgress] = useState("");
  const [balance, setBalance] = useState("");
  const [formParams, updateFormParams] = useState({
    offerPrice: 0,
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    if (!walletAddress) {
      toast.error("Please connect your MetaMask Wallet");
      return;
    }
    window.ethereum.request({
      method:'eth_getBalance', 
      params: [walletAddress, 'latest']
  }).then(balance => {
      // Return string value to convert it into int balance
      setBalance(ethers.utils.formatEther(balance)) 
      // Yarn add ethers for using ethers utils or
      // npm install ethers
      // Format the string into main latest balance
  })

    if (!isEmpty(walletAddress) && walletAddress !== "0x") {
     axios
        .get("/profile/" + walletAddress)
        .then((res) => {
          //setUserState(res.data);
          if(isEmpty(res.data)){
            toast("Please Create a Profile first by Visting Profile Page.");
            navigate("/Profile");
          }
        })
        .catch((err) => {
          console.log(err);
         toast.error(
            "Uh Oh! Something went wrong while fetching your account"
          );
        })
        .finally(() => {
       
        });
    }
  }, []);

  async function makeOffer(e) {
    e.preventDefault();
    if (!walletAddress) {
      toast.error("Please connect your MetaMask Wallet");
      return;
    }

    try {
       
      setProgress("20%");
      setIsFormLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      console.log(signer.getAddress);
      //Pull the deployed contract instance
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );
      setProgress("30%");
   
      try {

        if(formParams.offerPrice <=0){
          setNotif("failed");
          setMessage("Please Enter Valid Bid Amount");
          return;
        }
        const bidPrice = ethers.utils.parseUnits(
          formParams.offerPrice,
          "ether"
        );
        let transaction = await contract.bid(tokenId , {
          value: bidPrice,
        });
        let txReceipt = await transaction.wait();
        const [bidEvent] = txReceipt.events;
        const { bidder, amount } = bidEvent.args;
        setProgress("50%");
      } catch (error) {
        // Catch any errors for any of the above operations.
        const endIndex = error.message.search("{");
        if (endIndex >= 0) {
          setNotif("failed");
          setMessage("Unable to List NFT on the MarketPlace!.Pls try agian Later.(Higher Bid Exists) " + error.code);
        }
        return;
      }
      setProgress("70%");
      axios
        .post("/asset/offers", {
          fromWalletAddress: walletAddress,
          offerExpires: "2022-10-03T05:56:39.539Z",
          offerPrice: formParams.offerPrice,
          offerPriceChain: "ETH",
          offerStatus: "BID",
          ownerWalletAddress: ownerAddress,
          tokenId: tokenId,
          propAddressId: propAddressId,
          imageUrl:imageUrl
        })
        .then(() => {
          setNotif("success");
          setMessage("Offer Placed Successfully");
        })
        .catch(() => {
          setNotif("failed");
          setMessage("Unable to Place Bid on NFT.Pls try agian Later");
        })
        .finally(() => {
          updateFormParams({
            offerPrice: "",
            startTime: "",
            endTime: "",
          });
        });
        setProgress("100%");

      //closeModal(false);
    } catch (e) {
      console.log("Unable to Accept Offer", e);
      setNotif("failed");
      setMessage("Unable to Place Bid on NFT on the MarketPlace!.Pls try agian Later");
    } finally {
      setIsFormLoading(false);
      //navigate("/");
    }
  }

  return (
    <>
  {isFormLoading && <Loading progress={progress} />}
        {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }
      <div className={styles.overlay}></div>
      {termsCheckBox ? (
        <div className={styles.offerDiv}>
          <div className={styles.header}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setTermsCheckBox(false)}
            />
            <h2>Make an Offer</h2>
            <FontAwesomeIcon onClick={() => closeModal(false)} icon={faXmark} />
          </div>
          <div className={styles.inputCol}>
            <div className={styles.inputRow}>
              <h5>Offer Amount</h5>
              <p>Balance: {balance} ETH</p>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.dropDown}>
                <button
                  onClick={() => {
                    setOpenDrop1((prev) => {
                      return !prev;
                    });
                  }}
                >
                  <p>
                    <FontAwesomeIcon icon={faEthereum} /> {dropVal1}
                  </p>{" "}
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div
                  className={`${styles.mainDrop} ${
                    openDrop1 && styles.activeDrop
                  }`}
                >
                  <ul>
                    <li
                      onClick={(e) => {
                        setDropVal1(e.target.innerText);
                        setOpenDrop1(false);
                      }}
                    >
                      ETH
                    </li>
                   
                  </ul>
                </div>
              </div>
              <input
                onChange={(e) =>
                  updateFormParams((prev) => {
                    return { ...prev, offerPrice: e.target.value };
                  })
                }
                value={formParams.offerPrice}
                type="number"
                placeholder="Amount"
              />
            </div>
            <p>Min Bid amount: {minBidPrice} ETH</p>
          </div>
          <div className={styles.inputCol}>
            <div className={styles.inputRow}>
              <h5>Offer Expiration</h5>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.dropDown}>
                <button
                  onClick={() => {
                    setOpenDrop2((prev) => {
                      return !prev;
                    });
                  }}
                >
                  <p>{dropVal2}</p> <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div
                  className={`${styles.mainDrop} ${
                    openDrop2 && styles.activeDrop
                  }`}
                >
                  <ul>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      12 Hours
                    </li>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      1 Day
                    </li>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      3 Days
                    </li>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      7 Days
                    </li>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      1 Month
                    </li>
                    <li
                      onClick={(e) => {
                        setDropVal2(e.target.innerText);
                        setOpenDrop2(false);
                      }}
                    >
                      Custom Date
                    </li>
                  </ul>
                </div>
              </div>
              <input type={"datetime-local"} />
            </div>
          </div>
          <button onClick={makeOffer} className="cta-btn">
            Make Offer
          </button>
        </div>
      ) : (
        <div className={styles.makeOffer}>
          <FontAwesomeIcon
            className={styles.close}
            icon={faXmark}
            onClick={() => closeModal(false)}
          />
          <h2>This is an unreviewed collection</h2>
          <img src={img} alt="review" />
          <p>
            Review this information to ensure it’s what you want to buy.{" "}
            <FontAwesomeIcon icon={faInfoCircle} />
          </p>
          <div className={styles.infoTable}>
            <div className={styles.tableRow}>
              <h3>Property Address</h3>
              <a href="#">{name}</a>
            </div>
            <div className={styles.tableRow}>
              <h3>Creator</h3>

              <a
                style={{
                  maxWidth: "205px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                href="#"
              >
                {ownerAddress}
              </a>
            </div>

            {showMore && (
              <>
                <div className={styles.tableRow}>
                  <h3>Contract address</h3>
                  <a
                    style={{
                      maxWidth: "205px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    href="#"
                  >
                    {contract}
                  </a>
                </div>
              </>
            )}
            <button
              onClick={() => setShowMore((prev) => !prev)}
              className={styles.show}
            >
              Show {showMore ? "Less" : "More"}
            </button>
          </div>
          <div className={styles.inputDiv}>
            <input
              value={termsCheckBox}
              onChange={(e) => setTermsCheckBox(e.target.checked)}
              type="checkbox"
              id="terms"
            />
            <label htmlFor="terms">
              I understand that RealtySwap has not reviewed this NFT and
              blockchain transactions are irreversible.<br></br>
              Offer placed does not give rights to claim ownership of the Physical
              Property. Seller will contact Bidder once approved and start the Legal Process off-chain as per rule of the Land
              I hearby abide with the Terms Represented on this Website.
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default MakeOffer;
