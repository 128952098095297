import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import logo from "../../Assets/logo.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { setWalletAddress } from "../../redux/walletReducer";

function Header() {
  const navigate = useNavigate();
  const [placeAddress, setPlaceAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  // when user selects an address from the drop down this api gets the data for the nft of that address ------------
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setPlaceAddress(value);
    setCoordinates(ll);

    navigate(`/Explore/${ll.lat}/${ll.lng}`);
  };

  const location = useLocation();
  const [scrollBg, setScrollBg] = useState(false);
  const [openHeader, setOpenHeader] = useState(false);
  const [connected, toggleConnect] = useState(false);
  const [currAddress, updateAddress] = useState("0x");
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.wallet);

  function getAddress() {
    const ethers = require("ethers");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    signer
      .getAddress()
      .then((res) => {
        updateAddress(res);
        dispatch(setWalletAddress(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function connectWebsite() {
    if (null != window.ethereum) {
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
     if (chainId !== "0x1") {
        //alert('Incorrect network! Switch your metamask network to Rinkeby');
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
      }
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(() => {
          // updateButton();
          // console.log("here");
          getAddress();
          window.location.replace(location.pathname);
        });
    }
  }
  useEffect(() => {
    if (null != window.ethereum) {
      let val = window.ethereum.isConnected();
      if (val) {
        // console.log("here");
        getAddress();
        toggleConnect(val);
      
      }

      window.ethereum.on("accountsChanged", function (accounts) {
        window.location.replace(location.pathname);
      });
    }
  });

  useEffect(() => {
    const scrollEvent = () => {
      if (window.scrollY > 100) {
        setScrollBg(true);
      } else {
        setScrollBg(false);
      }
    };
    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <div className={`${styles.headerCont} ${scrollBg && styles.bgWhite}`}>
      <div
        className={`${styles.overlay} ${openHeader && styles.openOverlay}`}
        onClick={() => setOpenHeader(false)}
      ></div>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
          <PlacesAutocomplete
            value={placeAddress}
            onChange={setPlaceAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className={styles.searchBar}>
                <input
                  type="text"
                  {...getInputProps({
                    placeholder: "search item here",
                    className: "location-search-input",
                  })}
                  placeholder="Search NFT Properties.."
                />

                {suggestions.length > 0 && (
                  <div
                    style={{ zIndex: 6, height: "auto" }}
                    className={styles.stateDrop}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          // className={styles.searchedItem}
                          key={suggestion.id}
                          {...getSuggestionItemProps(suggestion, {
                            className: `${className} ${styles.searchedItem}`,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className={styles.rightHeader}>
          <div
            className={`${styles.navLinks} ${openHeader && styles.openHeader}`}
          >
            <Link onClick={() => setOpenHeader(false)} to={"/Explore"}>
              Explore
            </Link>
            <Link onClick={() => setOpenHeader(false)} to={"/Create"}>
              Create
            </Link>
            <Link onClick={() => setOpenHeader(false)} to={"/Profile"}>
              Profile
            </Link>
            <Link onClick={() => setOpenHeader(false)} to={"/Price"}>
              Pricing
            </Link>
            <Link onClick={() => setOpenHeader(false)} to={"/Token"}>
              Token
            </Link>
            <Link onClick={() => setOpenHeader(false)} to={"/Advertise"}>
              Advertise
            </Link>
          </div>
          {connected ? (
                <button
                id="connectWallet"
                className="cta-btn"
                onClick={connectWebsite}
              >
              Connected
              </button>
          ) : (
            <Link onClick={() => setOpenHeader(false)} to={"/Create"}>
          <button
          id="connectWallet"
          className="cta-btn"
          onClick={connectWebsite}
        >
        Connect Wallet
        </button>
        </Link>
    )}

          
          <FontAwesomeIcon
            onClick={() => setOpenHeader((prev) => !prev)}
            className={styles.mobile}
            icon={openHeader ? faXmark : faBars}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
