import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "./styles/Update.module.css";
import "../../Components/Video/YoutubeEmbed.module.css";
import YoutubeEmbed from "../../Components/Video/YoutubeEmbed";

function Update() {
  const [email, setEmail] = useState("");
  const subscribeHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      return;
    }
    axios
      .post("/asset/message", { type: "SUBSCRIBE", emailId: email })
      .then((res) => {
        toast.success("Subscibed");
      })
      .catch((err) => toast.error("Uh Oh an error occured"))
      .finally(() => {
        setEmail("");
      });
  };
  return (
    <div className="container">
      <div className={styles.update}>
        <h1>Dont miss an update</h1>
        <p>
          Sign up for our newsletter to get the latest real estate blockchain
          updates.
        </p>
        <form
          className={styles.inputDiv}
          onSubmit={(e) => {
            subscribeHandler(e);
          }}
        >
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email address"
          />
          <button type="submit" style={{ display: "none" }}></button>
          <FontAwesomeIcon onClick={subscribeHandler} icon={faArrowRight} />
        </form>
      </div>
     
    </div>

    
  );
}

export default Update;
