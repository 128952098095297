import React, { useState } from "react";
import { useParams } from "react-router";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";

import styles from "./Assets.module.css";
import img from "../../Assets/detailimg.svg";
import agent from "../../Assets/agent.jpg";
import ether from "../../Assets/ether.png";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import isEmpty from "../../utils/is-empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons";
import ReactGA from 'react-ga4';

import {
  faArrowUp,
  faShare,
  faXmark,
  faHeart as solidHeart,
  faPhone,
  faList,
  faArrowUpRightFromSquare,
  faStar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Card from "../../Components/Card/Card";
import { NumericFormat } from "react-number-format";
import cardBg from "../../Assets/card-img.jpg";
import ThumbnailSlider from "../../Components/ThumbnailSlider/ThumbnailSlider";
import MakeOffer from "../../Components/MakeOffer/MakeOffer";
import CancelListing from "../../Components/CancelListing/CancelListing";
import { Link } from "react-router-dom";
import ShareModal from "../../Components/ShareModal/ShareModal";
function Assets() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [gal, setGal] = useState(false);
  const [offer, setOffer] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [suggested, setSuggested] = useState(false);
  const [modalForm, setModalForm] = useState({
    name: "",
    email: "",
    num: "",
    message: "",
  });
  const [liked, setLiked] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const [map, setMap] = useState(null);
  const [share, setShare] = useState(false);
  const OPTIONS = {
    minZoom: 4,
    maxZoom: 18,
    mapTypeId: "satellite",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_API_KEY",
  });
  const likeHandler = () => {
    if (!walletAddress) {
      toast.error("Please connect the wallet");
      return;
    }
    const status = !liked ? 1 : 0;
    axios
      .post("/asset/fav", {status, propAddressId:data?.asset?.propAddressId, walletAddress })
      .then((res) => setLiked((prevState) => !prevState))
      .catch((err) => {});
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 40.348694,
      lng: -74.16496,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  // ss
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    axios
      .get(`/asset/${id}`)
      .then((res) => {
        setData(res.data);
        setCoordinates(() => {
          return {
            lat: +res.data.asset.latitude,
            lng: +res.data.asset.longitude,
          };
        });
      })
      .catch((err) => {
        toast.error("Uh Oh! Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);
  const formHandler = (e) => {
    e.preventDefault();
    axios
      .post("/asset/message", {
        emailId: modalForm.email,
        fromWalletAddress: walletAddress,
        message: modalForm.message,
        name: modalForm.name,
        phNo: modalForm.num,
        toWalletAddress: data.asset.ownerAddress,
        tokenId: id,
      })
      .then((res) => toast.success("Message Sent"))
      .catch((err) => {
        toast.error("Uh Oh! Something went wrong");
      })
      .finally(() => setModal(false));
  };
  useEffect(() => {
    setLiked(data?.asset?.favourite);
  }, [data]);
  useEffect(() => {
    axios
      .post("/assetList?pageNo=0&pageSize=10", { filterType: "ALL" })
      .then((res) => {
        setSuggested(res.data.assetList);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Uh Oh! Something went wrong while fetching data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="container">
      {modal && (
        <>
          <div onClick={() => setModal(false)} className={styles.overlay}></div>
          <div className={styles.modal}>
            <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} />
            <h1>Send Message</h1>
            <form onSubmit={formHandler}>
              <div className={styles.modalDiv}>
                <label htmlFor="name">Full Name</label>
                <input
                  value={modalForm.name}
                  onChange={(e) =>
                    setModalForm((prev) => {
                      return { ...prev, name: e.target.value };
                    })
                  }
                  type="text"
                  id="name"
                  required
                />
              </div>
              <div className={styles.modalDiv}>
                <label htmlFor="email">Email</label>
                <input
                  value={modalForm.email}
                  onChange={(e) =>
                    setModalForm((prev) => {
                      return { ...prev, email: e.target.value };
                    })
                  }
                  type="email"
                  id="email"
                  required
                />
              </div>
              <div className={styles.modalDiv}>
                <label htmlFor="num">Phone Number</label>
                <input
                  value={modalForm.num}
                  onChange={(e) =>
                    setModalForm((prev) => {
                      return { ...prev, num: e.target.value };
                    })
                  }
                  type="number"
                  id="number"
                  required
                />
              </div>
              <div className={styles.modalDiv}>
                <label htmlFor="num">Message</label>
                <textarea
                  value={modalForm.message}
                  onChange={(e) =>
                    setModalForm((prev) => {
                      return { ...prev, message: e.target.value };
                    })
                  }
                  id="message"
                  required
                ></textarea>
              </div>
              <button type="submit" className="cta-btn">
                Send
              </button>
            </form>
          </div>
        </>
      )}
      {gal && (
        <ThumbnailSlider
          images={[...data?.asset?.displayImageUrl]}
          setGal={setGal}
        />
      )}
      {share && (
        <ShareModal
          name={data?.asset?.name}
          bed={data?.asset?.beds}
          bath={data?.asset?.bath}
          img={data?.asset?.imageUrl}
          price={data?.estimate}
          closeModal={setShare}
        />
      )}
      {offer && (
        <MakeOffer
          tokenId={data?.asset?.tokenId}
          ownerAddress={data?.asset?.ownerAddress}
          closeModal={setOffer}
          name={data?.asset?.name}
          contract={data?.asset?.contractId}
          propAddressId={data?.asset?.propAddressId}
          imageUrl={data?.asset?.imageUrl}
          minBidPrice={data?.asset?.minOfferPrice}
        />
      )}
      {cancel && (
        <CancelListing
          tokenId={data?.asset?.tokenId}
          ownerAddress={data?.asset?.ownerAddress}
          closeModal={setCancel}
          name={data?.asset?.name}
          contract={data?.asset?.contractId}
          propAddressId={data?.asset?.propAddressId}
          imageUrl={data?.asset?.imageUrl}
          isListed={data?.asset?.listed}
        />
      )}
      {isLoading && <Loading />}
      {data && (
        <>
          <div className={styles.nftDetail}>
            <div className={styles.topDetailRow}>
              <h1 className={styles.nftName}>{data?.asset?.name}</h1>
              <div className={styles.flexCol}>
                <div className={styles.shareRow}>
                  <FontAwesomeIcon
                    onClick={likeHandler}
                    icon={liked ? solidHeart : faHeart}
                  />
                  <p>Favourite</p>
                  <FontAwesomeIcon
                    icon={faShare}
                    onClick={() => setShare(true)}
                  />
                  <p> Share </p>
                  <a href={data?.asset?.externalLink} target={"blank"}>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                  <p> Link </p>
                
                </div>
                <div className={styles.rightHeader}>
                {walletAddress === data?.asset?.ownerAddress &&
                data?.asset?.listed ? (
                  <button
                    onClick={() => {
                      setCancel(true);
                    }}
                    className="cta-btn"
                  >
                    Cancel Listing
                  </button>
                ) : walletAddress === data?.asset?.ownerAddress &&
                  !data?.asset?.listed && walletAddress ? (
                  <Link  to={`/list/${id}`}>
                   <button className="cta-btn"
                  >
                     List NFT
                  </button>
                  </Link>
                ) : walletAddress !== data?.asset?.ownerAddress &&
                  data?.asset?.listed ? (
                  <button
                    onClick={() => {
                      setOffer(true);
                    }}
                    className="cta-btn"
                  >
                    Make an Offer
                  </button>
                ) : walletAddress !== data?.asset?.ownerAddress &&
                  data?.asset?.mintable ? (
                  <button disabled className="cta-btn">
                    Not Listed
                  </button>
                ) : (
                  <p></p>
                )}
              </div>
              </div>
            </div>
            <div className={styles.imgDiv}>
              <img
                style={{
                  gridColumn:
                    data?.asset?.displayImageUrl[1] &&
                    data?.asset?.displayImageUrl[2]
                      ? "1/2"
                      : "1/3",
                }}
                onClick={() => setGal(true)}
                src={data?.asset?.imageUrl}
                alt=""
              />
              {data?.asset?.displayImageUrl[1] &&
              data?.asset?.displayImageUrl[2] ? (
                <div className={styles.imgCol}>
                  <img
                    onClick={() => setGal(true)}
                    src={data?.asset?.displayImageUrl[1]}
                    alt=""
                  />
                  <img
                    onClick={() => setGal(true)}
                    src={data?.asset?.displayImageUrl[2]}
                    alt=""
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <Splide
              options={{
                rewind: true,
                gap: "0rem",
                perPage: 1,
                type: "loop",
                pagination: true,
                drag: true,
                arrows: false,
                width: "100%",
              }}
              className={styles.mobileSlider}
            >
              <SplideSlide>
                <img
                  onClick={() => setGal(true)}
                  src={data?.asset?.imageUrl}
                  alt=""
                />
              </SplideSlide>
              <SplideSlide>
                <img
                  onClick={() => setGal(true)}
                  src={data?.asset?.imageUrl}
                  alt=""
                />
              </SplideSlide>
              <SplideSlide>
                <img
                  onClick={() => setGal(true)}
                  src={data?.asset?.imageUrl}
                  alt=""
                />
              </SplideSlide>
            </Splide>
            <div className={styles.bottomNft}>
              <div className={styles.descNft}>
                <p className={styles.mainDesc}>{data?.asset?.description}</p>
                <hr className={styles.hr} />
                <h1>NFT Attributes</h1>
                <div className={styles.featureDiv}>
                  <div className={styles.col}>
                    <h3>Latitude</h3>
                    <p>{data?.asset?.latitude}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Longitude</h3>
                    <p>{data?.asset?.longitude}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Type</h3>
                    <p>{data?.asset?.propType}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Sq.Ft</h3>
                    <p>{data?.asset?.sqft}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Built in</h3>
                    <p>{data?.asset?.yearBuilt}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Lot size</h3>
                    <p>{data?.asset?.lotSqft}</p>
                  </div>

                  <div className={styles.col}>
                    <h3>Beds</h3>
                    <p>{data?.asset?.beds}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>Baths</h3>
                    <p>{data?.asset?.bath}</p>
                  </div>
                  <div className={styles.col}>
                    <h3>TokenId</h3>
                    <p>{data?.asset?.tokenId}</p>
                  </div>
                </div>

                <div className={styles.assetMap}>
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      center={{ ...coordinates }}
                      options={OPTIONS}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {/* Child components, such as markers, info windows, etc. */}

                      <Marker
                        position={{
                          lat: +coordinates.lat,
                          lng: +coordinates.lng,
                        }}
                      />
                    </GoogleMap>
                  )}
                </div>

                {!isEmpty(data.priceHistory) && (
                  <div
                    className={`${styles.moreDetails} ${styles.overflowTable}`}
                  >
                    <hr className={styles.hr} />
                    <h2>Price History</h2>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Event Date</th>
                          <th>Event</th>
                          <th>Price</th>
                          <th>Price Per Sqt</th>
                          <th>Rental</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.priceHistory.map((el, idx) => {
                          return (
                            <tr key={"pric-hisgfsw" + idx}>
                              <td>{el.eventDate}</td>
                              <td>{el.event}</td>
                              <td>
                                {" "}
                                <NumericFormat
                                  prefix="$"
                                  value={el.price}
                                  allowLeadingZeros
                                  displayType="text"
                                  thousandSeparator=","
                                />
                              </td>
                              <td>{el.pricePerSqt}</td>
                              <td>{el.rental.toString()}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {!isEmpty(data.taxHistory) && (
                  <div
                    className={`${styles.moreDetails} ${styles.overflowTable}`}
                  >
                    <hr className={styles.hr} />
                    <h2>Tax History</h2>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Event Date</th>
                          <th>Tax Paid</th>
                          <th>Value</th>
                          <th>Value Increase Rate</th>
                          <th>Tax Increase Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.taxHistory.map((el, idx) => {
                          return (
                            <tr key={"tax-history" + idx}>
                              <td>{el.eventDate}</td>
                              <td>
                                <NumericFormat
                                  prefix="$"
                                  value={el.taxPaid}
                                  allowLeadingZeros
                                  displayType="text"
                                  thousandSeparator=","
                                />
                              </td>
                              <td>
                                {" "}
                                <NumericFormat
                                  prefix="$"
                                  value={el.value}
                                  allowLeadingZeros
                                  displayType="text"
                                  thousandSeparator=","
                                />
                              </td>
                              <td>{el.valueIncreaseRate}</td>
                              <td>{el.taxIncreaseRate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {!isEmpty(data.schoolListings) && (
                  <div className={styles.moreDetails}>
                    <hr className={styles.hr} />
                    <h2>Schools</h2>

                    {data.schoolListings.map((el, idx) => {
                      return (
                        <a
                          key={"school" + idx}
                          href={el.link}
                          target={"blank"}
                          className={styles.moreDetailsRow}
                        >
                          <p>
                            {el.name}, {el.grades}, {el.distance}
                          </p>
                          <h3>{el.rating}</h3>
                        </a>
                      );
                    })}
                  </div>
                )}
                <div className={styles.agent}>
                  <h2>Your Personal Guides</h2>

                  <div className={styles.agentRow}>
                    {data.agentListings &&
                      data?.agentListings.map((elem, idx) => {
                        return (
                          <div className={styles.agentBox} key={"agent" + idx}>
                            <img
                              src={
                                elem.bannerImageUrl
                                  ? elem.bannerImageUrl
                                  : agent
                              }
                              alt=""
                            />
                            <h3>{elem.displayName}</h3>
                            <h4>{elem.companyName}</h4>
                            <h4>{elem.serviceType}</h4>
                            <div className={styles.reviews}>
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <p>100 reviews</p>
                            </div>
                            <div className={styles.callRow}>
                              <a target={"blank"} href={`mailto:${elem.emailId}`}>
                                <FontAwesomeIcon icon={faEnvelope} />{" "}
                              </a>
                              <a target={"blank"} href={`tel:${elem.phNo}`}>
                                <FontAwesomeIcon icon={faPhone} />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={styles.stickyBox}>
                <div className={styles.boxWrapper}>
                  {!data?.asset?.mintable ? (
                    <>
                       <div className={styles.owner}>
                        <img src={img} alt="" />
                        <div className={styles.ownerDetails}>
                          <h2>Current Owner</h2>
                          <p><i>{data?.asset?.ownerAddress?data?.asset?.ownerAddress:"None"}</i></p>
                        </div>
                      </div>
                      <div className={styles.listOwner}>
                        <a href="#" target={"blank"}>
                          <FontAwesomeIcon icon={faEthereum} /> Ethereum
                          (ERC-721)
                        </a>
                        <a
                          href={data?.asset?.blockexplorerlink}
                          target={"blank"}
                        >
                          <img src={ether} alt="" /> View on Etherscan{" "}
                          <FontAwesomeIcon
                            className={styles.up}
                            icon={faArrowUp}
                          />
                        </a>
                        <a href={data?.asset?.ipfs} target={"blank"}>
                          <FontAwesomeIcon icon={faEye} /> Open orignal on IPFS{" "}
                          <FontAwesomeIcon
                            className={styles.up}
                            icon={faArrowUp}
                          />
                        </a>
                      </div>
                      {data?.estimate && (
                        <div className={styles.boxDesc}>
                          <h3>Estimate</h3>
                          <NumericFormat
                            prefix="$"
                            value={data?.estimate}
                            allowLeadingZeros
                            displayType="text"
                            thousandSeparator=","
                          />
                        </div>
                      )}
                       <button className="cta-btn"
                      >
                         <Link to="/Create" className="cta-btn">
                             Mint NFT
                      </Link>
                      </button>
                        
                    </>
                  ) : (
                    <>
                      <div className={styles.owner}>
                        <img src={img} alt="" />
                        <div className={styles.ownerDetails}>
                          <h2>Current Owner</h2>
                          <p><i>{data?.asset?.ownerAddress}</i></p>
                        </div>
                      </div>
                      <div className={styles.listOwner}>
                        <a href="#" target={"blank"}>
                          <FontAwesomeIcon icon={faEthereum} /> Ethereum
                          (ERC-721)
                        </a>
                        <a
                          href={data?.asset?.blockexplorerlink}
                          target={"blank"}
                        >
                          <img src={ether} alt="" /> View on Etherscan{" "}
                          <FontAwesomeIcon
                            className={styles.up}
                            icon={faArrowUp}
                          />
                        </a>
                        <a href={data?.asset?.ipfs} target={"blank"}>
                          <FontAwesomeIcon icon={faEye} /> Open orignal on IPFS{" "}
                          <FontAwesomeIcon
                            className={styles.up}
                            icon={faArrowUp}
                          />
                        </a>
                      </div>
                      {data?.estimate && (
                        <div className={styles.boxDesc}>
                          <h3>Estimate</h3>
                          <NumericFormat
                            prefix="$"
                            value={data?.estimate}
                            allowLeadingZeros
                            displayType="text"
                            thousandSeparator=","
                          />
                        </div>
                      )}
                        {walletAddress !== data?.asset?.ownerAddress &&
                data?.asset?.listed  ? (
                      <button
                        onClick={() => setModal(true)}
                        className="cta-btn"
                      >
                        Message
                      </button>
                        ):
                        <button
                        onClick={() => {
                          setCancel(true);
                        }}
                        className="cta-btn"
                      >
                        Cancel Listing
                      </button>
                        }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mobBox}>
            <div className={styles.boxCol}>
              <h3>
                {" "}
                Estimate :{" "}
                <NumericFormat
                  prefix="$"
                  value={data?.estimate}
                  allowLeadingZeros
                  displayType="text"
                  thousandSeparator=","
                />
              </h3>
            </div>
            {/* <button className="cta-btn">Start an offer</button> */}
          </div>
        </>
      )}
      <div className={styles.suggestedListings}>
        <h1>Similar listings</h1>
        {suggested && (
          <Splide
            options={{
              gap: "0rem",
              perPage: 3.5,
              perMove: 1,
              type: "loop",
              pagination: true,
              drag: true,
              arrows: true,
              width: "100%",
              breakpoints: {
                1287: {
                  perPage: 3,
                },
                1080: {
                  perPage: 2,
                },
                734: {
                  perPage: 1,
                },
              },
            }}
          >
            {suggested.map((elem) => {
              return (
                <SplideSlide key={elem.id}>
                  <Card {...elem} />
                </SplideSlide>
              );
            })}
          </Splide>
        )}
      </div>
    </div>
  );
}

export default Assets;
