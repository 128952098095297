import styles from "./ApproveListing.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import Marketplace from "../../RealtySwap.json";
import Loading from "../Loading/Loading";
import img from "../../Assets/review-collection.webp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ApproveListing = ({
  tokenId,
  fromWalletAddress,
  closeModal,
  name,
  propAddressId,
  imageUrl,
  offerPrice,
}) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [termsCheckBox, setTermsCheckBox] = useState(false);
  const [openDrop1, setOpenDrop1] = useState(false);
  const [openDrop2, setOpenDrop2] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [dropVal1, setDropVal1] = useState("ETH");
  const [dropVal2, setDropVal2] = useState("3 Days");
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const ethers = require("ethers");

  const [formParams, updateFormParams] = useState({
    offerPrice: 0,
    startTime: "",
    endTime: "",
  });

  async function ApproveListing(e) {
    e.preventDefault();
    if (!walletAddress) {
      toast.error("Please connect your MetaMask Wallet");
      return;
    }
   
    try {
      setIsFormLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      console.log(signer.getAddress);
      //Pull the deployed contract instance
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );
      
      try {
         let transaction = await contract.CloseBiding(tokenId);
        let txReceipt = await transaction.wait();
     
      } catch (error) {
        // Catch any errors for any of the above operations.
        const endIndex = error.message.search("{");
        if (endIndex >= 0) {
          toast.error(error.message);
        }
        return;
      }

      axios
      .post("/asset/list", {
        closeListing: true,
        ownerWalletAddress: walletAddress,
        offerWalletAddressId:fromWalletAddress,
        tokenId: tokenId,
        bidAcceptPrice:offerPrice,
        propAddressId: propAddressId,
      }).then(() => {
          toast.success("Listing Approved Successfully");
        })
        .catch(() => {
          toast.error("Uh Oh! an error occured");
        })
        .finally(() => {
          updateFormParams({
            offerPrice: "",
            startTime: "",
            endTime: "",
          });
        });

      //closeModal(false);
    } catch (e) {
      console.log("Unable to Accept Offer", e);
      toast.error(
        "Unable to Accept Offer,Please contact support@realtyswap.io"
      );
    } finally {
      setIsFormLoading(false);
      navigate("/");
    }
  }

  return (
    <>
      {isFormLoading && <Loading />}
      <div className={styles.overlay}></div>
        <div className={styles.offerDiv}>
          <div className={styles.header}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setTermsCheckBox(false)}
            />
            <h2>Approve NFT Listing</h2>
            <FontAwesomeIcon onClick={() => closeModal(false)} icon={faXmark} />
          </div>
        
          <div className={styles.inputCol}>
            
            Pls Review below details {" "}
            <FontAwesomeIcon icon={faInfoCircle} />
          <div className={styles.infoTable}>
            <div className={styles.tableRow}>
              <h3>Property Address</h3>
              <a href="#">{name}</a>
            </div>
            <div className={styles.tableRow}>
              <h3>Bid Wallet Address</h3>

              <a
                style={{
                  maxWidth: "205px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                href="#"
              >
                {fromWalletAddress}
              </a>
            </div>

           
              <>
                <div className={styles.tableRow}>
                  <h3>Bid Price</h3>
                  <a
                    style={{
                      maxWidth: "205px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    href="#"
                  >
                    {offerPrice}
                  </a>
                </div>
              </>
          
            </div>
          </div>
          <button onClick={ApproveListing} className="cta-btn">
            Approve Listing
          </button>
        </div>
    
    </>
  );
};

export default ApproveListing;
