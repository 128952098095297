import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import "./App.css";
import Home from "./Pages/HomePage/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Assets from "./Pages/Assets/Assets";
import Explore from "./Pages/Explore/Explore";
import SearchPage from "./Pages/Explore/SearchPage";
import Create from "./Pages/Create/Create";
import Profile from "./Pages/Profile/Profile";
import Settings from "./Pages/Settings/Settings";
import MetaConnect from "./Components/MetaConnect/MetaConnect";
import ScrollToTop from "./Components/ScrollToTop";
import Index from "./Pages/Price/Index";
import Contact from "./Pages/Contact/Contact";
import Blog from "./Pages/Blog/Blog";
import Blog1 from "./Pages/Blog/what-are-nfts";
import Blog2 from "./Pages/Blog/house-on-blockchain";
import Blog3 from "./Pages/Blog/understanding-cryptocurrency";
import Blog4 from "./Pages/Blog/web3-real-estate";
import ListNft from "./Pages/ListNft/ListNft";
import Advertise from "./Pages/Advertise/Advertise";
import Token from "./Pages/Token/Token";
import Terms from "./Pages/HomePage/Terms";
import ReactGA from 'react-ga4';
import React, {useEffect } from 'react';


function App() {
  const { address } = useSelector((state) => state.wallet);
  const TRACKING_ID = "G-W909VT6F0R"; 
  ReactGA.initialize(TRACKING_ID);

  console.log(address);
  useEffect(() => {
  }, []);
  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Assets />} path="/assets/:id" />
        <Route path="/Explore" element={<Explore />} />
        <Route path="/Explore/:lat/:lng" element={<SearchPage />} />
        <Route path="/list/:id" element={<ListNft />} />
        <Route path="/Connect" element={<MetaConnect />} />
        <Route path="/Advertise" element={<Advertise />} />
        <Route path="/Token" element={<Token />} />
        <Route path="/Price" element={<Index />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/what-are-nfts" element={<Blog1 />} />
        <Route path="/house-on-blockchain" element={<Blog2 />} />
        <Route path="/understanding-cryptocurrency" element={<Blog3 />} />
        <Route path="/web3-real-estate" element={<Blog4 />} />
        <Route path="/terms" element={<Terms />} />

        {address && (
          <>
            <Route path="/Create" element={<Create />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Settings/:walletAddress" element={<Settings />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/Connect" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
