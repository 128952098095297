import React from "react";
import styles from "./styles/Pricing.module.css";
function Terms() {
  return (
    <div className="container">
    <div className={styles.pricing}>
      <h1>TERMS OF SERVICE</h1>
      <div >
          <p>
REALTYSWAP TERMS OF SERVICE
<br></br>
<br></br>
Last Updated: Oct 1, 2022
<br></br>
<br></br>
Welcome, and thank you for your interest in REALTYSWAP Inc. (“REALTYSWAP,” “we,” “our” or “us”), the REALTYSWAP Platform
(defined below), our website at www.REALTYSWAP.IO/, along with our related websites, hosted applications, mobile or
other services (collectively, the “Service”). These Terms of Service constitute a legally binding contract between you
and REALTYSWAP regarding your use of the Service.
PLEASE READ THE FOLLOWING TERMS CAREFULLY:
BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING
OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A
CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS
AND CONDITIONS, INCLUDING REALTYSWAP’S PRIVACY POLICY (COLLECTIVELY, THESE “TERMS”). IF
YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THESE TERMS, THEN YOU DO NOT HAVE OUR
PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND REALTYSWAP’S PROVISION OF THE
SERVICE TO YOU, CONSTITUTES AN AGREEMENT BETWEEN REALTYSWAP AND YOU TO BE BOUND BY
THESE TERMS.
ARBITRATION NOTICE. EXCEPT FOR CERTAIN KINDS OF DISPUTES DESCRIBED IN SECTION 14,
YOU AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL BE RESOLVED BY BINDING,
INDIVIDUAL ARBITRATION, AND BY ACCEPTING THESE TERMS, YOU AND REALTYSWAP ARE EACH
WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT
OR DEFEND YOUR RIGHTS UNDER THIS CONTRACT (EXCEPT FOR MATTERS THAT MAY BE TAKEN
TO SMALL CLAIMS COURT). YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR
AND NOT A JUDGE OR JURY. (SEE SECTION 14.)
NFT CREATOR NOTICE. IF YOU ARE USING THE SERVICE TO CREATE, MINT, SELL, TRADE OR
OTHERWISE DISTRIBUTE NFTs (DEFINED BELOW), COLLECTIBLES (DEFINED BELOW), AND/OR ANY
OTHER CRYPTOGRAPHIC ASSETS VIA THE SERVICE, THEN YOU ARE A “CREATOR” AND YOU MUST
SEPARATELY REVIEW AND AGREE TO THE CREATOR TERMS AND CONDITIONS (AVAILABLE AT
WWW.REALTYSWAP.IO/CREATORTANDCS). THE CREATOR TERMS AND CONDITIONS MAY REQUIRE
YOU TO PROVIDE US WITH MORE INFORMATION ABOUT YOURSELF THAT IS REQUIRED FOR YOU
TO REGISTER FOR A CREATOR ACCOUNT (DEFINED BELOW), SPECIFICALLY YOUR LEGAL NAME
AND CURRENT MAILING ADDRESS.
NFT RISK NOTICE. AS FURTHER DESCRIBED IN SECTION 13, THE PRICES AND VALUE OF NFTS
(DEFINED BELOW) MAY FLUCTUATE SIGNIFICANTLY. YOU ACKNOWLEDGE THAT YOU FULLY
UNDERSTAND THE RISKS ASSOCIATED WITH PURCHASING AND SELLING NFTS.
AGREEMENT
</p>
<br></br>
1. REALTYSWAP SERVICE.
1.1 Platform. The “REALTYSWAP Platform” provides a web-based platform for trading blockchain-based digital
collectibles supported by applications, forums, technologies and a governance module that rewards active platformusers with a voice. The REALTYSWAP Platform supports a variety of blockchains, which, as updated from time to time in
REALTYSWAP’s sole discretion, may include, but is not limited to: Ethereum, Flow, Tezos, Polygon, and Solana (each, a
“REALTYSWAP-Supported Blockchain”). For more information about the REALTYSWAP Platform, please visit our FAQs.
1.2 Use of the Service. You and any other users that have registered for an Account (defined below) are each
a “User” whose use of the Service may include: (a) accessing, downloading or using any component of the Service; 
-2-
<br></br>
<br></br>
(b) buying or receiving any Collectible via any component of the Service; (c) using, sending tokens to, receiving
tokens from, sending messages to or otherwise transacting with any component of the Service; (d) using, transferring,
voting, buying, selling or receiving REALTYSWAP’s native governance token, RARI; and (e) participating in, accessing or
using REALTYSWAP’s governance module.
2. YOUR ACCOUNT
<br></br>
<br></br>
2.1 Eligibility. You must be at least 18 years old to agree to these Terms and use the Service. If you are the
parent or legal guardian of a child younger than 18 years of age (a “Minor”), you may allow such Minor to use the
Service solely through your Account and with your approval and oversight. You, as the Account holder, are
responsible for all actions occurring through your Account, including all financial charges and legal liability associated
with such Account, even if such charges or liability are incurred by a Minor. You agree to indemnify REALTYSWAP for your
Minor’s violation or disaffirmation of the Terms. By agreeing to these Terms, you represent and warrant to us that:
(a) you are at least 18 years old; (b) you are not currently suspended or are not permanently banned from the Service;
(c) your registration and your use of the Service complies with any and all applicable laws and regulations. Further,
you are not, (and, if you are an entity, you are not owned or controlled by any other person who is), and are not acting
on behalf of any other person who is, identified on any list of prohibited parties under any law or by any nation or
government, state or other political subdivision thereof, any entity exercising legislative, judicial or administrative
functions of or pertaining to government such as the lists maintained by the United Nations Security Council, the U.S.
government (including the U.S. Treasury Department’s Specially Designated Nationals list and Foreign Sanctions
Evaders list), the European Union or its member states, and the government of your home country. You are not, (and,
if you are an entity, you are not owned or controlled by any other person who is), and is not acting on behalf of any
other person who is, located, ordinarily resident, organized, established, or domiciled in any country or jurisdiction
against which the U.S. maintains economic sanctions or an arms embargo. The tokens, digital assets, or other funds
you use to purchase Collectibles are not derived from, and do not otherwise represent the proceeds of, any activities
done in violation or contravention of any law. If you are an entity, organization, or company, the individual accepting
these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree
to be bound by these Terms.
<br></br>
2.2 Privacy Policy. REALTYSWAP collects, processes, and otherwise uses your information in accordance with our
Privacy Policy.
<br></br>
<br></br>
2.3 Accounts and Registration. In order to register an account on the Service (“Account”), you will need to
provide a blockchain address and link directly to a third-party REALTYSWAP-Supported Blockchain node or link indirectly
through any REALTYSWAP-Supported Blockchain cryptocurrency wallet application (such as the web browser extension
Metamask) which interacts with the node (each, a “Wallet”). You agree that the information you provide to us is
accurate, complete, and not misleading, and that you will keep it accurate and up to date at all times. You are solely
responsible for maintaining the confidentiality of your Account and you accept responsibility for all activities that
occur under your Account. If you believe that your Account is no longer secure, then you should immediately notify
us at dispute@REALTYSWAP.IO. By using a Wallet in connection with the Service, you agree that you are using the Wallet
under the terms and conditions of the applicable provider of the Wallet. Wallets are not operated by, maintained by,
or affiliated with REALTYSWAP, and REALTYSWAP does not have custody or control over the contents of your Wallet and has no
ability to retrieve or transfer its contents. REALTYSWAP accepts no responsibility for, or liability to you, in connection with
your use of a Wallet and makes no representations or warranties regarding how the Service will operate with any
specific Wallet. You are solely responsible for keeping your Wallet secure and you should never share your Wallet
credentials or seed phrase with anyone. If you discover an issue related to your Wallet, please contact your Wallet
provider.
2.4 Creator Account. The Service includes functionality that allows Creators to “tokenize” their own content,
for example, as an ERC-721 token (each, an “NFT”). NFTs embody the Creator’s own content including collectibles,
artwork, videos, game items, etc. (together with the NFT, a “Collectible”) for sale, trade, or other distribution via the
Service. NFTs are intended to be “non-fungible” with a Uniform Resource Identifier (“URI”) identifying an
appropriately configured JavaScript Object Notation file (the “Collectible ID”). If you use the Service to mint
Collectibles, you must separately review and agree to the Creator Terms and Conditions and create a “Creator
Account.” 
-3-
<br></br>
<br></br>
2.5 Your General Covenants, Representations and Warranties. By using the Service, you covenant, represent,
and warrant as follows:
(a) Non-contravention. Your use of the Service does not constitute and would not reasonably be expected
to result in (with or without notice, lapse of time, or both) a breach, default, contravention, or violation
of any law applicable to you, or any contract or agreement to which you are a party or by which you
are bound.
(b) No Claim, Loan, Ownership Interest, or Investment Purpose. Your purchase, sale, holding, receipt
and use of NFTs, Collectibles, or the Service does not: (i) represent or constitute a loan or a
contribution of capital to, or other investment in REALTYSWAP or any business or venture; (ii) provide you
with any ownership interest, equity, security, or right to or interest in the assets, rights, properties,
revenues or profits of, or voting rights whatsoever in, REALTYSWAP or any other business or venture; and
(iii) create or imply or entitle you to the benefits of any fiduciary or other agency relationship between
REALTYSWAP or any of its directors, officers, employees, agents or affiliates, on the on hand, and you, on
the other hand. You are not entering into these Terms for the purpose of making an investment with
respect to REALTYSWAP or its securities, but solely wish to use the Service for its intended purposes.
(c) Non-Reliance. You are knowledgeable, experienced, and sophisticated in using and evaluating
blockchain and related technologies and assets, including REALTYSWAP-Supported Blockchains, NFTs,
Collectibles and “smart contracts” (bytecode deployed to a blockchain). You have conducted your
own thorough independent investigation and analysis of the Service and the other matters
contemplated by these Terms, and have not relied up-on any information, statement, omission,
representation, or warranty, express or implied, written or oral, made by or on behalf of REALTYSWAP in
connection therewith, except as expressly set forth by REALTYSWAP in these Terms.
(d) Taxes. You are solely responsible for assessing all Taxes owed, if any, for your use of the Service,
including, but not limited to, tax liabilities associated with any sales of your NFTs. As used herein,
“Taxes” means the taxes, duties, levies, tariffs, and other governmental charges that you may be
required to collect and remit to governmental agencies, and other similar municipal, state, federal,
national or other withholding and personal or corporate income taxes.
2.6 REALTYSWAP May Deny Access to or Use of the Service. REALTYSWAP reserves the right to terminate your access to
and use of the Service, in whole or in part, at any time, with or without notice, at REALTYSWAP’s discretion for any or no
reason. REALTYSWAP reserves the right at all times to disclose any information as it deems necessary to satisfy any applicable
law, regulation, legal process, or governmental request, or to edit, refuse to post or to remove any information or
materials (including Collectibles), in whole or in part, in REALTYSWAP’s sole discretion. Collectibles or other materials
uploaded to the Service may be subject to limitations on usage, reproduction and/or dissemination; you are responsible
for adhering to such limitations if you acquire a Collectible.
FOR MORE INFORMATION AND DESCRIPTIONS OF THE SERVICE, PLEASE VISIT OUR FAQs.
3. PAYMENT TERMS; PRICE
3.1 Payment Terms. Buying, selling, or transferring Collectibles may be subject to fees, commissions, royalties
and other charges including: (1) standard marketplace service fees associated with transactions on REALTYSWAP-Supported
Blockchains between Users (“Marketplace Fees”); (2) commissions on secondary sales of Collectibles, payable to
the creators of such Collectibles; and (3) “Gas Fees” paid to REALTYSWAP-Supported Blockchain miners in ETH or other
cryptocurrency through your Wallet as consideration for mining your transactions (collectively “Fees”). REALTYSWAP may
change the Marketplace Fees for any feature of the Service, including additional fees or charges, with or without
advance notice to you. Before you pay any Fees, you will have an opportunity to review and accept the Fees that you
will be charged. All Fees are in ETH or other cryptocurrency and are non-refundable except as required by applicable
law. 
-4-
<br></br>
<br></br>
3.2 Price. REALTYSWAP reserves the right to determine pricing for the Service. REALTYSWAP will make reasonable efforts
to keep pricing information published on the website up to date. We encourage you to check our website periodically
for current pricing information. REALTYSWAP, at its sole discretion, may make promotional offers with different features
and different pricing to any of REALTYSWAP’s customers. These promotional offers, unless made to you, will not apply to
your offer or these Terms.
4. OWNERSHIP; PROPRIETARY RIGHTS. The Service is owned and operated by REALTYSWAP. The visual
interfaces, graphics, design, compilation, information, data, computer code (including source code or object code),
products, software, services, and all other elements of the Service (“Materials”) provided by REALTYSWAP are protected by
intellectual property and other laws. All Materials included in the Service are the property of REALTYSWAP or its third-party
licensors. Except as expressly authorized by REALTYSWAP, you may not make use of the Materials. “REALTYSWAP” and its logos
are trademarks of REALTYSWAP. All other trademarks appearing in the Service are the property of their respective owners.
REALTYSWAP reserves all rights to the Materials not granted expressly in these Terms.
5. LICENSES
<br></br>
<br></br>
5.1 Limited License. Subject to your complete and ongoing compliance with these Terms, REALTYSWAP grants you,
solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable
license to (a) install and use one object code copy of any mobile or other downloadable application associated with
the Service obtained from a legitimate marketplace (whether installed by you or pre-installed on your mobile device
by the device manufacturer) on a mobile device or applicable equipment that you own, control or possess; and (b)
access and use the Service.
5.2 License Restrictions. Except and solely to the extent such a restriction is impermissible under applicable
law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications
to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control
mechanism. If you are prohibited under applicable law from using the Service, you may not use it.
5.3 Feedback. If you choose to provide input and suggestions regarding problems with or proposed
modifications or improvements to the Service (“Feedback”), then you hereby grant REALTYSWAP an unrestricted, perpetual,
irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose,
including to improve the Service and create other products and services.
6. THIRD-PARTY TERMS
<br></br>
<br></br>
6.1 Third-Party Services and Linked Websites. REALTYSWAP may provide tools through the Service that enable you
to export information, including User Content (defined below), to third-party services, including through features that
allow you to link your Account with an account on the third-party service, such as Twitter or Facebook, or through
our implementation of third-party buttons (such as “like” or “share” buttons). By using any of these tools, you agree
that REALTYSWAP may transfer that information to the applicable third-party service. Third-party services are not under
REALTYSWAP’s control, and, to the fullest extent permitted by law, REALTYSWAP is not responsible for any third-party service’s
use of your exported information. The Service may also contain links to third-party websites. Linked websites are not
under REALTYSWAP’s control, and REALTYSWAP is not responsible for their content.
6.2 Third-Party Software. The Service may include or incorporate third-party software components that are
generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those
components (“Third-Party Components”). Although the Service is provided to you subject to these Terms, nothing
in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third-Party Components
under the applicable third-party licenses or to limit your use of Third-Party Components under those third-party
licenses.
6.3 Third-Party Offerings and Content. References, links, or referrals to or connections with or reliance on
third-party resources, products, services, or content, including smart contracts developed or operated by third parties,
may be provided to Users in connection with the Service. In addition, third parties may offer promotions related to the
Service. REALTYSWAP does not endorse or assume any responsibility for any activities of or resources, products, services, 
-5-
<br></br>
<br></br>
content, or promotions owned, controlled, operated, or sponsored by third parties. If you access any such resources,
products, services, or content or participate in any such promotions, you do so solely at their own risk. You hereby
expressly waive and release REALTYSWAP from all liability arising from your use of any such resources, products, services
or content or participation in any such promotions. You further acknowledge and agree that REALTYSWAP will not be
responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection
with use of or reliance on any such resources, products, services, content, or promotions from third parties.
7. USER CONTENT
7.1 User Content Generally. In addition to creating and posting Collectibles—which is governed by the Creator
Terms and Conditions—the Service may permit Users to submit, upload, publish, broadcast, or otherwise transmit
(“Post”) content to the Service, including messages, reviews, photos, video or audio (including sound or voice
recordings and musical recordings embodied in the video or audio), images, folders, data, text, and any other works
of authorship or other works (“User Content”). For the avoidance of doubt, Collectibles are User Content. You retain
any copyright and other proprietary rights that you may hold in the User Content that you Post to the Service except
those rights that you explicitly choose to waive.
7.2 License Grant to REALTYSWAP. By Posting User Content to or via the Service, you grant REALTYSWAP a worldwide,
non-exclusive, perpetual, irrevocable, royalty-free, fully paid right and license (with the right to sublicense through
multiple tiers) to host, store, transfer, display, perform, reproduce, modify for the purpose of formatting for display,
create derivative works as authorized in these Terms, and distribute your User Content, in whole or in part, in any
media formats and through any media channels now known or hereafter developed. All of the rights you grant in these
Terms are provided on a through-to-the-audience basis, meaning the owners or operators of external services will not
have any separate liability to you or any other third party for User Content Posted or otherwise used on external
services via the Service.
7.3 License Grants to Other Users. By Posting User Content to or via the Service to other Users of the Service,
you grant those Users a non-exclusive license to access and use that User Content as permitted by these Terms and
the functionality of the Service.
7.4 Specific Rules for Photographs and Images. If you Post a photograph or image to the Service that includes
one or more persons, you hereby grant such persons and their administrators, guardians, heirs, and trustees, if any, an
irrevocable, perpetual, royalty free, fully paid-up, worldwide license to reproduce, distribute, and publicly display that
photograph for personal use and through any online platform or service, including the Service, Facebook, Instagram,
and Twitter, but not to promote any third-party product, good, or service. The license contained in this Section does
not permit the subject of any photo or their administrators, guardians, heirs, or trustees to sell that image or photograph,
whether on a standalone basis or as embodied in any product.
7.5 You Must Have Rights to the Content You Post; User Content Representations and Warranties. You must
not Post User Content if you are not the owner of or are not fully authorized to grant rights in all of the elements of
that User Content. REALTYSWAP disclaims any and all liability in connection with User Content. You are solely responsible
for your User Content and the consequences of providing User Content via the Service. By providing User Content
via the Service, you affirm, represent, and warrant to us that:
(a) you are the creator and owner of the User Content, or have the necessary licenses, rights, consents,
and permissions to authorize REALTYSWAP and Users of the Service to use and distribute your User Content
as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by
REALTYSWAP, the Service, and these Terms;
(b) your User Content, and the Posting or other use of your User Content as contemplated by these Terms,
does not and will not: (i) infringe, violate, misappropriate, or otherwise breach any third-party right,
including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity,
or any other intellectual property, contract, or proprietary right; (ii) slander, defame, libel, or invade
the right of privacy, publicity or other property rights of any other person; or (iii) cause REALTYSWAP to
violate any law or regulation or require us to obtain any further licenses from or pay any royalties,
fees, compensation or other amounts or provide any attribution to any third parties; and
-6-
<br></br>
<br></br>
(c) your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent,
pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.
7.6 User Content Disclaimer. We are under no obligation to edit or control User Content that you or other Users
Post and will not be in any way responsible or liable for User Content. REALTYSWAP may, however, at any time and without
prior notice, screen, remove, edit, or block any User Content in our sole discretion, including pursuant to our judgment
that it violates these Terms. You understand that, when using the Service, you will be exposed to User Content from
a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable.
REALTYSWAP makes no representations or warranties as to the quality, origin, or ownership of any content found in the
Service. REALTYSWAP will not be liable for any errors, misrepresentations, or omissions in, of, and about, the content, nor
for the availability of the content. REALTYSWAP will not be liable for any losses, injuries, or damages from the purchase,
inability to purchase, display, or use of content. You agree to waive, and do waive, any legal or equitable right or
remedy you have or may have against REALTYSWAP with respect to User Content. If notified by a User or content owner
that User Content allegedly does not conform to these Terms, we may investigate the allegation and determine in our
sole discretion whether to remove the User Content, which we reserve the right to do at any time and without notice.
For clarity, REALTYSWAP does not permit infringing activities on the Service.
7.7 Monitoring Content. REALTYSWAP does not control and does not have any obligation to monitor: (a) User Content;
(b) any content made available by third parties; or (c) the use of the Service by its Users. You acknowledge and agree
that REALTYSWAP reserves the right to, and may from time to time, monitor any and all information and content (including
User Content) transmitted or received through the Service for operational and other purposes. If at any time REALTYSWAP
chooses to monitor the content (including User Content), REALTYSWAP still assumes no responsibility or liability for such
content, or any loss or damage incurred as a result of the use of such content. During monitoring, information may be
examined, recorded, copied, and used in accordance with our Privacy Policy.
8. INTELLECTUAL PROPERTY RIGHTS PROTECTION
8.1 Respect of Third Party Rights. REALTYSWAP respects the intellectual property rights of others, takes the
protection of intellectual property rights very seriously, and asks users of the Service to do the same. Infringing activity
will not be tolerated on or through the Service.
8.2 DMCA Notification. We comply with the provisions of the Digital Millennium Copyright Act applicable
to Internet service providers (17 U.S.C. § 512, as amended). If you have an intellectual property rights-related
complaint about any material on the Service, you may contact our Designated Agent at the following address:
REALTYSWAP, Inc.
Attn: DMCA Notice
8 The Green
Suite #11317,
Dover, DE 19901
Email: dmca@REALTYSWAP.IO
<br></br>
<br></br>
8.3 Procedure for Reporting Claimed Infringement. If you believe that any content made available on or
through the Service has been used or exploited in a manner that infringes an intellectual property right you own or
control, then please promptly send a written “Notification of Claimed Infringement” to the Designated Agent
identified above containing the following information:
(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the
copyright or other right being infringed;
(b) a description of the copyrighted work or other intellectual property right that you claim has been
infringed;
(c) a description of the material that you claim is infringing and where it is located on the Service,
including a URL representing a link to the material on the REALTYSWAP website;
-7-
<br></br>
<br></br>
(d) your address, telephone number, and email address;
(e) a statement by you that you have a good faith belief that the use of the materials on the Service of
which you are complaining is not authorized by the copyright or other intellectual property right
owner, its agent, or the law; and
(f) a statement by you that the above information in your notice is accurate and that, under penalty of
perjury, you are the copyright or other intellectual property right owner or authorized to act on the
copyright or intellectual property owner’s behalf.
Your Notification of Claimed Infringement may be shared by REALTYSWAP with the user alleged to have infringed a right
you own or control as well as with the operators of publicly available databases that track notifications of claimed
infringement, and you consent to REALTYSWAP making such disclosures. You should consult with your own lawyer or see
17 U.S.C. § 512 to confirm your obligations to provide a valid notice of claimed infringement.
8.4 Repeat Infringers. REALTYSWAP’s policy is to: (a) remove or disable access to material that REALTYSWAP believes in
good faith, upon notice from an intellectual property rights owner or authorized agent, is infringing the intellectual
property rights of a third party by being made available through the Service; and (b) in appropriate circumstances, to
terminate the accounts of and block access to the Service by any user who repeatedly or egregiously infringes other
people’s copyright or other intellectual property rights. REALTYSWAP will terminate the accounts of users that are determined
by REALTYSWAP to be repeat infringers. REALTYSWAP reserves the right, however, to suspend or terminate accounts of users in
our sole discretion.
8.5 Counter Notification. If you receive a notification from REALTYSWAP that material made available by you on or
through the Service has been the subject of a Notification of Claimed Infringement, then you will have the right to
provide REALTYSWAP with what is called a “Counter Notification.” To be effective, a Counter Notification must be in
writing, provided to REALTYSWAP’s Designated Agent through one of the methods identified in Section 8.2, and include
substantially the following information:
(a) your physical or electronic signature;
(b) identification of the material that has been removed or to which access has been disabled and the
location at which the material appeared before it was removed or access to it was disabled;
(c) a statement under penalty of perjury that you have a good faith belief that the material was removed
or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
(d) your name, address, and telephone number, and a statement that you consent to the jurisdiction of
Federal District Court for the judicial district in which the address is located, or if you are residing
outside of the United States, then for any judicial district in which REALTYSWAP may be found, and that you
will accept service of process from the person who provided notification under Section 8.2 above or
an agent of that person.
A party submitting a Counter Notification should consult a lawyer or see 17 U.S.C. § 512 to confirm the party’s
obligations to provide a valid counter notification under the Copyright Act.
8.6 Reposting of Content Subject to a Counter Notification. If you submit a Counter Notification to REALTYSWAP in
response to a Notification of Claimed Infringement, then REALTYSWAP will promptly provide the person who provided the
Notification of Claimed Infringement with a copy of your Counter Notification and inform that person that REALTYSWAP
will replace the removed User Content or cease disabling access to it in 10 business days, and REALTYSWAP will replace the
removed User Content and cease disabling access to it not less than 10, nor more than 14, business days following
receipt of the Counter Notification, unless REALTYSWAP’s Designated Agent receives notice from the party that submitted
the Notification of Claimed Infringement that such person has filed an action seeking a court order to restrain the user
from engaging in infringing activity relating to the material on REALTYSWAP’s system or network.
-8-
<br></br>
<br></br>
8.7 False Notifications of Claimed Infringement or Counter Notifications. The Copyright Act provides at 17
U.S.C. § 512(f) that:
“[a]ny person who knowingly materially misrepresents under [Section 512 of the Copyright Act (17 U.S.C. §
512)] (1) that material or activity is infringing, or (2) that material or activity was removed or disabled by mistake or
misidentification, will be liable for any damages, including costs and attorneys’ fees, incurred by the alleged infringer,
by any copyright owner or copyright owner’s authorized licensee, or by a service provider, who is injured by such
misrepresentation, as the result of [REALTYSWAP] relying upon such misrepresentation in removing or disabling access to
the material or activity claimed to be infringing, or in replacing the removed material or ceasing to disable access to
it.” REALTYSWAP reserves the right to seek damages from any party that submits a Notification of Claimed Infringement or
Counter Notification in violation of the law.
9. PERMITTED & PROHIBITED CONDUCT OF THE SERVICE
9.1 Permitted Uses. The Service is reserved exclusively for lawful consumer entertainment and artistic
purposes.
9.2 Prohibited Uses. By using the Service, you agree not to, directly or indirectly:
(a) use the Service for any illegal purpose or in violation of any local, state, national, or international law;
(b) harass, threaten, demean, embarrass, bully, or otherwise harm any other User of the Service;
(c) violate, or encourage others to violate, any right of a third party, including by infringing or
misappropriating any third-party intellectual property right;
(d) access, search, or otherwise use any portion of the Service through the use of any engine, software,
tool, agent, device, or mechanism (including spiders, robots, crawlers, and data mining tools) other
than the software or search agents provided by REALTYSWAP;
(e) interfere with security-related features of the Service, including by: (i) disabling or circumventing
features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise
attempting to discover the source code of any portion of the Service except to the extent that the
activity is expressly permitted by applicable law;
(f) interfere with the operation of the Service or any User’s enjoyment of the Service, including by:
(i) uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code;
(ii) making any unsolicited offer or advertisement to another User of the Service; (iii) collecting
personal information about another User or third party without consent; or (iv) interfering with or
disrupting any network, equipment, or server connected to or used to provide the Service;
(g) perform any fraudulent activity including impersonating any person or entity, claiming a false
affiliation, accessing any other User’s Account without permission, or falsifying your age or date of
birth;
(h) sell or otherwise transfer the access granted under these Terms or any Materials (as defined in
Section 4) or any right or ability to view, access, or use any Materials;
(i) employ any device, scheme or artifice to defraud, or otherwise materially mislead REALTYSWAP or any other
Users, including by impersonating or assuming any false identity;
(j) engage in any act, practice or course of business that operates or would operate as a fraud or deceit
upon REALTYSWAP or any other Users;
-9-
<br></br>
<br></br>
(k) violate, breach or fail to comply with any applicable provision of these Terms or any other terms of
service, Privacy Policy, trading policy or other contract governing the use of the Service or any
relevant Collectibles;
(l) use the Service by or on behalf of a competitor of the REALTYSWAP or competing platform or service for the
purpose of interfering with the Service to obtain a competitive advantage;
(m) engage or attempt to engage in or assist any hack of or attack on the Service, including any
“sybil attack”, “DoS attack” or “griefing attack” or theft of Collectibles, digital assets or funds, or
upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or
any other similar software or programs that may damage the operation of another’s computer or
property or interfere with the Service;
(n) knowingly sell or transfer any Collectible, or use any Collectible in a manner, that does or may
infringe any copyright, trademark, patent, trade secret or other intellectual property or other
proprietary rights of others, or upload, or otherwise make available, files that contain images,
photographs, software or other material protected by intellectual property laws (including, copyright
or trademark laws) or rights of privacy or publicity unless the applicable User owns or controls the
rights thereto or has received all necessary consent to do the same;
(o) commit any violation of applicable laws, rules or regulations;
(p) use the Service to offer additional services, including, but not limited to, surveys, contests, pyramid
schemes, chain letters, junk email, spamming, or any duplicative or unsolicited messages (commercial
or otherwise);
(q) defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy
and publicity) of other;
(r) publish, post, distribute or disseminate any profane, obscene, pornographic indecent or unlawful
content, pictures, topic, name, material or information;
(s) engage in or knowingly facilitate any “rug pulls,” “front-running,” “wash trading,” “pump and dump
trading,” “ramping,” “cornering” or fraudulent, deceptive or manipulative trading activities,
including:
(i) trading Collectibles at successively lower or higher prices for the purpose of creating or inducing
a false, misleading or artificial appearance of activity in such Collectible, unduly or improperly
influencing the market price for such Collectible or establishing a price which does not reflect
the true state of the market in such Collectible;
(ii) creating or inducing a false or misleading appearance of activity in any Collectibles or creating
or inducing a false or misleading appearance with respect to the market in any Collectibles: (1)
executing or causing the execution of any transaction in any Collectibles which involves no
material change in the beneficial ownership thereof; or (2) entering any order for the purchase
or sale of any Collectibles with the knowledge that an order of substantially the same size, and
at substantially the same price, for the sale of such Collectible, has been or will be entered by or
for the same or different parties;
(iii) participating in, facilitating, assisting or knowingly transacting with any pool, syndicate or joint
account organized for the purpose of unfairly or deceptively influencing the market price of any
Collectibles; or
-10-
<br></br>
<br></br>
(t) use the Service to transact in securities, commodities futures, trading of commodities on a leveraged,
margined or financed basis, binary options (including prediction-market transactions), real estate or
real estate leases, equipment leases, debt financings, equity financings or other similar transactions;
(u) use the Service to buy, sell or advertise personal, professional or business services;
(v) use the Service to engage in token-based or other financings of a business, enterprise, venture, DAO,
software development project or other initiative, including initial coin offering, DAO initial coin
offering, initial exchange offering, yield farming or other token-based fundraising events;
(w) use the Service for money laundering, terrorist financing, or other illicit finance; or
(x) attempt to do any of the acts described in this Section 9.2 or assist or permit any person in engaging
in any of the acts described in this Section 9.2.
10. MODIFICATION OF TERMS. We may, from time to time, change these Terms. Please check these Terms
periodically for changes. Revisions will be effective immediately except that, for existing users, material revisions
will be effective 30 days after posting or notice to you of the revisions unless otherwise stated. We may require that
you accept the modified Terms in order to continue to use the Service. If you do not agree to the modified Terms,
then you should remove your User Content and discontinue your use of the Service. Except as expressly permitted in
this Section 10, these Terms may be amended only by a written agreement signed by authorized representatives of the
parties to these Terms. It is your sole responsibility to review the Terms and understand modifications to these Terms
as they may be updated from time to time.
11. TERM, TERMINATION, AND MODIFICATION OF THE SERVICE
11.1 Term. These Terms are effective beginning when you accept the Terms or first download, install, access,
or use the Service, and ending when terminated as described in Section 11.2.
11.2 Termination. If you violate any provision of these Terms, your authorization to access the Service and these
Terms automatically terminates. In addition, REALTYSWAP may, at its sole discretion, terminate these Terms or your
Account, or suspend or terminate your access to the Service, at any time for any reason or no reason, with or without
notice. You may terminate your Account and these Terms at any time by visiting
https://REALTYSWAPcom.zendesk.com/hc/en-us/requests/new or by contacting customer service at dispute@REALTYSWAP.IO.
11.3 Effect of Termination. Upon termination of these Terms: (a) your license rights will terminate and you must
immediately cease all use of the Service; (b) you will no longer be authorized to access your Account or the Service;
(c) you must pay REALTYSWAP any unpaid amount that was due prior to termination; and (d) all payment obligations accrued
prior to termination and Sections 5.1, 5.3, 7.2, 7.3, 9, 11.3, 12, 13, 13.3, 14.10, and 15 will survive.
11.4 Modification of the Service. REALTYSWAP reserves the right to modify or discontinue the Service at any time
(including by limiting or discontinuing certain features of the Service), temporarily or permanently, without notice to
you. REALTYSWAP will have no liability for any change to the Service or any suspension or termination of your access to or
use of the Service.
12. INDEMNITY. To the fullest extent permitted by law, you are responsible for your use of the Service and for
any Minor’s use of the Service occurring through your Account, and you will defend and indemnify REALTYSWAP, its
affiliates and their respective shareholders, directors, managers, members, officers, employees, consultants, and agents
(together, the “REALTYSWAP Entities”) from and against every claim brought by a third party, and any related liability,
damage, loss, and expense, including attorneys’ fees and costs, arising out of or connected with: (1) your or your
Minor’s unauthorized use of, or misuse of, the Service; (2) your or your Minor’s violation of any portion of these
Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation;
(3) your or your Minor’s violation of any third-party right, including any intellectual property right or publicity,
confidentiality, other property, or privacy right; or (4) any dispute or issue between you or your Minor and any third
party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise 
-11-
<br></br>
<br></br>
subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and
in that case, you agree to cooperate with our defense of those claims.
13. LIMITATION OF LIABILITY; DISCLAIMER OF WARRANTIES; NO WARRANTY BY REALTYSWAP
13.1 No Responsibility for NFTs or Collectibles; No Guarantee of Uniqueness or IP. REALTYSWAP has no
responsibility for Collectibles that Users trade via the Service. REALTYSWAP does not investigate and cannot guarantee or
warrant the authenticity, originality, uniqueness, marketability, legality, or value of any Collectibles that Users trade
on the Service. The Collectible ID of an NFT and the content embodied therein specifies the properties of the NFT
and the content embodied therein, including the name and description of the NFT and the content embodied therein
(the “Collectible Descriptors”), a URI identifying any image file associated with the Collectible (the “Collectible
Image”) and potentially other “metadata” associated with the Collectible (the Collectible Descriptors, Collectible
Image and such other metadata, collectively, the “Collectible Metadata”). The Collectible Metadata for Collectibles
that are created through the REALTYSWAP Applications may be stored on InterPlanetary File System (“IPFS”) through a
third-party distributed cloud service (e.g., Pinata) or a third-party storage chain (e.g., Filecoin). The Collectible
Metadata for Collectibles that are created outside the REALTYSWAP Applications may be stored in other ways, depending on
how such Collectibles were created. There can be no guarantee or assurance of the uniqueness, originality or quality
of any Collectible or Collectible Metadata. In the absence of an express legal agreement between the creator of any
Collectibles and purchasers of Collectibles, there cannot be any guarantee or assurance that the purchase or holding
of Collectibles confers any license to or ownership of the Collectible Metadata or other intellectual property associated
with Collectibles or any other right or entitlement, notwithstanding that you may rightfully own or possess the
Collectible. REALTYSWAP may from time to time remove certain Collectibles from the Service or restrict the creation of
Collectibles on the Service in REALTYSWAP’s sole and absolute discretion, including in connection with any belief by REALTYSWAP
that such Collectibles violate these Terms. REALTYSWAP does not commit and will not be liable for any failure to support,
display or offer or continue to support, display, or offer any Collectibles for trading through the Service.
13.2 No Professional Advice or Liability. All information provided by or on behalf of REALTYSWAP is for
informational purposes only and should not be construed as professional, accounting, or legal advice. You should not
take or refrain from taking any action in reliance on any information contained in these Terms or provided by or on
behalf of REALTYSWAP. Before you make any financial, legal, or other decisions involving the Service, you should seek
independent professional advice from persons licensed and qualified in the area for which such advice would be
appropriate. You must always use caution when giving out any personally information to other Users or third-party
linked websites through the Service. REALTYSWAP does not control or endorse User Content, any third-party content,
messages, or information found in the Service and REALTYSWAP will not be liable for any use or misuse of User Content by
any User. REALTYSWAP specifically disclaims any liability with regard to the Service and any actions resulting from your
use of the Service.
<br></br>
<br></br>
13.3 Disclaimers Related to Blockchain Technology. To the maximum extent permitted by applicable law, and
notwithstanding anything otherwise expressly provided herein, REALTYSWAP disclaims any and all liability related to the
following:
(a) Use of Blockchain Technology. REALTYSWAP utilizes experimental cryptographic and blockchain
technologies, including tokens, cryptocurrencies, stablecoins, “smart contracts,” consensus
algorithms, voting systems and distributed, decentralized or peer-to-peer networks or systems in
providing the Service. You acknowledge and agree that such technologies are novel, experimental,
and speculative, and that therefore there is significant uncertainty regarding the operation and effects
and risks thereof and the application of existing law thereto. To the maximum extent permitted by
applicable law, REALTYSWAP disclaims any and all liability related to the foregoing.
(b) Certain Risks of Blockchain Technology. The technology utilized in delivering the Service depends
on public peer-to-peer networks such as the REALTYSWAP-Supported Blockchains that are not under the
control or influence of REALTYSWAP and are subject to many risks and uncertainties. REALTYSWAP may have
limited or no ability to change these technologies, other than ceasing to support certain “smart
contracts” and adding support for new “smart contracts”. You are solely responsible for the
safekeeping of the private key associated with the REALTYSWAP-Supported Blockchain address used to
participate in the Service. REALTYSWAP will not be able to restore or issue any refunds due to lost private 
-12-
keys. If you are not able to use the Service due to loss or theft of a private key or otherwise, REALTYSWAP
does not guarantee and is not responsible for your ability to exercise their rights with respect to such
Collectible or any other applicable digital assets.
(c) Certain Risks of Smart Contract Technology. NFTs and the content embodied therein and other digital
assets relevant to the Service depend on smart contracts deployed to REALTYSWAP-Supported Blockchains,
and some may be coded or deployed by persons other than REALTYSWAP. Once deployed to REALTYSWAPSupported Blockchain(s), the code of smart contracts cannot be modified. In the event that applicable
smart contracts are adversely affected by malfunctions, bugs, defects, malfunctions, hacking, theft,
attacks, negligent coding or design choices, or changes to the protocol rules of any REALTYSWAP-Supported
Blockchain, you may be exposed to a risk of total loss and forfeiture of all Collectibles and other
relevant digital assets.
(d) Asset Prices. The fiat-denominated prices and value in public markets of assets such as ETH,
Collectibles, and other digital assets have historically been subject to dramatic fluctuations and are
highly volatile. As relatively new products and technologies, blockchain-based assets are not widely
accepted as a means of payment for goods and services. A significant portion of demand for these
assets is generated by speculators and investors seeking to profit from the short- or long-term holding
of blockchain assets. The market value of any ETH, Collectibles, and other digital assets may decline
below the price for which you acquire such asset through the Service or on any other platform. You
acknowledge and agree that the costs and speeds of transacting with cryptographic and blockchainbased systems are variable and may increase or decrease dramatically at any time, resulting in
prolonged inability to access or use any ETH or other cryptocurrency, Collectibles, or other digital
assets associated with the Service.
(e) Regulatory Uncertainty. Blockchain technologies and digital assets are subject to many legal and
regulatory uncertainties, and the Service, Collectibles, and other digital assets could be adversely
impacted by one or more regulatory or legal inquiries, actions, suits, investigations, claims, fines or
judgments, which could impede or limit your ability to continue the use and enjoyment of such assets
and technologies.
(f) Cryptography Risks. Cryptography is a progressing field. Advances in code cracking or technical
advances such as the development of quantum computers may present risks to REALTYSWAP-Supported
Blockchain(s), the Service, Collectibles, and other digital assets, including the theft, loss or
inaccessibility thereof.
(g) Fork Handling. Certain elements of the Service, REALTYSWAP-Supported Blockchains, NFTs, Collectibles,
and other digital assets may be subject to “forks.” Forks occur when some or all persons running the
software clients for a particular blockchain system adopt a new client or a new version of an existing
client that: (i) changes the protocol rules in backwards-compatible or backwards-incompatible manner
that affects which transactions can be added into later blocks, how later blocks are added to the
blockchain, or other matters relating to the future operation of the protocol; or (ii) reorganizes or
changes past blocks to alter the history of the blockchain. Some forks are “contentious” and thus may
result in two or more persistent alternative versions of the protocol or blockchain, either of which may
be viewed as or claimed to be the legitimate or genuine continuation of the original. REALTYSWAP may not
be able to anticipate, control or influence the occurrence or outcome of forks, and does not assume
any risk, liability or obligation in connection therewith. Without limiting the generality of the
foregoing, REALTYSWAP does not assume any responsibility to notify you of pending, threatened or
completed forks. REALTYSWAP will determine its response(s) to such forks in its sole and absolute discretion,
without any duty or obligation or liability to you for such response (or lack of such response). REALTYSWAP
reserves the right to only allow one NFT to be associated with the relevant metadata forming the
Collectible, notwithstanding that copies of such NFT may exist on both chains following a fork. You
assume full responsibility to independently remain apprised of and informed about possible forks, and
to manage your own interests in connection therewith.
-13-
(h) Essential Third-Party Software Dependencies. Smart Contracts deployed to REALTYSWAP-Supported
Blockchains are public software utilities which are accessible directly through a Wallet. Because the
Service does not provide Wallet software or REALTYSWAP-Supported Blockchain nodes, such software
constitutes an essential third-party or User dependency without which the Service cannot be utilized
and Collectibles and other digital assets cannot be traded or used. Furthermore, Collectible Descriptors
defining the art, text or other data associated with a Collectible must be stored off of REALTYSWAPSupported Blockchains. Collectibles created on REALTYSWAP have their Collectible Descriptors stored on
the IPFS system through an IPFS node associated with a distributed cloud service (e.g., Pinata), but
REALTYSWAP cannot guarantee continued operation of such IPFS node, the integrity and persistence of data
on IPFS, or the distributed cloud service. Collectibles not created on REALTYSWAP may have Collectible
Descriptors stored in an IPFS node associated with a third-party decentralized storage chain (e.g.,
Filecoin, Arweave), on a personal IPFS node, or through a third-party centralized cloud (e.g., AWS).
REALTYSWAP cannot guarantee the continued operation of such third-party platforms.
(i) Viruses, Hacking, Phishing, Etc. The Service, REALTYSWAP, or your NFTs may be the target of third-party
attacks, including but not limited to phishing, bruteforcing, inactive listing exploits or the use of
viruses (“Third-Party Attacks”). REALTYSWAP will not be responsible or liable, directly or indirectly, for
any actual or alleged loss or damage sustained by You in connection with Third-Party Attacks.
13.4 Legal Limitations on Disclaimers. Some jurisdictions do not allow the exclusion of certain warranties or
the limitation or exclusion of certain liabilities and damages. Accordingly, some of the disclaimers and limitations set
forth in these Terms may not apply in full to you. The disclaimers and limitations of liability provided in these Terms
will apply to the fullest extent permitted by applicable law.
13.5 Officers, Directors, Etc. All provisions of these Terms which disclaim or limit obligations or liabilities of
REALTYSWAP will also apply, mutatis mutandis, to the officers, directors, members, employees, independent contractors,
agents, stockholders, debtholders, and affiliates of REALTYSWAP.
13.6 THE SERVICE AND ALL MATERIALS AND CONTENT (INCLUDING USER CONTENT)
AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS.
REALTYSWAP DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING
TO THE SERVICE AND ALL MATERIALS AND CONTENT (INCLUDING USER CONTENT) AVAILABLE
THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND
(B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. REALTYSWAP DOES NOT
WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
CONTENT (INCLUDING USER CONTENT) OFFERED THROUGH THE SERVICE, WILL BE
UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS,
AND REALTYSWAP DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
13.7 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
THE SERVICE OR REALTYSWAP ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF REALTYSWAP ENTITIES OR THE SERVICE
THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE
THAT MAY RESULT FROM THE SERVICE AND YOUR PURCHASE, SALE OR TRANSFER OF ANY
COLLECTIBLE OR DEALING WITH ANY OTHER USER. YOU UNDERSTAND AND AGREE THAT YOU
USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING
USER CONTENT.
<br></br>
<br></br>
13.8 TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL REALTYSWAP ENTITIES BE
LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE
LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER 
-14-
BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER
LEGAL THEORY, AND WHETHER OR NOT ANY REALTYSWAP ENTITY HAS BEEN INFORMED OF THE
POSSIBILITY OF DAMAGE.
13.9 EXCEPT AS PROVIDED IN SECTIONS 14.6 AND 14.7 AND TO THE FULLEST EXTENT
PERMITTED BY LAW, THE AGGREGATE LIABILITY OF REALTYSWAP ENTITIES TO YOU FOR ALL CLAIMS
ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE
SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE,
IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO REALTYSWAP FOR ACCESS TO
AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING
RISE TO THE CLAIM OR (B) US$50.
13.10 EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND
DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS
AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
LIMITATIONS IN THIS SECTION 13 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
ESSENTIAL PURPOSE.
13.11 THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SET FORTH IN THIS SECTION 13
APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. REALTYSWAP does not disclaim any warranty or other right
that REALTYSWAP is prohibited from disclaiming under applicable law.
14. DISPUTE RESOLUTION
<br></br>
<br></br>
14.1 Generally. In the interest of resolving disputes between you and REALTYSWAP in the most expedient and cost
effective manner, and except as described in Section 14.2 and 14.5, you and REALTYSWAP agree that every dispute arising
in connection with these Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in
court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in
court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a
court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of
these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless
of whether a claim arises during or after the termination of these Terms.
YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND REALTYSWAP
ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
14.2 Limited Survival Period for Dispute. Any dispute you may have in connection with the Service or any of
the other matters contemplated by these Terms will survive for the shorter of, and may be brought against REALTYSWAP
solely prior to: (a) the expiration of the statute of limitations applicable thereto; and (b) the date that is six months
after the date on which the facts and circumstances giving rise to such claim or cause of action first arose.
14.3 Exceptions. Despite the provisions of Section 14.1, nothing in these Terms will be deemed to waive,
preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue
an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek
injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual
property infringement claim.
14.4 Opt-Out. If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions
of this Section 14 within 30 days after the date that you agree to these Terms by sending a letter to REALTYSWAP Inc.,
Attention: Legal Department – Arbitration Opt-Out, 8 The Green, Suite # 11317, Dover, US, DE, 19901 that specifies:
your full legal name, the email address associated with your Account, and a statement that you wish to opt out of
arbitration (“Opt-Out Notice”). Once REALTYSWAP receives your Opt-Out Notice, this Section 14 will be void and any 
-15-
action arising out of these Terms will be resolved as set forth in Section 16.2. The remaining provisions of these Terms
will not be affected by your Opt-Out Notice.
14.5 Arbitrator. Any arbitration between you and REALTYSWAP will be settled under the Federal Arbitration Act and
administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (collectively,
“AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by
calling the AAA at +1-800-778-7879, or by contacting REALTYSWAP. The arbitrator has exclusive authority to resolve any
dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.
14.6 Notice of Arbitration; Process. A party who intends to seek arbitration must first send a written notice of
the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other
party has not provided a current physical address, then by electronic mail (“Notice of Arbitration”). REALTYSWAP’s address
for the Notice of Arbitration is: REALTYSWAP Inc., 8 The Green, Suite # 11317, Dover, US, DE, 19901. The Notice of
Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought
(“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an
agreement to do so within 30 days after the Notice of Arbitration is received, you or REALTYSWAP may commence an
arbitration proceeding. All arbitration proceedings between the parties will be confidential unless otherwise agreed by
the parties in writing. During the arbitration, the amount of any settlement offer made by you or REALTYSWAP must not be
disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the arbitrator awards
you an amount higher than the last written settlement amount offered by REALTYSWAP in settlement of the dispute prior to
the award, REALTYSWAP will pay to you the higher of: (a) the amount awarded by the arbitrator and (b) US$10,000.
14.7 Fees. If you commence arbitration in accordance with these Terms, REALTYSWAP will reimburse you for your
payment of the filing fee, unless your claim is for more than US$10,000, in which case the payment of any fees will
be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in the county
and state of your residence or billing address, but if the claim is for US$10,000 or less, you may choose whether the
arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a nonappearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or
parish) of your residence or billing address. If the arbitrator finds that either the substance of your claim or the relief
sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in
Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case,
you agree to reimburse REALTYSWAP for all monies previously disbursed by it that are otherwise your obligation to pay
under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a
reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award,
if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees
or expenses at any time during the proceeding and upon request from either party made within 14 days of the
arbitrator’s ruling on the merits.
14.8 No Class Actions. YOU AND REALTYSWAP AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER
IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and REALTYSWAP agree
otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any
form of a representative or class proceeding.
14.9 Modifications to this Arbitration Provision. If REALTYSWAP makes any future change to this arbitration provision,
other than a change to REALTYSWAP’s address for Notice of Arbitration, you may reject the change by sending us written
notice within 30 days of the change to REALTYSWAP’s address for Notice of Arbitration, in which case your Account will be
immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will
survive.
<br></br>
<br></br>
14.10 Enforceability. If Section 14.8 or the entirety of this Section 14 is found to be unenforceable, or if
REALTYSWAP receives an Opt-Out Notice from you, then the entirety of this Section 14 will be null and void and, in that
case, the exclusive jurisdiction and venue described in Section 16.2 will govern any action arising out of or related to
these Terms.
-16-
<br></br>
<br></br>
15. EMAIL COMMUNICATIONS. We may send you emails concerning our products and services, as well as
those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the
promotional email itself.
16. MISCELLANEOUS
16.1 General Terms. These Terms and any other agreements expressly incorporated by reference into these
Terms, are the entire and exclusive understanding and agreement between you and REALTYSWAP regarding your use of the
Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation
of law or otherwise, without our prior written consent. We may assign these Terms at any time without notice or
consent. The failure to require performance of any provision will not affect our right to require performance at any
other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms,
be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of Section headers in these
Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these
Terms the use of the word “including” means “including but not limited to.” If any part of these Terms is held to be
invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining
parts will remain in full force and effect.
16.2 Governing law. These Terms are governed by the laws of the state of California without regard to conflict
of law principles. You and REALTYSWAP submit to the personal and exclusive jurisdiction of the state courts and federal
courts located within Wilmington, Delaware for resolution of any lawsuit or court proceeding permitted under these
Terms.
<br></br>
<br></br>
16.3 Privacy Policy. Please read the REALTYSWAP Privacy Policy (the “Privacy Policy”) carefully for information
relating to our collection, use, storage, and disclosure of your personal information. The REALTYSWAP Privacy Policy is
incorporated by this reference into, and made a part of, these Terms.
16.4 Additional Terms. Your use of the Service is subject to all additional terms, policies, rules, or guidelines
applicable to the Service or certain features of the Service that we may post on or link to from the Service
(the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these
Terms.
16.5 Consent to Electronic Communications. By using the Service, you consent to receiving certain electronic
communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about
our electronic communications practices. You agree that any notices, agreements, disclosures, or other
communications that we send to you electronically will satisfy any legal communication requirements, including that
those communications be in writing.
16.6 Contact Information. The Service is offered by REALTYSWAP, Inc., located at 8 The Green, Suite # 11317, Dover,
US, DE, 19901. You may contact us by sending correspondence to that address or by emailing us at
dispute@REALTYSWAP.IO.
16.7 Notice to California Residents. If you are a California resident, under California Civil Code Section 1789.3,
you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department
of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
at +1-800-952-5210 in order to resolve a complaint regarding the Service or to receive further information regarding
use of the Service.
16.8 No Support. We are under no obligation to provide support for the Service. In instances where we may
offer support, the support will be subject to published policies.
16.9 International Use. The Service is intended for visitors located within the United States, although
international users are welcome where permitted under applicable law. However, we make no representation that the
Service is appropriate or available for use outside of the United States. Access to the Service from countries or
territories or by individuals where such access is illegal is prohibited.
-17-
16.10 Headings. The headings and captions contained in these Terms are for convenience of reference
only, will not be deemed to be a part of these Terms and will not be referred to in connection with the construction or
interpretation of these Terms.
16.11 Successors and Assigns. These Terms will inure to the benefit of REALTYSWAP, you, and the respective
permitted successors, permitted assigns, permitted transferees and permitted delegates of each, and will be binding
upon all of the foregoing persons and any person who may otherwise succeed to any right, obligation or liability under
these Terms by operation of law or otherwise. Unless otherwise expressly permitted under these Terms, you will not
share or provide a copy of, or transfer to, any person any digital assets or the private key associated with any digital
assets without notifying such person that such person will be bound by and become a party to these Terms by virtue
of thereof (or if the transferor has a reasonable belief that the transferee is aware of these Terms). You will not assign
any of your rights or delegate any of your liabilities or obligations under these Terms to any other person without
REALTYSWAP’s advance written consent. REALTYSWAP may freely assign, transfer or delegate its rights, obligations and liabilities
under these Terms to the maximum extent permitted by applicable law.
16.12 Severability. In the event that any provision of these Terms, or the application of any such provision
to any person or set of circumstances, will be determined by an arbitrator or court of competent jurisdiction to be
invalid, unlawful, void or unenforceable to any extent: (a) the remainder of these Terms, and the application of such
provision to persons or circumstances other than those as to which it is determined to be invalid, unlawful, void or
unenforceable, will not be impaired or otherwise affected and will continue to be valid and enforceable to the fullest
extent permitted by law; and (b) REALTYSWAP will have the right to modify these Terms so as to effect the original intent of
the parties as closely as possible in an acceptable manner in order that the transactions contemplated hereby be
consumed as originally contemplated to the fullest extent possible.
16.13 Force Majeure. REALTYSWAP will not incur any liability or penalty for not performing any act or fulfilling
any duty or obligation hereunder or in connection with the matters contemplated hereby by reason of any occurrence
that is not within its control (including any provision of any present or future law or regulation or any act of any
governmental authority, any act of God or war or terrorism, any epidemic or pandemic, or the unavailability, disruption
or malfunction of the Service, Internet, the World Wide Web or any other electronic network, any REALTYSWAP-Supported
Blockchain network or blockchain or any aspect thereof, or any consensus attack, or hack, or denial-of-service or other
attack on the foregoing or any aspect thereof, or on the other software, networks and infrastructure that enables REALTYSWAP
to provide the Service), it being understood that REALTYSWAP will use commercially reasonable efforts, consistent with
accepted practices in the industries in which REALTYSWAP operates, as applicable, to resume performance as soon as
reasonably practicable under the circumstances.
16.14 No Implied Waivers. No failure or delay on the part of REALTYSWAP in the exercise of any power, right,
privilege or remedy under these Terms will operate as a waiver of such power, right, privilege or remedy; and no
single or partial exercise of any such power, right, privilege or remedy will preclude any other or further exercise
thereof or of any other power, right, privilege or remedy. REALTYSWAP will not be deemed to have waived any claim arising
out of these Terms, or any power, right, privilege or remedy under these Terms, unless the waiver of such claim,
power, right, privilege or remedy is expressly set forth in a written instrument duly executed and delivered on behalf
of REALTYSWAP, and any such waiver will not be applicable or have any effect except in the specific instance in which it is
given.
16.15 Entire Agreement. These Terms constitutes the entire agreement between the parties relating to the
subject matter hereof and supersede all prior or contemporaneous agreements and understandings, both written and
oral, between the parties with respect to the subject matter hereof.
16.16 Rules of Interpretation
(a) “hereof,” “herein,” “hereunder,” “hereby” and words of similar import will, unless otherwise stated,
be construed to refer to these Terms as a whole and not to any particular provision of these Terms;
(b) “include(s)” and “including” will be construed to be followed by the words “without limitation”; 
-18-
<br></br>
<br></br>
(c) defined terms apply to both singular, plural, gerund or any other form of such terms;
(d) “or” will be construed to be the “inclusive or” rather than “exclusive or” unless the context requires
otherwise;
(e) any rule of construction to the effect that ambiguities are to be resolved against the drafting party will
not be applied in the construction or interpretation of these Terms;
(f) section titles, captions and headings are for convenience of reference only and have no legal or
contractual effect;
(g) whenever the context requires: the singular number will include the plural, and vice versa; the
masculine gender will include the feminine and neuter genders; the feminine gender will include the
masculine and neuter genders; and the neuter gender will include the masculine and feminine genders;
and
(h) except as otherwise indicated, all references in these Terms to “Sections,” “clauses,” etc., are intended
to refer to Sections of Sections, clauses, etc. of these Terms.
17. Notice Regarding Apple. This Section 17 only applies to the extent you are using our mobile application on an
iOS device. You acknowledge that these Terms are between you and REALTYSWAP only, not with Apple Inc. (“Apple”), and
Apple is not responsible for the Service or the content of it. Apple has no obligation to furnish any maintenance and
support services with respect to the Service. If the Service fails to conform to any applicable warranty, you may notify
Apple, and Apple will refund any applicable purchase price for the mobile application to you. To the maximum extent
permitted by applicable law, Apple has no other warranty obligation with respect to the Service. Apple is not
responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use
of the Service, including: (1) product liability claims; (2) any claim that the Service fails to conform to any applicable
legal or regulatory requirement; or (3) claims arising under consumer protection or similar legislation. Apple is not
responsible for the investigation, defense, settlement, and discharge of any third-party claim that the Service and/or
your possession and use of the Service infringe a third party’s intellectual property rights. You agree to comply with
any applicable third-party terms when using the Service. Apple and Apple’s subsidiaries are third-party beneficiaries
of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have
accepted the right) to enforce these Terms against you as a third-party beneficiary of these Terms. You hereby
represent and warrant that: (a) you are not located in a country that is subject to a U.S. Government embargo or that
has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any
U.S. Government list of prohibited or restricted parties.

</div>
      </div>
    </div>
   );
}

export default Terms;
