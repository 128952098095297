import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import blogImg from "../../Assets/blog-img.jpg";
import user from "../../Assets/user-placeholder.jpeg";
import styles from "./Blog.module.css";
import BlogBox from "../../Components/BlogBox/BlogBox";
import bg from "../../Assets/blog1.png";
import bg1 from "../../Assets/blog2.png";
import bg2 from "../../Assets/blog3.png";
import bg3 from "../../Assets/blog4.png";
import icon from "../../Assets/logowhite.png";
function Blog2() {
  return (
    <div className="container">
      <div className={styles.blog}>
        <h1>House on The BlockChain</h1>
        <p className={styles.date}>
          <span>July 18, 2022 By</span> Rich Harbinger . Announcement
        </p>
        <img src={bg2} alt="" />
        <h6>Introduction</h6>
        <p>
          
Welcome to the future of real estate, where land titles are decentralized and everything is on a blockchain. But first, let’s take a step back and talk about how we got here.       </p>
        <p>
        It's no secret that the real estate industry is big. In fact, it's massive. The global market for residential real estate is worth over $217 trillion and has been growing by an average of 7 percent annually since 2000.

There are several reasons why this industry is ripe for disruption:

The industry is fragmented, with no single point of entry or data standardization

There's a shortage of middlemen in the marketplace (why do middlemen exist? They bring buyers and sellers together)

More buyers are online than ever before

We believe there are many opportunities for blockchain technology to be used in the real estate market: building trust between parties, reducing costs associated with transactions and contracts etc...
     </p>
        <div className={styles.figure}>
          <img src={bg} alt="" />
          <span>Token launchpad on RealtySwap</span>
        </div>
        <p>
        On a blockchain, every transaction is recorded in a public ledger. This means that for each house sold, there is a record of who bought it and at what price. In addition, the location of the house can be tracked on an interactive map showing all homes currently being transacted—providing data about exactly where these transactions are taking place.

The house itself can also be owned by its residents through a co-operative arrangement with their local municipal government or state government, which would lease them land as opposed to owning it outright. The rents collected from tenants would go back into maintenance costs while any profits made could be reinvested into other housing projects in your community (and beyond).     </p>
        <h6>Tokenizing The Building Blocks of our Future</h6>
        <p>
        As the world moves toward a tokenized future, we are witnessing a shift away from traditional methods of conducting business. Tokenizing real estate is one such example of this revolutionary change in the global marketplace.

In this article, we will look at two projects that are working to bring tokenized real estate to consumers around the world:

House on the Block Chain (HOTBC)

Tokenize Real Estate (TRX)
        </p>
       
        <h6>NFT real estate marketplace built on the blockchain</h6>
        <p>
        NFTs are digital assets that are not limited to one platform. You can use them across multiple platforms and they can be traded in a decentralized market place. This means that with NFTs, you can trade your land on the blockchain and then use it in another game or app without having to worry about transferring it back and forth between accounts.

NFTs are also used as currency for real estate transactions on 3D metaverse platforms such as Second Life, Entropia Universe and Decentraland (to name just a few). This means that if you buy an island in Entropia Universe today with EntroCoin tokens (ERC-20), tomorrow you could sell it for Bitcoin cryptocurrency if you wanted to!
        </p>
        <div className={styles.figure}>
          <img src={bg} alt="" />
          <span>available starting on July 26, 2022</span>
        </div>
        <h6> RealtySwap: What’s Next</h6>
        <p>
          With the start of our launchpad, our goal remains to build the best
          Solana experience in the NFT ecosystem.
        </p>
        <p>On RealtySwap, you’ll find: </p>
        <ul>
          <li>
            <p>
              <strong>
                Your Solana collections will automatically appear on RealtySwap:{" "}
              </strong>
              House on the Block Chain is a blockchain-based NFT real estate marketplace. It's a secure, decentralized and immutable marketplace where you can buy and sell high quality real estate using non-fungible tokens (NFTs). The House on the Block Chain is an online platform that allows anyone to purchase fractional ownership in any property listed.
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>You’ll always have custody of your NFTs: </strong>SI think we’re just scratching the surface of what’s possible in real estate. We plan to continue improving upon this technology, as well as expanding our own business model to include more types of assets than just houses. This is an exciting time for real estate investors, and we look forward to helping them understand what Block Chain can do for them!
            </p>
          </li>
        </ul>
        <h6>Getting Started with Solana on RealtySwap</h6>
        <p>
          To buy Solana NFTs on RealtySwap, connect a Solana-supported wallet (such
          as Phantom or Glow), and check out our blog post for more information
          on how to get started with Solana on RealtySwap. You can find all Solana
          collections on RealtySwap here.
        </p>
        <div className={styles.bottomBlog}>
          <div className={styles.userRow}>
            <img src={user} alt="" />
            <h2>Rich Harbinger</h2>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3>Previous Post</h3>
              <a href="#">House on BlockChain</a>
            </div>
            <div className={styles.col}>
              <h3>Next Post</h3>
              <a href="#">What Are NFT's￼</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cont2}>
        <h1>Related Articles</h1>
        <Splide
          className={styles.splide}
          options={{
            perPage: 3,
            perMove: 1,
            drag: true,
            wdith: "100%",
            pagination: false,
            arrows: false,
            gap: "1rem",
            breakpoints: {
              1300: {
                perPage: 2,
              },
              750: {
                perPage: 1,
                gap: "0rem",
                width: "100%",
              },
            },
          }}
        >
          <SplideSlide>
            <BlogBox icon={icon} heading={"What are NFT's"} bg={bg1} />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Your HOUSE on the BlockChain"}
              bg={bg2}
            />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Understanding Blockchain and Cryptocurrency"}
              bg={bg3}
            />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Are we ready for Web3.0 Real Estate"}
              bg={bg}
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Blog2;
