import React, { useState } from "react";

import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotifBox from "../../Components/NotifBox/NotifBox";
import { useParams } from "react-router-dom";
import Select from "react-select/creatable";

import styles from "./Advertise.module.css";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faUsers,
  faUnlock,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import isEmpty from "../../utils/is-empty";
import Loading from "../../Components/Loading/Loading";
import { faCircleInfo, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ReactGA from 'react-ga4';

const initialState = {
  accountType: "EXTERNAL",
  bannerImageUrl: "",
  profileImageUrl: "",
  description: "",
  displayName: "",
  emailId: "",
  fbHandle: "",
  igHandle: "",
  redditHandle: "",
  tkHandle: "",
  twitterHandle: "",
  userName: "",
  walletAddress: "",
  walletChain: "ETH",
  servicezones:[]
};

const Advertise = () => {
 
  
  const [type, setType] = useState("broker");

const [openDrop, setOpenDrop] = useState(false);
const [dropdownContent, setDropdownContent] = useState("Realtor/Agent");
const [progress, setProgress] = useState("");
const { walletAddress } = "0x"

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [profileImgState, setProfileImgState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });
  const [bannerState, setBannerState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });
  const [formParams, updateFormParams] = useState({
    servicezones: []
  });
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");
  const {
    getRootProps: getProfileRootProps,
    getInputProps: getProfileInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setProfileImgState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setProfileImgState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const {
    getRootProps: getBannerRootProps,
    getInputProps: getBannerInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setBannerState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setBannerState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const uploadFile = (e, type) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("accountId","0x");
    formData.append("type","EXTERNAL");

    if (type === "profile") {
      setProfileImgState((prevState) => {
        return { ...prevState, isLoading: true };
      });

      formData.append("file", profileImgState.file);
    } else {
      setBannerState((prevState) => {
        return { ...prevState, isLoading: true };
      });

      formData.append("file", bannerState.file);
    }

    axios
      .post(`/uploadFile`, formData)
      .then((res) => {
        if (type === "profile") {
          setFormState((prevState) => {
            return { ...prevState, profileImageUrl: res.data.fileName };
          });
        } else {
          setFormState((prevState) => {
            return { ...prevState, bannerImageUrl: res.data.fileName };
          });
        }

        toast.success(
          `${
            type === "profile" ? "Profile image" : "Profile "
          } uploaded successfully`
        );
      })
      .catch((err) => {
        console.log(err);
      toast.error("Uh Oh! Something's not right. Please try again later.");
      })
      .finally(() => {
        if (type === "profile") {
          setProfileImgState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        } else {
          setBannerState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        }
      });
  };


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    
    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };



  const formSubmitHandler = (e) => {
    e.preventDefault();

    setIsFormLoading(true);
    setFormState((prevState) => {
      return { ...prevState, walletAddress };
    });
    const serviceZones = [];
    formState.accountType = "EXTERNAL";
    formState.servicetype = dropdownContent;
    formParams.servicezones.map((el) => serviceZones.push(el.value)) ;
    formState.servicezones = serviceZones;
    
    if(!isValidEmail(formState.emailId)){
      toast.error("Please enter a Valid Email Address");
      setIsFormLoading(false);
      return;
    }
    if(formState.bannerImageUrl.length <=0){
      toast.error("Please Upload a Profile Picture First");
      setIsFormLoading(false);
      return;
    }
    axios
      .post("/profile", formState)
      .then((res) => {
        setNotif("success");
        setMessage("Thank you for Registring with Us!!");
        setIsFormLoading(false);
        setFormState(initialState);
       
      })
      .catch((err) => {
        console.log(err);
        toast.error("Uh Oh! Something's not right");
        setIsFormLoading(false);
      })
      .finally(() => {
        setIsFormLoading(false);
       
      });
  };

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
   
    <div className={styles.settings}>
      <h1>Join Us. </h1> <hr className={styles.hr} />
      <div className={styles.ownerDetails}>
        <p > 
       
          ** Limited Time, free account setup for real estate agents/providers.
        </p>
      </div>
      <br></br>
      {isFormLoading && <Loading progress={progress} />}

     {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }

      <div className={styles.bottomSettings}>
      <form onSubmit={formSubmitHandler}>
          <div className={styles.typeRow}>
            <div
              onClick={() => {
                setType("agent");
              }}
              className={`${styles.typeBox} ${
                type === "agent" ? styles.activeType : ""
              }`}
            >
              <FontAwesomeIcon icon={faDollarSign} />
              <h2>Agent/Broker</h2>
            </div>
            <div
              onClick={() => {
                setType("professional");
              }}
              className={`${styles.typeBox} ${
                type === "professional" ? styles.activeType : ""
              }`}
            >
              <FontAwesomeIcon icon={faClock} />
              <h2>Other Professional</h2>
            </div>
          </div>
          <div className={styles.inputCol}>
            <h2>Screen Name</h2>
            <input
              type="text"
              required
              name="displayName"
              placeholder="Enter Username"
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.inputCol}>
            <h2>Company/Agency Name</h2>
            <input
              type="text"
              required
              name="companyName"
              placeholder="Enter Company/Agency Name"
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.inputCol}>
            <h2>Bio</h2>
            <textarea
                   type="text"
                required
                placeholder="e.g. 'Tell the world your story!'"
                onChange={inputChangeHandler}
                name="description"
              ></textarea>
          </div>
          <div className={styles.inputCol2}>
              <h2>Service Codes</h2>
              <Select
                value={formParams.servicezones}
                isMulti
                placeholder="Enter Multiple ZipCodes!"
                name="servicezones"
                // options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                openMenuOnClick={false}
                onChange={(e, e2, e3) => {
                  // console.log(e2, e3);
                  updateFormParams({
                    ...formParams,
                    servicezones: e,
                  });
                }}
              />
            </div>
        
          <div className={styles.inputCol}>
            <h2>Email Address</h2>
            <input
              type="text"
              required
              name="emailId"
              placeholder="Enter Email Address"
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.inputCol}>
            <h2>Reachable @</h2>
            <input
              type="text"
              required
              name="phNo"
              placeholder="Enter Phone No."
              onChange={inputChangeHandler}
            />
          </div>
         
          <div className={styles.inputCol}>
            <h2>Social Connections</h2>
            <div className={styles.inputStack}>
              <div className={styles.inputDiv}>
                <FontAwesomeIcon icon={faInstagram} />
                <input
                  type="text"
                  name="igHandle"
                  placeholder="Your Instagram Handle"
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.inputDiv}>
                <FontAwesomeIcon icon={faFacebookF} />
                <input
                  type="text"
                  name="fbHandle"
                  placeholder="Your FaceBook Handle"
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.inputDiv}>
                <FontAwesomeIcon icon={faTwitter} />
                <input
                  type="text"
                  name="twitterHandle"
                  placeholder="Your Twitter Handle "
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
         
          <div className={styles.dropDown}>
              <h6
                onClick={() => {
                  setOpenDrop((prev) => {
                    return !prev;
                  });
                }}
              >
                  Speciality: {dropdownContent} <FontAwesomeIcon icon={faChevronDown} />
              
              </h6>
              <div
                className={`${styles.mainDrop} ${
                  openDrop && styles.activeDrop
                }`}
              >
                {/* drop down ---------------------------------- */}
                <ul>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Realtor/Agent
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Mortgage Servicer
                  </li>
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Insurance Agent
                  </li>
                 
                  <li
                    onClick={(e) => {
                      setDropdownContent(e.target.innerText);
                      setOpenDrop(false);
                    }}
                  >
                    Other
                  </li>
                </ul>
                {/* drop down ends---------------------------------- */}
              </div>
              
          </div>
          <button type="submit" className="cta-btn">
            Save
          </button>
          </div>
        </form>
        <div className={styles.profilePic}>
            <h4>
              Profile Picture <FontAwesomeIcon icon={faCircleInfo} />
            </h4>
            <form
              className="text-center"
              onSubmit={(e) => uploadFile(e, "banner")}
              encType="multipart/form-data"
            >
              <div {...getBannerRootProps({ className: "dropzone" })}>
                <input {...getBannerInputProps()} />
                <div className={styles.bannerWrap}>
                  {bannerState.dataUrl ? (
                    <img src={bannerState.dataUrl} alt="banner" />
                  ) : formState.bannerImageUrl ? (
                    <img src={formState.bannerImageUrl} alt="user" />
                  ) : (
                    <div className={styles.banner}></div>
                  )}
                </div>
              </div>

              {bannerState.dataUrl && (
                <button
                  disabled={bannerState.isLoading}
                  className="align-self-center cta-btn"
                >
                  {bannerState.isLoading ? "Loading..." : "Upload"}
                </button>
              )}
            </form>
          </div>
      </div>
    </div>
  );
};

export default Advertise;
