import React, { useEffect, useState } from "react";
import styles from "./styles/Collection.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MarketplaceJSON from "../../REALTYSWAPMarketPlace.json";
import axios from "axios";

import cardBg from "../../Assets/card-img.jpg";
import Card from "../../Components/Card/Card";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";

function TopCollection() {
  const [data, updateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, updateFetched] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    console.log(data);
    axios
      .post("/assetList?pageNo=0&pageSize=10", { filterType: "ALL" })
      .then((res) => {
        updateData(res.data.assetList);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Uh Oh! Something went wrong while fetching data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // async function getAllNFTs() {
  //   const ethers = require("ethers");
  //   //After adding your Hardhat network to your metamask, this code will get providers and signers
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const signer = provider.getSigner();
  //   //Pull the deployed contract instance
  //   let contract = new ethers.Contract(
  //     MarketplaceJSON.address,
  //     MarketplaceJSON.abi,
  //     signer
  //   );
  //   //create an NFT Token
  //   let transaction = await contract.getAllNFTs();

  //   //Fetch all the details of every NFT from the contract and display
  //   const items = await Promise.all(
  //     transaction.map(async (i) => {
  //       const tokenURI = await contract.tokenURI(i.tokenId);
  //       let meta = await axios.get(tokenURI);
  //       meta = meta.data;

  //       let price = ethers.utils.formatUnits(i.price.toString(), "ether");
  //       let item = {
  //         price,
  //         tokenId: i.tokenId.toNumber(),
  //         seller: i.seller,
  //         owner: i.owner,
  //         image: meta.image,
  //         name: meta.name,
  //         description: meta.description,
  //         beds: meta.propertyDtls ? meta.propertyDtls.attributes.beds : "",
  //         bath: meta.propertyDtls ? meta.propertyDtls.attributes.bath : "",
  //         sqft: meta.propertyDtls ? meta.propertyDtls.attributes.sqft : "",
  //         lotSqft: meta.propertyDtls
  //           ? meta.propertyDtls.attributes.lotSqft
  //           : "",
  //         yearBuilt: meta.propertyDtls
  //           ? meta.propertyDtls.attributes.yearBuilt
  //           : "",
  //         type: meta.propertyDtls ? meta.propertyDtls.attributes.type : "",
  //       };
  //       return item;
  //     })
  //   );

  //   updateFetched(true);
  //   updateData(items);
  // }

  // if (!dataFetched) getAllNFTs();
  return (
    <div className="container">
      {isLoading && <Loading />}
      <div className={styles.collection}>
        <h2 >RealtySwap is an innovative platform which aims to transform RealEstate by Tokenizing properties on the Blockchain Network.</h2>
      </div>
    </div>
  );
}

export default TopCollection;
