import React from "react";
import styles from "./styles/Hero.module.css";
import { Link } from "react-router-dom";
function Hero() {
  return (
    <div className="container">
      
    <div className={styles.update}>
 
      <h1>Real Estate.Next</h1>
      <hr></hr>

      <h2>
       Tokenizing Real World Assets on the Block Chain. 
      </h2>
      <div className={styles.btnDiv}>
            <Link to="/Explore" className="cta-btn">
              Explore
            </Link>
            <Link to="/Create" className="cta-btn">
              Create
            </Link>
          </div>
    </div>
  
  </div>
  );
}

export default Hero;
