import React, { useState } from "react";
import styles from "./FaqTab.module.css";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function FaqTab({ question, answer }) {
  const [active, setActive] = useState(false);
  return (
    <div className={styles.faqTab}>
      <div className={styles.faqBtn} onClick={() => setActive((prev) => !prev)}>
        <h3>{question}</h3>
        <FontAwesomeIcon
          className={active ? styles.rotate : ""}
          icon={faPlus}
        />
      </div>
      <div className={`${styles.answer} ${active ? styles.overflow : ""}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
}

export default FaqTab;
