import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./NotifBox.module.css";

const NotifBox = ({ success, text, closeModal }) => {
  useEffect(() => {
    setTimeout(() => {
      closeModal(false);
    }, 15000);
  }, []);

  return (
    <>
      <div onClick={() => closeModal(false)} className={styles.overlay}></div>
      <div className={styles.notifBox}>
        <FontAwesomeIcon
          onClick={() => closeModal(false)}
          className={styles.close}
          icon={faXmark}
        />
        <FontAwesomeIcon
          style={{ color: success ? "green" : "red" }}
          icon={success ? faCheckCircle : faXmarkCircle}
        />
        <p>{text}</p>
        <p></p>
      
        <Link  to={"/Profile"}>
           <button  className="cta-btn">
            OK.
          </button>
          </Link>
      </div>
      
    </>
  );
};

export default NotifBox;
