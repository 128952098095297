import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles/Settings.module.css";
import {
  faFacebookF,
  faInstagram,
  faReddit,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import isEmpty from "../../utils/is-empty";
import Loading from "../../Components/Loading/Loading";
import ReactGA from 'react-ga4';


const initialState = {
  accountType: "USER",
  bannerImageUrl: "",
  profileImageUrl: "",
  description: "",
  displayName: "",
  emailId: "",
  fbHandle: "",
  igHandle: "",
  redditHandle: "",
  tkHandle: "",
  twitterHandle: "",
  userName: "",
  walletAddress: "",
  walletChain: "ETH",
};

function Settings() {
  const { walletAddress } = useParams();
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [profileImgState, setProfileImgState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });
  const [bannerState, setBannerState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });

  const {
    getRootProps: getProfileRootProps,
    getInputProps: getProfileInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setProfileImgState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setProfileImgState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const {
    getRootProps: getBannerRootProps,
    getInputProps: getBannerInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setBannerState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setBannerState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const uploadFile = (e, type) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("accountId",walletAddress);
    if (type === "profile") {
      setProfileImgState((prevState) => {
        return { ...prevState, isLoading: true };
      });
      formData.append("type","PROFILE");
      formData.append("file", profileImgState.file);
    } else {

      setBannerState((prevState) => {
        return { ...prevState, isLoading: true };
      });
       formData.append("type","BANNER");
      formData.append("file", bannerState.file);
    }

    axios
      .post(`/uploadFile`, formData)
      .then((res) => {
        if (type === "profile") {
          setFormState((prevState) => {
            return { ...prevState, profileImageUrl: res.data.fileName };
          });
        } else {
          setFormState((prevState) => {
            return { ...prevState, bannerImageUrl: res.data.fileName };
          });
        }

        toast.success(
          `${
            type === "profile" ? "Profile image" : "Banner"
          } uploaded successfully`
        );
      })
      .catch((err) => {
        console.log(err);
       toast.error("Uh Oh! Something's not right. Please try again later.");
      })
      .finally(() => {
        if (type === "profile") {
          setProfileImgState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        } else {
          setBannerState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        }
      });
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    if(formState.walletAddress === '0x'){
      toast.error("Please Connect Metamask wallet to Mainnet Network");
      setIsFormLoading(false);
      return;
    }
    axios
      .post("/profile", formState)
      .then((res) => {
        console.log(res.data);
        toast.success("Profile updated successfully");
      })
      .catch((err) => {
        console.log(err);
     toast.error("Uh Oh! Something's not right");
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);

    // if (!isEmpty(walletAddress) && walletAddress !== "0x") {
    setIsProfileLoading(true);

    setFormState((prevState) => {
      return { ...prevState, walletAddress };
    });

    axios
      .get("/profile/" + walletAddress)
      .then((res) => {
        if (res.data) {
          setFormState(res.data);
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error("Uh Oh! Something went wrong while fetching your account");
      })
      .finally(() => {
        setIsProfileLoading(false);
      });
    // }
  }, [walletAddress]);

  return (
    <div className={styles.settings}>
      {(isFormLoading || isProfileLoading) && <Loading />}
      <h1>Profile Details</h1>
      <hr className={styles.hr} />
      <div className={styles.ownerDetails}>
        <p>
          {" "}
          Wallet Address: -<i> {walletAddress}</i>
        </p>
      </div>
      <hr className={styles.hr} />
      <div className={styles.bottomSettings}>
        {isFormLoading ? (
          <div>Loading...</div>
        ) : (
          <form onSubmit={formSubmitHandler}>
            <div className={styles.inputCol}>
              <h2>Username</h2>
              <input
                type="text"
                name="displayName"
                onChange={inputChangeHandler}
                value={formState.displayName || ""}
                placeholder="Enter Username"
              />
            </div>
            <div className={styles.inputCol}>
              <h2>Bio</h2>
              <input
                type="text"
                name="description"
                onChange={inputChangeHandler}
                placeholder="Tell the world your story!"
                value={formState.description || ""}
              />
            </div>
            <div className={styles.inputCol}>
              <h2>Email Address</h2>
              <input
                type="text"
                name="emailId"
                onChange={inputChangeHandler}
                placeholder="Enter Email Address"
                value={formState.emailId || ""}
              />
            </div>
            <div>
              <h3>Social Connections</h3>
              <p>Help Users reach out by connecting to your Social Accounts</p>
            </div>
            
            <div className={styles.inputCol}>
              <h2>Links</h2>
              <div className={styles.inputStack}>
              <div className={styles.inputDiv}>
                  <FontAwesomeIcon icon={faInstagram} />
                  <input
                    type="text"
                    name="igHandle"
                    onChange={inputChangeHandler}
                    placeholder="Your Instagram Handle"
                    value={formState.igHandle || ""}
                  />
                </div>
               
                <div className={styles.inputDiv}>
                  <FontAwesomeIcon icon={faFacebookF} />
                  <input
                    type="text"
                    name="fbHandle"
                    onChange={inputChangeHandler}
                    placeholder="Your FaceBook Handle"
                    value={formState.fbHandle || ""}
                  />
                </div>
                <div className={styles.inputDiv}>
                  <FontAwesomeIcon icon={faTwitter} />
                  <input
                    type="text"
                    name="twitterHandle"
                    onChange={inputChangeHandler}
                    placeholder="Your Twitter Handle "
                    value={formState.twitterHandle || ""}
                  />
                </div>
                <div className={styles.inputDiv}>
                  <FontAwesomeIcon icon={faReddit} />
                  <input
                    type="text"
                    name="redditHandle"
                    onChange={inputChangeHandler}
                    placeholder="Your Reddit Handle "
                    value={formState.redditHandle || ""}
                  />
                </div>
               
              </div>
            </div>

            <button disabled={isFormLoading} type="submit" className="cta-btn">
              {isFormLoading ? "Loading..." : "Save"}
            </button>
          </form>
        )}
        <div className={styles.picSettings}>
          <div className={styles.profilePic}>
            <h4>
              Profile Image <FontAwesomeIcon icon={faCircleInfo} />
            </h4>

            <form
              className="text-center"
              onSubmit={(e) => uploadFile(e, "profile")}
              encType="multipart/form-data"
            >
              <div {...getProfileRootProps({ className: "dropzone" })}>
                <input {...getProfileInputProps()} />
                <div className={styles.pictureWrap}>
                  {profileImgState.dataUrl ? (
                    <img src={profileImgState.dataUrl} alt="user" />
                  ) : formState.profileImageUrl ? (
                    <img src={formState.profileImageUrl} alt="user" />
                  ) : (
                    <div className={styles.picture}></div>
                  )}
                </div>
              </div>

              {profileImgState.dataUrl && (
                <button
                  disabled={profileImgState.isLoading}
                  className="align-self-center cta-btn"
                >
                  {profileImgState.isLoading ? "Loading..." : "Upload"}
                </button>
              )}
            </form>
          </div>
          <div className={styles.profilePic}>
            <h4>
              Profile Banner <FontAwesomeIcon icon={faCircleInfo} />
            </h4>
            <form
              className="text-center"
              onSubmit={(e) => uploadFile(e, "banner")}
              encType="multipart/form-data"
            >
              <div {...getBannerRootProps({ className: "dropzone" })}>
                <input {...getBannerInputProps()} />
                <div className={styles.bannerWrap}>
                  {bannerState.dataUrl ? (
                    <img src={bannerState.dataUrl} alt="banner" />
                  ) : formState.bannerImageUrl ? (
                    <img src={formState.bannerImageUrl} alt="user" />
                  ) : (
                    <div className={styles.banner}></div>
                  )}
                </div>
              </div>

              {bannerState.dataUrl && (
                <button
                  disabled={bannerState.isLoading}
                  className="align-self-center cta-btn"
                >
                  {bannerState.isLoading ? "Loading..." : "Upload"}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
