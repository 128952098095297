import styles from "./Contact.module.css";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
function Contact() {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formParams, updateFormParams] = useState({
    name: "",
    email: "",
    message: ""
  });
  //const { email, description, price } = formParams;
  function contactUs(e) {
    const { name, email, message } = formParams;
    e.preventDefault();
    setIsFormLoading(true);
    if (email === "") {
      toast.error("Please enter a valid Email Address");
      return;
    }
    axios
      .post("/asset/message", { type: "CONTACT_US", emailId: email ,message: message,name :name})
      .then((res) => {
        toast.success("Thank you for Reaching out!!!");
      })
      .catch((err) => toast.error("Uh Oh an error occured"))
      .finally(() => {
      
      });
  };
  return (
    <div className={styles.contact}>
     
      <h1>Contact Us</h1>
      <form>
      
        <div className={styles.inputCol}>
          <h2>Name</h2>
          <input
                onChange={(e) =>
                  updateFormParams({ ...formParams, name: e.target.value })
                }
                value={formParams.name}
                type="text"
               
              />
        
        </div>
        <div className={styles.inputCol}>
          <h2>Email</h2>
          <input
                onChange={(e) =>
                  updateFormParams({ ...formParams, email: e.target.value })
                }
                value={formParams.email}
                type="text"
               
              />
      
        </div>
        <div className={styles.inputCol}>
          <h2>Query/Message:</h2>
          <textarea type="message"  placeholder="How can we help you.."
          onChange={(e) =>
                  updateFormParams({ ...formParams, message: e.target.value })
                }
                value={formParams.message}
                />
        </div>
        <button onClick={contactUs}  className="cta-btn">
        Submit
            </button>
       </form>
      <br></br>
    </div>
  );
}

export default Contact;
