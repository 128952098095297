import React from "react";
import styles from "./Tab.module.css";
import cardBg from "../../Assets/ether.png";
function Tab({ img, title, desc, date }) {
  return (
    <div className={styles.activityTab}>
      <img src={img || cardBg} alt="" />
      <div className={styles.tabDesc}>
        <h2>{title}</h2>
        <h3>{desc}</h3>
        <p ><i>{date}</i></p>
      </div>
    </div>
  );
}

export default Tab;
