import React, { useEffect, useRef, useState } from "react";
import styles from "./ThumbnailSlider.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ThumbnailSlider = ({ images, setGal }) => {
  const slider1 = useRef();
  const slider2 = useRef();
  useEffect(() => {
    slider1.current.sync(slider2.current.splide);
  }, [slider1, slider2]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.sliderDiv}>
      <FontAwesomeIcon
        className={styles.close}
        icon={faXmark}
        onClick={() => setGal(false)}
      />
      <Splide
        className={`${styles.thumbnail} thumbnail-slide`}
        ref={(slider) => (slider2.current = slider)}
        options={{
          pagination: false,
          arrows: false,
          isNavigation: true,
          width: "100%",
          perPage: 3.5,
          direction: "ttb",
          height: "100vh",
          gap: "1rem",
        }}
      >
        {images.map((elem, idx) => {
          return (
            <SplideSlide key={"thumbnail" + idx}>
              <img src={elem} />
            </SplideSlide>
          );
        })}
      </Splide>
      <Splide
        className={`${styles.mainSlider} main-slide`}
        ref={(slider) => (slider1.current = slider)}
        options={{
          pagination: false,
          arrows: false,
          width: "100%",
          rewind: true,
          drag: true,
          type: "loop",
          perPage: 1,
          height: "100vh",
          lazyLoad: "sequential",
          breakpoints: {
            1226: {
              arrows: true,
              pagination: true,
              height: "100vh",
            },
          },
        }}
      >
        {images.map((elem, idx) => {
          return (
            <SplideSlide key={"big" + idx}>
              <img src={elem} />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default ThumbnailSlider;
