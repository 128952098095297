import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as solidHeart,
  faTowerBroadcast,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Card.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Card({
  imageUrl,
  name,
  beds,
  bath,
  sqft,
  yearBuilt,
  tokenId,
  propAddressId,
  pointerNone,
}) {
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const [liked, setLiked] = useState(false);
  const likeHandler = () => {
    if (!walletAddress) {
      toast.error("Please connect the wallet");
      return;
    }
    const status = !liked ? 1 : 0;
    axios
      .post("/asset/fav", { status, propAddressId, walletAddress })
      .then((res) => setLiked((prevState) => !prevState))
      .catch((err) => {});
  };
  return (
    <div
      style={
        pointerNone ? { cursor: "not-allowed", pointerEvents: "none" } : {}
      }
      className={styles.card}
    >
      <Link to={`/Assets/${propAddressId}`}>
        <img className={styles.cardBg} src={imageUrl || ""} alt="" />
      </Link>
      <div className={styles.absRow}>
        <h6>Open</h6>
        <FontAwesomeIcon
          className={`${liked && styles.redColor}`}
          onClick={likeHandler}
          icon={liked ? solidHeart : faHeart}
        />
      </div>
      <div className={styles.cardDesc}>
        <h3>{name}</h3>
        {/* <h4>{description}</h4> */}
        <div className={styles.row}>
          <p>
            <strong>{beds} </strong> bds
          </p>
          <p>
            <strong>{bath} </strong> ba
          </p>
          <p>
            <strong>{sqft} </strong> sqft
          </p>
          <p>
            <strong>{yearBuilt} </strong> year built
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
