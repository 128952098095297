import React, { useEffect, useState } from "react";
import styles from "./styles/Collection.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MarketplaceJSON from "../../REALTYSWAPMarketPlace.json";
import axios from "axios";

import cardBg from "../../Assets/card-img.jpg";
import Card from "../../Components/Card/Card";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";

function TrendingCollection() {
  const [data, updateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, updateFetched] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    axios
      .post("/assetList?pageNo=0&pageSize=10", { 
        filterType: "RADIUS",
        latitude: 26.742482, // Get From Google  API
        longitude:  -80.109139, // Get From Google  API
        radius: 30
      })
      .then((res) => {
        updateData(res.data.assetList);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Uh Oh! Something went wrong while fetching data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container trend">
      {isLoading && <Loading />}
      <div className={styles.collection}>
        <h1>Trending Collection</h1>
        <Splide
          options={{
            gap: "0rem",
            perPage: 3.5,
            type: "loop",
            perMove: 1,
            pagination: true,
            drag: true,
            arrows: true,
            width: "100%",
            breakpoints: {
              1287: {
                perPage: 2,
              },
              734: {
                perPage: 1,
              },
            },
          }}
          className={"collectionSlider"}
        >
          {data.map((card, idx) => {
            return (
              <SplideSlide key={idx + Date()}>
                <Card {...card} />
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
}

export default TrendingCollection;
