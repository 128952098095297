import styles from "./CancelListing.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import Marketplace from "../../RealtySwap.json";
import Loading from "../Loading/Loading";
import img from "../../Assets/review-collection.webp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NotifBox from "../../Components/NotifBox/NotifBox";

const CancelListing = ({
  closeModal,
  id,
  ownerAddress,
  name,
  contract,
  tokenId,
  propAddressId,
  imageUrl,
  isListed
}) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [termsCheckBox, setTermsCheckBox] = useState(false);
  const [openDrop1, setOpenDrop1] = useState(false);
  const [openDrop2, setOpenDrop2] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [dropVal1, setDropVal1] = useState("ETH");
  const [dropVal2, setDropVal2] = useState("3 Days");
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const ethers = require("ethers");
  const [progress, setProgress] = useState("");

  const [formParams, updateFormParams] = useState({
    offerPrice: 0,
    startTime: "",
    endTime: "",
  });

  async function cancelListing(e) {
    e.preventDefault();
    if (!walletAddress) {
      toast.error("Please connect your MetaMask Wallet");
      return;
    }
    if (!isListed) {
      toast.error("Asset Not Currently Listed");
      return;
    }
    
    try {
      setProgress("20%");
      setIsFormLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      console.log(signer.getAddress);
      //Pull the deployed contract instance
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );
      
      try {
        console.log("tokenId " + tokenId);
        console.log("signer.getAddress() " + signer.getAddress());
        let transaction = await contract.CancelNFTListing(tokenId);
        let txReceipt = await transaction.wait();
        setProgress("60%");
      } catch (error) {
        // Catch any errors for any of the above operations.
        const endIndex = error.message.search("{");
        if (endIndex >= 0) {
          toast.error(error.message);
        }
        return;
      }
      setProgress("70%");
      axios
      .post("/asset/list", {
        cancelListing: true,
        ownerWalletAddress: walletAddress,
        tokenId: tokenId,
        propAddressId:propAddressId
      }).then(() => {
          toast.success("Listing Cancelled Successfully");
        })
        .catch(() => {
          toast.error("Uh Oh! an error occured");
        })
        .finally(() => {
          updateFormParams({
            offerPrice: "",
            startTime: "",
            endTime: "",
          });
        });
        setProgress("100%");
      //closeModal(false);
    } catch (e) {
      console.log("Unable to Accept Offer", e);
      toast.error(
        "Unable to Accept Offer,Please contact support@realtyswap.io"
      );
    } finally {
      setIsFormLoading(false);
      navigate("/");
    }
  }

  return (
    <>
  {isFormLoading && <Loading progress={progress} />}      
      <div className={styles.overlay}></div>
    
        <div className={styles.offerDiv}>
          <div className={styles.header}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setTermsCheckBox(false)}
            />
            <h2>Cancel NFT Listing</h2>
            <FontAwesomeIcon onClick={() => closeModal(false)} icon={faXmark} />
          </div>
        
          <div className={styles.inputCol}>
            
            Pls Review below details {" "}
            <FontAwesomeIcon icon={faInfoCircle} />
          <div className={styles.infoTable}>
            <div className={styles.tableRow}>
              <h3>Property Address</h3>
              <a href="#">{name}</a>
            </div>
            <div className={styles.tableRow}>
              <h3>Creator</h3>

              <a
                style={{
                  maxWidth: "205px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                href="#"
              >
                {ownerAddress}
              </a>
            </div>

           
              <>
                <div className={styles.tableRow}>
                  <h3>Contract address</h3>
                  <a
                    style={{
                      maxWidth: "205px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    href="#"
                  >
                    {contract}
                  </a>
                </div>
              </>
          
            </div>
          </div>
          <button onClick={cancelListing} className="cta-btn">
            Cancel Listing
          </button>
        </div>
    
    </>
  );
};

export default CancelListing;
