import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import blogImg from "../../Assets/blog-img.jpg";
import user from "../../Assets/user-placeholder.jpeg";
import styles from "./Blog.module.css";
import BlogBox from "../../Components/BlogBox/BlogBox";
import bg from "../../Assets/blog1.png";
import bg1 from "../../Assets/blog2.png";
import bg2 from "../../Assets/blog3.png";
import bg3 from "../../Assets/blog4.png";
import icon from "../../Assets/logowhite.png";
function Blog1() {
  return (
    <div className="container">
      <div className={styles.blog}>
        <h1>What Are NFT's</h1>
        <p className={styles.date}>
          <span>March 18, 2022 By</span> Rohit Chopra . Announcement
        </p>
        <img src={bg3} alt="" />
        <p>
        Non-fungible token.

That doesn’t make it any clearer.

Right, sorry. “Non-fungible” more or less means that it’s unique and can’t be replaced with something else. For example, a bitcoin is fungible — trade one for another bitcoin, and you’ll have exactly the same thing
        </p>
        <p>
        A one-of-a-kind trading card, however, is non-fungible. If you traded it for a different card, you’d have something completely different. You gave up a Squirtle, and got a 1909 T206 Honus Wagner, which StadiumTalk calls “the Mona Lisa of baseball cards.” (I’ll take their word for it.)

How do NFTs work?

At a very high level, most NFTs are part of the Ethereum blockchain, though other blockchains have implemented their own version of NFTs. Ethereum is a cryptocurrency, like bitcoin or dogecoin, but its blockchain also keeps track of who’s holding and trading NFTs.

How do you pronounce NFT?

Almost everyone spells it out, saying “en eff tee.” The brave call them “nefts.” The enlightened have never had the word cross their lips.

What’s worth picking up at the NFT supermarket?

NFTs can really be anything digital (such as drawings, music, your brain downloaded and turned into an AI), but a lot of the current excitement is around using the tech to sell digital art. 
        </p>
        <div className={styles.figure}>
          <img src={bg} alt="" />
          <span>Solana launchpad on RealtySwap</span>
        </div>
        <p>
          We’re excited to start this program with two Solana creators as part
          of our launchpad experience. We are excited to launch and learn with
          these first drops, and we’re committed to expanding primary drops on
          RealtySwap.
        </p>
        <h6>Zoonies</h6>
        <p>
        Well, they’re pretty complex, but the basic idea is that blockchains are a way to store data without having to trust any one company or entity to keep things secure and accurate. There are definitely nuances and exceptions there, which you can read about in our blockchain explainer, but when most people say “blockchain,” that’s the kind of tech they’re talking about.

There’s also... a lot of nuance about whether NFT’s are on the blockchain or not, which we’ll dig into in a bit.
        </p>
        <div className={styles.figure}>
          <img src={bg} alt="" />
          <span>Zoonies will be available starting on July 20, 2022</span>
        </div>
      
        <div className={styles.figure}>
          <img src={bg2} alt="" />
          <span>Monkai will be available starting on July 26, 2022</span>
        </div>
        <h6>Solana on RealtySwap: What’s Next</h6>
        <p>
          With the start of our launchpad, our goal remains to build the best
          Solana experience in the NFT ecosystem.
        </p>
        <p>On RealtySwap, you’ll find: </p>
        <ul>
          <li>
            <p>
              <strong>
                Your Solana collections will automatically appear on RealtySwap:{" "}
              </strong>
              Any creator can launch a Solana collection and appear on RealtySwap
              automatically. To make this possible, RealtySwap will display all
              Metaplex Certified Collections (MCC) and the most popular Solana
              Candy Machine programs. Creators can find and share collection
              links with their community as soon as minting begins on the
              blockchain.
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>You’ll always have custody of your NFTs: </strong>Some
              Solana marketplaces require you to give up custody of your NFT in
              order to list it for sale. That means it’s not in your wallet and
              remains completely inaccessible for you in an “escrow” process.
              This practice is more common with Solana, but RealtySwap will never
              hold or “escrow” your NFTs and they will always safely remain in
              your wallet until you have sold or transferred them.
            </p>
          </li>
        </ul>
        <h6> Fungible versus non-fungible</h6>
        <p>
       
Before we plunge into NFTs, it's critical to comprehend the "non-fungible" part of "non-fungible token." When a thing is fungible, it implies it's tradable with one more of a similar thing. An exemplary model is a $1 dollar greenback: you could trade dollars with somebody you'd both still have $1.

Non-fungible, then again, implies the thing is absolutely remarkable, and subsequently has its own exceptional worth. For instance, two vehicles of a similar make and demonstrate could have various qualities in view of the number of miles that are on the odometer, their mishap records, or on the other hand in the event that it was recently claimed by a superstar.

How do NFTs function?
NFTs work on blockchain innovation. The blockchain is essentially a huge, computerized, openly available report. The most well known blockchains are appropriated across numerous hubs (read: individuals' PCs), which is the reason you'll hear them depicted as "decentralized."

So rather than a focal organization possessed server, the blockchain is dispersed across a shared organization. Besides the fact that this guarantees that the blockchain stays permanent, it likewise permits the hub administrators to bring in cash, rather than a solitary organization. Since the blockchain records and jelly history, changing provable validness and computerized ownership is extraordinarily situated.

At the point when somebody makes, moves, purchases, sells, or in any case accomplishes something with a NFT, that all gets recorded on the blockchain. This empowers confirmation.

This record fills in as an extremely durable explanation of legitimacy that can be seen or gotten to by anybody. Today, when you purchase a piece of craftsmanship or a gatherer's thing, it commonly accompanies a paper declaration of credibility, which you should then monitor until the end of time. It is effortlessly neglected, lost or obliterated, making an extremely delicate framework for validness. Blockchain's deal a straightforward and safer answer for this well established issue of demonstrating genuineness.

Suppose you need to purchase a piece of work of art from Tyler Hobbs. With NFTs, you can see the whole history of that part, every one of the past proprietors, each deal, as far as possible back to Hobbs' unique formation of the piece. Without NFTs, you wouldn't be aware on the off chance that you were purchasing the genuine piece or simply a great phony.

The effect of NFT innovation
Blockchain innovation is progressive for computerized merchandise. With NFTs, computerized merchandise can be provably scant, straightforwardly adaptable, and have confirmed proprietorship. However, you may be thinking… so what?

For makers, these new qualities are inconceivably strong. Rather than dispersing their craftsmanship, music, or different manifestations on stages that are customarily difficult to adapt, they're ready to sell exceptional and confirmed things on a blockchain-based commercial center. Notwithstanding the underlying deals, the fundamental code that powers NFTs additionally can likewise empower makers to get set maker income on each auxiliary deal. For instance, an engineer could make an in-game skin that can be utilized across different games and has laid out genuineness and possession, and that designer can bring in cash each time that skin is traded.

This innovation is progressive for authorities, as well. Envision you're going to purchase a show pass on the web — with NFTs, you can trust its credibility, in view of the undisputed blockchain history, rather than depending on the affiliate's statement.

What are NFTs utilized for?
There's really nothing that a NFT can't address, yet how about we investigate a portion of the manners in which NFTs are being utilized today, and expected executions for what's in store.

Workmanship
Craftsmen are making inconceivable and novel pieces with NFTs. Damien Hirst involved NFTs in his assortment "The Money", wherein he made advanced adaptations of 10,000 one of a kind actual works of art. Authorities had one year to choose if they needed the computerized or the actual adaptation of the work of art — whichever rendition they didn't pick would be obliterated.

Profile pictures (PFPs)
These are likely the tasks you've heard the most about: Exhausted Gorilla Yacht Club (BAYC), Doodles, Universe of Ladies (Amazing), and that's only the tip of the iceberg. For some individuals on the web, these PFPs really become their internet based character. In addition to the fact that they relate to the gathering, they unequivocally relate to their symbol. PFP projects are likewise based on holder benefits (like BAYC's popular yacht party) and local area (like Goodness, which gives a piece of their maker profit to ladies driven foundations).

Collectibles
NFTs carry an additional oomph to your customary collectibles. Rather than an actual b-ball exchanging card that sits in a folio under your bed, you can gather dynamic NFTs from the NBA's assortment "The Affiliation," where each card changes in light of that player and group's exhibition.

Space names
Naming guidelines like Ethereum Naming Help (ENS) and Bonfida (the same naming assistance for Solana) have arisen to smooth out naming for wallets, sites, and other blockchain applications. These assist with making utilizing the blockchain more easy to understand, with comprehensible names and inherent check.

Tagging and participation
Brands like Appearance and Coachella have gone to NFTs to execute restrictive tickets and passes. CameoPass highlights work from three conspicuous craftsmen, however holders of their NFTs likewise benefit from elite VIP interactive discussions and gatherings across the globe. Coachella presented their NFT commercial center and assortments in 2022, including lifetime passes.
        </p>
        <div className={styles.bottomBlog}>
          <div className={styles.userRow}>
            <img src={user} alt="" />
            <h2>Rohit Chopra </h2>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3>Previous Post</h3>
              <a href="#">Important Update on Email Vendor Security Incident</a>
            </div>
            <div className={styles.col}>
              <h3>Next Post</h3>
              <a href="#">Welcoming Chris Dixon to our Board of Directors￼</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cont2}>
        <h1>Related Articles</h1>
        <Splide
          className={styles.splide}
          options={{
            perPage: 3,
            perMove: 1,
            drag: true,
            wdith: "100%",
            pagination: false,
            arrows: false,
            gap: "1rem",
            breakpoints: {
              1300: {
                perPage: 2,
              },
              750: {
                perPage: 1,
                gap: "0rem",
                width: "100%",
              },
            },
          }}
        >
          <SplideSlide>
          <BlogBox icon={icon} heading={"What are NFT's"} bg={bg1} article="what-are-nfts" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Your HOUSE on the BlockChain"} bg={bg2} article="house-on-blockchain" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Understanding Blockchain and Cryptocurrency"} bg={bg3} article="understanding-cryptocurrency" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Are we ready for Web3.0 Real Estate"} bg={bg} article="web3-real-estate" />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Blog1;
