import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import styles from "./MetaConnect.module.css";
import metaLogo from "../../Assets/metaLogo.svg";
import { setWalletAddress } from "../../redux/walletReducer";

function MetaConnect() {
  const location = useLocation();
  const navigate = useNavigate();
  const [connected, toggleConnect] = useState(false);
  const [currAddress, updateAddress] = useState("0x");
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.wallet);

  function getAddress() {
    const ethers = require("ethers");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    signer
      .getAddress()
      .then((res) => {
        updateAddress(res);
        dispatch(setWalletAddress(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateButton() {
    navigate("/");
  }
  function metaMaskDownload() {
    window.open("https://metamask.io/download/");
  }

  async function connectWebsite() {
    if (null != window.ethereum) {
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      if (chainId !== "0x1") {
        //alert('Incorrect network! Switch your metamask network to Rinkeby');
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
      }
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(() => {
          // updateButton();
          // console.log("here");
          getAddress();
          window.location.replace(location.pathname);
        });
    }else{
      window.open("https://metamask.io/download/");
    }
  }
  useEffect(() => {
    if (null != window.ethereum) {
      let val = window.ethereum.isConnected();
      if (val) {
        // console.log("here");
        getAddress();
        toggleConnect(val);
      }

      window.ethereum.on("accountsChanged", function (accounts) {
        window.location.replace(location.pathname);
      });
    }
  });

  return (
    <div className={styles.metaConnect}>
      <h1>Connect Your Wallet</h1>
      <p>
        If you don't have a <span>wallet</span> yet, you can select a provider below
        and create one now.
      </p>
      <div onClick={connectWebsite} className={styles.metaRow}>
        <div className={styles.leftMeta}>
          <img src={metaLogo} alt="" />
          <h3>MetaMask</h3>
        </div>
        <h3 className={styles.pop} >Popular</h3>
      </div>
    </div>
  );
}

export default MetaConnect;
