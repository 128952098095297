import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import blogImg from "../../Assets/blog-img.jpg";
import user from "../../Assets/user-placeholder.jpeg";
import styles from "./Blog.module.css";
import BlogBox from "../../Components/BlogBox/BlogBox";
import bg from "../../Assets/blog1.png";
import bg1 from "../../Assets/blog2.png";
import bg2 from "../../Assets/blog3.png";
import bg3 from "../../Assets/blog4.png";
import icon from "../../Assets/logowhite.png";

function Blog() {
  return (
    <div className="container">
      <div className={styles.blog}>
        <h1>Announcing OpenSea’s Solana Launchpad</h1>
        <p className={styles.date}>
          <span>July 18, 2022 By</span> Parth Chopra . Announcement
        </p>
        <img src={blogImg} alt="" />
        <p>
          We believe in a multi-chain future where people on OpenSea have access
          to NFTs across a vast number of blockchains. In the last year, Solana
          has emerged among the top blockchains for NFTs, and we share their
          vision of a scalable and inclusive NFT ecosystem.
        </p>
        <p>
          Today, we’re kicking off our Solana launchpad experience on OpenSea.
          With this new launchpad experience, creators can now host a new mint
          from beginning to end on OpenSea. We will help creators navigate all
          pre-mint activity, allowlist minting for their community and
          supporters, as well as all post-mint and secondary sales.
        </p>
        <div className={styles.figure}>
          <img src={blogImg} alt="" />
          <span>Solana launchpad on OpenSea</span>
        </div>
        <p>
          We’re excited to start this program with two Solana creators as part
          of our launchpad experience. We are excited to launch and learn with
          these first drops, and we’re committed to expanding primary drops on
          OpenSea.
        </p>
        <h6>Zoonies</h6>
        <p>
          Cute, mutant aliens called Zoonies are a highly developed civilization
          living on Planet Zoon. Zoonies lore tells stories of these aliens, who
          chose to stay isolated on their planet for thousands of years, until
          recently allowing humans to visit for the first time. Zoonies utility
          includes a playable arcade, 2D metaverse and more. 8,888 Zoonies will
          be available on OpenSea starting on July 20, 2022.
        </p>
        <div className={styles.figure}>
          <img src={blogImg} alt="" />
          <span>Zoonies will be available starting on July 20, 2022</span>
        </div>
        <h6>Monkai</h6>
        <p>
          Monkai is a narrative-driven NFT project that brings forth innovative
          utility, community building and an epic gaming metaverse. By obtaining
          a Monkai NFT, individuals can participate in the creation of the
          Monkai anime narrative and access utility via the Monkai DAO.
          Collection 1 will be available on OpenSea starting on July 26, 2022.
        </p>
        <div className={styles.figure}>
          <img src={blogImg} alt="" />
          <span>Monkai will be available starting on July 26, 2022</span>
        </div>
        <h6>Solana on OpenSea: What’s Next</h6>
        <p>
          With the start of our launchpad, our goal remains to build the best
          Solana experience in the NFT ecosystem.
        </p>
        <p>On OpenSea, you’ll find: </p>
        <ul>
          <li>
            <p>
              <strong>
                Your Solana collections will automatically appear on OpenSea:{" "}
              </strong>
              Any creator can launch a Solana collection and appear on OpenSea
              automatically. To make this possible, OpenSea will display all
              Metaplex Certified Collections (MCC) and the most popular Solana
              Candy Machine programs. Creators can find and share collection
              links with their community as soon as minting begins on the
              blockchain.
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>You’ll always have custody of your NFTs: </strong>Some
              Solana marketplaces require you to give up custody of your NFT in
              order to list it for sale. That means it’s not in your wallet and
              remains completely inaccessible for you in an “escrow” process.
              This practice is more common with Solana, but OpenSea will never
              hold or “escrow” your NFTs and they will always safely remain in
              your wallet until you have sold or transferred them.
            </p>
          </li>
        </ul>
        <h6>Getting Started with Solana on OpenSea</h6>
        <p>
          To buy Solana NFTs on OpenSea, connect a Solana-supported wallet (such
          as Phantom or Glow), and check out our blog post for more information
          on how to get started with Solana on OpenSea. You can find all Solana
          collections on OpenSea here.
        </p>
        <div className={styles.bottomBlog}>
          <div className={styles.userRow}>
            <img src={user} alt="" />
            <h2>Parth Chopra</h2>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3>Previous Post</h3>
              <a href="#">Important Update on Email Vendor Security Incident</a>
            </div>
            <div className={styles.col}>
              <h3>Next Post</h3>
              <a href="#">Welcoming Chris Dixon to our Board of Directors￼</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cont2}>
        <h1>Related Articles</h1>
        <Splide
          className={styles.splide}
          options={{
            perPage: 3,
            perMove: 1,
            drag: true,
            wdith: "100%",
            pagination: false,
            arrows: false,
            gap: "1rem",
            breakpoints: {
              1300: {
                perPage: 2,
              },
              750: {
                perPage: 1,
                gap: "0rem",
                width: "100%",
              },
            },
          }}
        >
          <SplideSlide>
            <BlogBox icon={icon} heading={"What are NFT's"} bg={bg1} />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Your HOUSE on the BlockChain"}
              bg={bg2}
            />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Understanding Blockchain and Cryptocurrency"}
              bg={bg3}
            />
          </SplideSlide>
          <SplideSlide>
            <BlogBox
              icon={icon}
              heading={"Are we ready for Web3.0 Real Estate"}
              bg={bg}
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Blog;
