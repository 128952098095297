import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import ReactPaginate from "react-paginate";

import styles from "./styles/SearchPage.module.css";
import Cards from "../../Components/Card/Card";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import ReactGA from 'react-ga4';

function SearchPage() {

  const { lat, lng } = useParams();
  const [defaultLocation, setDefaultLocation] = useState({
    lat: +lat,
    lng: +lng,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [data, updateData] = useState([]);
  // We start with an empty list of items.
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;

  const OPTIONS = {
    minZoom: 4,
    maxZoom: 18,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_API_KEY",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: +lat,
      lng: +lng,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  // ss
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleCenterChanged = () => {
    if (map) {
      const newCenter = map.getCenter();
      console.log(newCenter.lat()); // TO BE PASSSED TO API
      console.log(newCenter.lng()); // TO BE PASSSED TO API
      setDefaultLocation({ lat: newCenter.lat(), lng: newCenter.lng() });
      // Goes into a Loop if called
      axios
        .post(`/assetList?pageNo=${currentPage}&pageSize=${itemsPerPage}`, {
          filterType: "RADIUS",
          latitude: newCenter.lat(), // Get From Google  API
          longitude: newCenter.lng(), // Get From Google  API
          // latitude: "-74.16762811584077", // Get From Google  API
          // longitude: "40.349159350420635", // Get From Google  API
          radius: 50, // hardcode for now - This should be passed when we zoomin /out of map
        })
        .then((res) => {
          updateData(res.data.assetList);
          setPageCount(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);

          toast.error("Uh Oh! Something went wrong while fetching data");
        });
    }
  };

  // const reloadMap = React.useCallback(function callback(map) {
  //   axios
  //     .post("/assetList?pageNo=0&pageSize=10", {
  //       filterType: "RADIUS",
  //       latitude: +lat, // Get From Google  API
  //       longitude: +lng, // Get From Google  API
  //       // latitude: "-74.16762811584077", // Get From Google  API
  //       // longitude: "40.349159350420635", // Get From Google  API
  //       radius: 60, // hardcode for now - This should be passed when we zoomin /out of map
  //     })
  //     .then((res) => {
  //       updateData(res.data.assetList);
  //       console.log(res.data.assetList);
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       toast.error("Uh Oh! Something went wrong while fetching data");
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(event.selected);
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    setIsLoading(true);

    axios
      .post(`/assetList?pageNo=${currentPage}&pageSize=${itemsPerPage}`, {
        filterType: "RADIUS",
        latitude: +lat, // Get From Google  API
        longitude: +lng, // Get From Google  API
        // latitude: "-74.16762811584077", // Get From Google  API
        // longitude: "40.349159350420635", // Get From Google  API
        radius: 30, // hardcode for now - This should be passed when we zoomin /out of map
      })
      .then((res) => {
        updateData(res.data.assetList);
        setPageCount(res.data.totalPages);
        // console.log(res.data.assetList);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Uh Oh! Something went wrong while fetching data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, lat, lng]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    setCurrentItems(data.slice(itemOffset, endOffset));
    // setPageCount(Math.ceil(data.length / itemsPerPage));
    // setPageCount();
  }, [data, itemOffset, itemsPerPage]);
  // console.log(currentPage, pageCount);

  return (
    <>
      <div className={styles.searchPage}>
        {isLoading && <Loading />}
        <div className={styles.leftSearch}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "88vh" }}
              center={defaultLocation}
              options={OPTIONS}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onDragEnd={handleCenterChanged}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <>
                <Marker position={{ lat: +lat, lng: +lng }} />
                {data.map((el, idx) => {
                  return (
                    <Marker
                      key={"marker" + idx}
                      position={{
                        lat: el.latitude,
                        lng: el.longitude,
                      }}
                    />
                  );
                })}
              </>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`${styles.rightSearch} ${
            data.length <= 0 ? styles.overflowNone : ""
          }`}
        >
          {data.length > 0 ? (
            data.map((elem, idx) => {
              return <Cards {...elem} key={idx + Date() + "Searched"} />;
            })
          ) : (
            <h1 className={styles.nofound}>No Nfts found</h1>
          )}

          <div className={styles.pagination}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName={styles.selected}
              disabledClassName={styles.disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
