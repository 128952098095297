import React from "react";
import Comp from "./Comp";
import Faq from "./Faq";
import Hero from "./Hero";
import Learn from "./Learn";
import Review from "./Review";
import Table from "./Table";
function Index() {
  return (
    <div>
      <Hero />
      <Comp />
      <Review />
      <Faq />
      <Learn />
    </div>
  );
}

export default Index;
