import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faChevronDown,
  faGear,
  faList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import ApproveListing from "../../Components/ApproveListing/ApproveListing";

import styles from "./styles/Profile.module.css";
import pic from "../../Assets/profile-pic.webp";
import UserPlaceholder from "../../Assets/user-placeholder.jpeg";
import Card from "../../Components/Card/Card";
import Tab from "../../Components/Tab/Tab";
import cardBg from "../../Assets/card-img.jpg";
import MarketplaceJSON from "../../REALTYSWAPMarketPlace.json";
import axios from "axios";
import { toast } from "react-toastify";
import isEmpty from "../../utils/is-empty";
import Loading from "../../Components/Loading/Loading";
import { useSelector } from "react-redux";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import ReactGA from 'react-ga4';
import NotifBox from "../../Components/NotifBox/NotifBox";

function Profile() {
  const [approve, setApprove] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState("owned");
  const [openDrop, setOpenDrop] = useState(false);
  const [subDrop, setSubDrop] = useState(false);
  const [subDropVal, setSubDropVal] = useState("ETH");
  const [sortDrop, setSortDrop] = useState(false);
  const [sortVal, setSortVal] = useState("Recently Listed");
  const [data, updateData] = useState([]);
  const [dataFetched, updateFetched] = useState(false);
  const [address, updateAddress] = useState("0x");
  const [totalPrice, updateTotalPrice] = useState("0");
  const [isLoading, setIsLoading] = useState(true);
  const [userState, setUserState] = useState({});
  const { address: addressRedux } = useSelector((state) => state.wallet);
  const [isNFTLoading, setIsNFTLoading] = useState(false);
  const [nftCardsData, setNftCardsData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");

  async function getNFTData(tokenId) {
    const ethers = require("ethers");
    let sumPrice = 0;
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    // if (!isEmpty(window.ethereum)) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();

    const network = await provider.getNetwork();
    const networkName = network.name;
    if(networkName != "homestead"){
      setNotif("failed");
      setMessage("Thank you for Visiting us. Please connect wallet to Ethereum Mainnet!!");
      setIsLoading(false);
      return;
    }
    
    updateFetched(true);
    updateAddress(addr);
   
  }

  const params = useParams();
  const tokenId = params.tokenId;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (addressRedux) {
      if (!dataFetched) getNFTData(tokenId);
    }
  }, [dataFetched, addressRedux]);

  useEffect(() => {
    if (!isEmpty(address) && address !== "0x") {
      setIsLoading(true);

      axios
        .get("/profile/" + address)
        .then((res) => {
          setUserState(res.data);
        })
        .catch((err) => {
          console.log(err);

          toast.error(
            "Uh Oh! Something went wrong while fetching your account"
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [address]);

  useEffect(() => {
    let reqBody = {
      // owner_address: address,
      owner_address: addressRedux,
    };
    if (
      activeCategory === "owned" ||
      activeCategory === "created" ||
      activeCategory === "liked"
    ) {
      //changing the filter type ----------
      if (activeCategory === "owned") {
        reqBody.filterType = "OWNER_ADDRESS";
      } else if (activeCategory === "created") {
        reqBody.filterType = "OWNER_ADDRESS";
      } else if (activeCategory === "liked") {
        reqBody.filterType = "FAV";
      } else if (activeCategory === "offers") {
        reqBody.filterType = "offers";
      }
      setIsNFTLoading(true);
      //getting assets when its not the activity or offers tab
      axios
        .post("/assetList", reqBody)
        .then((res) => {
          console.log(res.data.assetList);
          setNftCardsData(res.data.assetList);
        })
        .catch((err) => {
          console.log(err);

          toast.error("Uh Oh! Something went wrong while fetching data");
        })
        .finally(() => {
          setIsNFTLoading(false);
        });
      //getting assets when its  the activity  tab
    } else if (activeCategory === "activity") {
      setIsNFTLoading(true);
      axios
        .get(`profile/activity/${addressRedux}`)
        .then((res) => {
          setNftCardsData(res.data);
        })
        .catch((err) => {
          toast.error("Uh Oh! Something went wrong while fetching data");
        })
        .finally(() => {
          setIsNFTLoading(false);
        });
    } else if (activeCategory === "offers") {
      setIsNFTLoading(true);
      axios
        .get(`/asset/offers/${addressRedux}/`)
        .then((res) => {
          setOffersData(res.data);
        })
        .catch((err) => {
          toast.error("Uh Oh! Something went wrong while fetching data");
        })
        .finally(() => {
          setIsNFTLoading(false);
        });
    }
    
  }, [address, activeCategory]);

  return (
    <div className="container">
      {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }


      {(isNFTLoading || isLoading) && <Loading />}
      <div className={styles.profile}>
        <div
          className={styles.topProfile}
          style={{
            backgroundImage: `url(${userState.bannerImageUrl || cardBg})`,
          }}
        >
          <img src={userState.profileImageUrl || UserPlaceholder} alt="" />
        </div>
        <div className={styles.profileDesc}>
          <div className={styles.desc}>
            <h1>
              {userState.displayName}{" "}
             
              {!isEmpty(userState.joinDate) ? (
                 <span className={styles.joinDate}>
                Joined on: {new Date(userState.joinDate).toLocaleDateString()}
                </span>
              ):
              <span className={styles.joinDate}>
              Joined on:
              </span>
            }
              
            </h1>
            <p>{userState.description}</p>
            <div className={styles.btnDiv}>
              <button className="cta-btn">
                <FontAwesomeIcon icon={faPlus} />
                Follow
              </button>
              <button className="grey-btn">Message</button>
              <button className="grey-btn">
                <FontAwesomeIcon icon={faArrowUpFromBracket} />
              </button>
              {!isEmpty(userState.accountType) ? (
              <Link to={"/Settings/" + address} className="grey-btn">
                <FontAwesomeIcon icon={faGear} />
              </Link>
              ):
              <Link to={"/Settings/" + address} >
             <button className="cta-btn">
                <FontAwesomeIcon icon={faPlus} />
                Create New Profile
              </button>
              </Link>
           
            }
            </div>
          </div>
          <div className={styles.followBox}>
            <div className={styles.followRow}>
              <h3>Followers</h3>
              <p>{userState.followers}</p>
            </div>
            <div className={styles.followRow}>
              <h3>Following</h3>
              <p>{userState.following}</p>
            </div>
            <div className={styles.followRow}>
              <h3>Address</h3>
              <p className={styles.address}>{address}</p>
            </div>
          </div>
        </div>
        <div className={styles.nftCategory}>
          <h2
            onClick={() => {
              setActiveCategory("owned");
            }}
            className={`${styles.category} ${
              activeCategory === "owned" && styles.activeCat
            }`}
          >
            Owned <span>{data.length}</span>
          </h2>
          <h2
            onClick={() => {
              setActiveCategory("created");
            }}
            className={`${styles.category} ${
              activeCategory === "created" && styles.activeCat
            }`}
          >
            Created <span>{nftCardsData.length}</span>
          </h2>
          <h2
            onClick={() => {
              setActiveCategory("liked");
            }}
            className={`${styles.category} ${
              activeCategory === "liked" && styles.activeCat
            }`}
          >
            Liked <span>{nftCardsData.length}</span>
          </h2>
          <h2
            onClick={() => {
              setActiveCategory("activity");
            }}
            className={`${styles.category} ${
              activeCategory === "activity" && styles.activeCat
            }`}
          >
            Activity <span>{nftCardsData.length}</span>
          </h2>
          <h2
            onClick={() => {
              setActiveCategory("offers");
            }}
            className={`${styles.category} ${
              activeCategory === "offers" && styles.activeCat
            }`}
          >
            Offers <span>{offersData.length}</span>
          </h2>
        </div>
        <div className={styles.filterBar}>
          <div className={styles.leftFilter}>
            <div className={styles.dropDown}>
              <button
                onClick={() => {
                  setOpenDrop((prev) => {
                    if (prev === "blockchain") {
                      return false;
                    } else {
                      return "blockchain";
                    }
                  });
                }}
              >
                BlockChain <FontAwesomeIcon icon={faChevronDown} />
              </button>
              <div
                className={`${styles.mainDrop} ${
                  openDrop === "blockchain" && styles.activeDrop
                }`}
              >
                <ul>
                  <li>Ethereum</li>
                  <li>Solana</li>
                </ul>
                <div className={styles.btnDivDrop}>
                  <button className={`${styles.white} ${styles.disabled}`}>
                    Clear
                  </button>
                  <button className={styles.black}>Apply</button>
                </div>
              </div>
            </div>
            <div className={styles.dropDown}>
              <button
                onClick={() => {
                  setOpenDrop((prev) => {
                    if (prev === "category") {
                      return false;
                    } else {
                      return "category";
                    }
                  });
                }}
              >
                Category <FontAwesomeIcon icon={faChevronDown} />
              </button>
              <div
                className={`${styles.mainDrop} ${
                  openDrop === "category" && styles.activeDrop
                }`}
              >
                <ul>
                  <li>All</li>
                  <li>Single Family</li>
                  <li>Condo</li>
                  <li>Townhome</li>
                  <li>Multi-Family</li>
                  <li>Land</li>
                  <li>Mobile/Manufactured</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>

            <div className={styles.dropDown}>
              <button
                onClick={() => {
                  setOpenDrop((prev) => {
                    if (prev === "price") {
                      return false;
                    } else {
                      return "price";
                    }
                  });
                }}
              >
                Price range <FontAwesomeIcon icon={faChevronDown} />
              </button>
              <div
                className={`${styles.mainDrop} ${styles.smallHeight} ${
                  openDrop === "price" && styles.activeDrop
                }`}
              >
                <div className={styles.subDropDiv}>
                  <button onClick={() => setSubDrop((prev) => !prev)}>
                    {subDropVal} <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                  <div
                    className={`${styles.subDrop} ${
                      subDrop && styles.activeDrop
                    }`}
                  >
                    <p
                      onClick={(e) => {
                        setSubDropVal(e.target.innerText);
                        setSubDrop(false);
                      }}
                    >
                      Eth
                    </p>
                  </div>
                </div>
                <div className={styles.inputRowDrop}>
                  <input type="text" placeholder="From" />
                  <input type="text" placeholder="To" />
                </div>
                <div className={styles.btnDivDrop}>
                  <button className={`${styles.white} ${styles.disabled}`}>
                    Clear
                  </button>
                  <button className={styles.black}>Apply</button>
                </div>
              </div>
            </div>
            {/* mobile sort drop down ------------------- */}
            <div className={styles.dropDown}>
              <button onClick={() => setSortDrop((prev) => !prev)}>
                <h6 className={styles.absFilter}>Sort</h6>
                {sortVal} <FontAwesomeIcon icon={faChevronDown} />
              </button>
              <div
                className={`${styles.sortDrop} ${
                  sortDrop && styles.activeDrop
                }`}
              >
                <h4>Sort By</h4>
                <h2
                  onClick={(e) => {
                    setSortVal(e.target.innerText);
                    setSortDrop(false);
                  }}
                >
                  Recently Listed
                </h2>
                <h2
                  onClick={(e) => {
                    setSortVal(e.target.innerText);
                    setSortDrop(false);
                  }}
                >
                  Price: Low to High
                </h2>
                <h2
                  onClick={(e) => {
                    setSortVal(e.target.innerText);
                    setSortDrop(false);
                  }}
                >
                  Price: High to Low
                </h2>
                <h2
                  onClick={(e) => {
                    setSortVal(e.target.innerText);
                    setSortDrop(false);
                  }}
                >
                  Auction Ending Soon
                </h2>
              </div>
            </div>
            {/* mobile sort drop down end ------------------- */}
          </div>
        
        </div>
        {activeCategory === "owned" ||
        activeCategory === "liked" ||
        activeCategory === "created" ? (
          <div className={styles.cardsGrid}>
            {nftCardsData.map((el, idx) => {
              return <Card key={el.id} {...el} />;
            })}
          </div>
        ) : (
          ""
        )}
        {activeCategory === "activity" && (
          <div className={styles.activityGrid}>
            {nftCardsData &&
              nftCardsData.map((el, idx) => {
                return <Tab key={`tab${idx}`} {...el} />;
              })}
          </div>
        )}
        {activeCategory === "offers" && (
          // offers -----------------------------
        
          <div className={styles.offers}>
        
            <div className={styles.offerBtn}>
              <p>
                <FontAwesomeIcon icon={faList} /> Offers
              </p>
            </div>
            <div className={styles.offerTable}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Address</th>
                    <th>From</th>
                    <th>Offer</th>
                    <th>Status</th>
                    <th>Expires</th>
                    <th>Approve Y/N</th>
                  </tr>
                </thead>
                <tbody>
                  {offersData &&
                    offersData.map((elem) => {
                      return (
                        
                        <tr style={{ textAlign: "center" }} key={elem.id}>
                                {approve ==="A" && (
            <ApproveListing
              tokenId={elem.tokenId}
              fromWalletAddress={elem.fromWalletAddress}
              closeModal={setApprove}
              name={elem.propAddressId}
              propAddressId={elem.propAddressId}
              imageUrl={elem.imageUrl}
              offerPrice={elem.offerPrice}
            />
          )}
    
                          <td>
                            <img
                              className={`${styles.imgOffer}`}
                              src={elem.imageUrl || "-"}
                              alt=""
                            />
                          </td>
                          <td>{elem.tokenId || "-"}</td>
                          <td>{elem.propAddressId || "-"}</td>
                          <td>
                            <p
                              style={{
                                maxWidth: "200px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {elem.fromWalletAddress || "-"}
                            </p>
                          </td>
                          <td>{elem.offerPrice || "-"}</td>
                          <td>{elem.offerStatus || "-"}</td>
                          <td>{elem.offerExpires || "-"}</td>
                          <td>
                          {elem.offerStatus ==="BID" && (
                          <button
                    onClick={() => {
                      setApprove("A");
                    }}
                    className="cta-btn"
                  >
                    Approve
                  </button>
                          )}
                      
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
