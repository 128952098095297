import React from "react";
import img from "../../Assets/enterprise.svg";
import styles from "./styles/Learn.module.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
function Learn() {
  return (
    <div className="container">
      <div className={styles.learn}>
        <img src={img} alt="" />
        <h1>
          Want to learn more? Email us 
        </h1><h2><i> support@realtyswap.io</i></h2>
        <p>We are exited in bringing forward the next best thing in Real Estate!</p>
        <Link to={"/Settings/" } >
             <button className="cta-btn">
                <FontAwesomeIcon icon={faPlus} />
                Create New Profile
              </button>
              </Link>
           
      </div>
    </div>
  );
}

export default Learn;
