import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import bg from "../../Assets/blog1.png";
import bg1 from "../../Assets/blog2.png";
import bg2 from "../../Assets/blog3.png";
import bg3 from "../../Assets/blog4.png";
import icon from "../../Assets/logowhite.png";
import styles from "./styles/Blog.module.css";
import BlogBox from "../../Components/BlogBox/BlogBox";

function Blog() {
  return (
    <div className="container">
      <div className={styles.blog}>
        <Splide
          className={styles.splide}
          options={{
            perPage: 3,
            perMove: 1,
            drag: true,
            wdith: "100%",
            pagination: false,
            arrows: false,
            gap: "1rem",
            breakpoints: {
              1300: {
                perPage: 2,
              },
              750: {
                perPage: 1,
                gap: "0rem",
                width: "100%",
              },
            },
          }}
        >
          <SplideSlide>
          <BlogBox icon={icon} heading={"What are NFT's"} bg={bg1} article="what-are-nfts" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Your HOUSE on the BlockChain"} bg={bg2} article="house-on-blockchain" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Understanding Blockchain and Cryptocurrency"} bg={bg3} article="understanding-cryptocurrency" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Are we ready for Web3.0 Real Estate"} bg={bg} article="web3-real-estate" />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Blog;
