import React, { useState } from "react";

import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotifBox from "../../Components/NotifBox/NotifBox";
import { useParams } from "react-router-dom";
import Select from "react-select/creatable";
import { FaFilePdf } from "react-icons/fa";
import styles from "./Token.module.css";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faD
} from "@fortawesome/free-brands-svg-icons";
import {
  faUsers,
  faUnlock,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import isEmpty from "../../utils/is-empty";
import Loading from "../../Components/Loading/Loading";
import { faCircleInfo, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ReactGA from 'react-ga4';
import Pdf from '../../Assets/RealtyswapWP.pdf';
const initialState = {
  accountType: "EXTERNAL",
  bannerImageUrl: "",
  profileImageUrl: "",
  description: "",
  displayName: "",
  emailId: "",
  fbHandle: "",
  igHandle: "",
  redditHandle: "",
  tkHandle: "",
  twitterHandle: "",
  userName: "",
  walletAddress: "",
  walletChain: "ETH",
  servicezones:[]
};

const Token = () => {
 
  
  const [type, setType] = useState("broker");

const [openDrop, setOpenDrop] = useState(false);
const [dropdownContent, setDropdownContent] = useState("Realtor/Agent");
const [progress, setProgress] = useState("");
const { walletAddress } = "0x"

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [profileImgState, setProfileImgState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });
  const [bannerState, setBannerState] = useState({
    isLoading: false,
    dataUrl: "",
    file: "",
  });
  const [formParams, updateFormParams] = useState({
    servicezones: []
  });
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");
  const {
    getRootProps: getProfileRootProps,
    getInputProps: getProfileInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setProfileImgState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setProfileImgState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const {
    getRootProps: getBannerRootProps,
    getInputProps: getBannerInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const images = Array.from(acceptedFiles);
      const img = images[0];
      setBannerState((prevState) => {
        return { ...prevState, file: img };
      });

      const reader = new FileReader();

      reader.onload = () => {
        setBannerState((prevState) => {
          return { ...prevState, dataUrl: reader.result };
        });
      };
      reader.readAsDataURL(img);
    },
  });

  const uploadFile = (e, type) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("accountId","0x");
    formData.append("type","EXTERNAL");

    if (type === "profile") {
      setProfileImgState((prevState) => {
        return { ...prevState, isLoading: true };
      });

      formData.append("file", profileImgState.file);
    } else {
      setBannerState((prevState) => {
        return { ...prevState, isLoading: true };
      });

      formData.append("file", bannerState.file);
    }

    axios
      .post(`/uploadFile`, formData)
      .then((res) => {
        if (type === "profile") {
          setFormState((prevState) => {
            return { ...prevState, profileImageUrl: res.data.fileName };
          });
        } else {
          setFormState((prevState) => {
            return { ...prevState, bannerImageUrl: res.data.fileName };
          });
        }

        toast.success(
          `${
            type === "profile" ? "Profile image" : "Profile "
          } uploaded successfully`
        );
      })
      .catch((err) => {
        console.log(err);
      toast.error("Uh Oh! Something's not right. Please try again later.");
      })
      .finally(() => {
        if (type === "profile") {
          setProfileImgState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        } else {
          setBannerState((prevState) => {
            return { ...prevState, isLoading: false };
          });
        }
      });
  };


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    
    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };



  const formSubmitHandler = (e) => {
    e.preventDefault();

    setIsFormLoading(true);
    setFormState((prevState) => {
      return { ...prevState, walletAddress };
    });
    const serviceZones = [];
    formState.accountType = "EXTERNAL";
    formState.servicetype = dropdownContent;
    formParams.servicezones.map((el) => serviceZones.push(el.value)) ;
    formState.servicezones = serviceZones;
    
    if(!isValidEmail(formState.emailId)){
      toast.error("Please enter a Valid Email Address");
      setIsFormLoading(false);
      return;
    }
    if(formState.bannerImageUrl.length <=0){
      toast.error("Please Upload a Profile Picture First");
      setIsFormLoading(false);
      return;
    }
    axios
      .post("/profile", formState)
      .then((res) => {
        setNotif("success");
        setMessage("Thank you for Registring with Us!!");
        setIsFormLoading(false);
        setFormState(initialState);
       
      })
      .catch((err) => {
        console.log(err);
        toast.error("Uh Oh! Something's not right");
        setIsFormLoading(false);
      })
      .finally(() => {
        setIsFormLoading(false);
       
      });
  };

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
   
    <div className={styles.settings}>
        <h1>
         Tokenomics - $RSWAP
        </h1>
        <h2>
      
       <a href = {Pdf} target="_blank">Whitepaper</a> <FaFilePdf></FaFilePdf>
        </h2>
        <h2> Summary and Utility</h2>
        <p> 
        Real estate is one of the most lucrative asset classes, but it's also one of the most difficult to access for individual investors. Traditional real estate investing requires large amounts of capital, extensive due diligence, and complex legal agreements. 
        Real estate NFT marketplaces offer a new way for investors to access real estate assets, and our platform aims to take this a step further with the creation of a native token <b>$RSWAP</b> that incentivizes participation and rewards users for contributing to the platform's growth and development. <br></br>
        <br></br>
       <b> BSC Contract: </b><a href="https://bscscan.com/address/0xD47B98282459B096f43cf8F47B74A31dBB220828">0xD47B98282459B096f43cf8F47B74A31dBB220828</a>
        <br></br>
        <b> Supply: </b>: 2,000,000,000
        <br></br>
        <b> Max Supply: </b>  2,000,000,000
        <br></br>
       
</p>
<h2> Utility</h2>
        <p>

What is the value of $RSWAP?
<br></br>
<br></br>
<b>1. Payment for NFT purchases: </b>Our token will serve as the primary currency for purchasing NFTs representing real estate assets on our platform.
<br></br>
<br></br>
<b>2. Transaction fees: </b>Users will be able to pay transaction fees on the platform using our token, and we will offer discounts for users who hold a certain amount of tokens.
<br></br>
<br></br><b>3. Governance and decision-making:</b> Token holders will be able to participate in governance decisions for the platform, including voting on proposals for new features and changes to transaction fees.
<br></br>
<br></br>
<b>4. Access to premium features:</b> Users who hold a certain amount of tokens will have access to premium features on the platform, such as access to exclusive real estate assets or priority access to new listings.
<br></br>
<br></br>
<b>5. Incentivizing participation:</b> We will offer rewards in the form of our token to users who contribute to the growth and development of the platform, including referring new users , realtors and property managers, contributing data and content, and improving the user experience.
<br></br>
<br></br>
<b>6. Staking rewards:</b> We will offer staking rewards to users who hold a certain amount of tokens for a specified period of time, incentivizing users to hold onto their tokens and reducing token supply on the market.
<br></br>
<br></br>
<b>7. Crowdfunding for real estate projects:</b> Our platform will allow real estate developers to create NFTs representing their upcoming projects and raise funds through a crowdfunding campaign on the platform. Our token will be used as the primary currency for these crowdfunding campaigns, incentivizing users to hold the token and participate in these projects.
<br></br>
<br></br>
<b>8. Access to data and analytics: </b>Users who hold a certain amount of tokens will have access to real estate data and analytics on the platform, providing valuable insights for real estate investors and enthusiasts.
<br></br>
<br></br>
<b>9. Premium content and educational resources: </b>Users who hold a certain amount of tokens will have access to premium content and educational resources on the platform, providing valuable knowledge and skills for users.
<br></br>
<br></br>
<h3> Token Economics:</h3>
<br></br>
Our token will be a deflationary token with a fixed total supply of 2 billion tokens. We will burn a portion of transaction fees on the platform, reducing the total supply of tokens over time. We will also offer token rewards for participation in network maintenance and governance, incentivizing users to contribute to the growth and development of the platform.
</p>
     <br></br>
      {isFormLoading && <Loading progress={progress} />}

     {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }

      <div className={styles.bottomSettings}>
    
        
      </div>
    </div>
  );
};

export default Token;
