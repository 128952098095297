import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ReactGA from 'react-ga4';

import styles from "./styles/Explore.module.css";
function Explore() {
  const navigate = useNavigate();


  const [placeAddress, setPlaceAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  // when user selects an address from the drop down this api gets the data for the nft of that address ------------
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setPlaceAddress(value);
    setCoordinates(ll);

    navigate(`/Explore/${ll.lat}/${ll.lng}`);
  };
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className={styles.explore}>
      <div className={styles.inputWrapper}>
        <div className={styles.inputDiv}>
          <PlacesAutocomplete
            value={placeAddress}
            onChange={setPlaceAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <div
                  className={`${styles.searchBar} ${
                    suggestions.length > 0 ? styles.borderRadius : ""
                  }`}
                >
                  <input
                    {...getInputProps({
                      placeholder: "Enter an  Address, City or ZipCode...",
                      className: "location-search-input",
                    })}
                    required
                  />
                  <FontAwesomeIcon
                    onClick={() => navigate("/Explore")}
                    icon={faSearch}
                  />
                </div>
                {suggestions.length > 0 && (
                  <div
                    style={{ zIndex: 6, height: "auto" }}
                    className={styles.stateDrop}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          // className={styles.searchedItem}
                          key={suggestion.id}
                          {...getSuggestionItemProps(suggestion, {
                            className: `${className} ${styles.searchedItem}`,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </PlacesAutocomplete>
          {/* <div className={styles.inputDiv}>
          <div
            className={`${styles.searchBar} ${
              filteredStates.length > 0 && styles.borderRadius
            }`}
          >
            <input
              onChange={searchHandler}
              type="text"
              placeholder="Enter a State"
            />
            <FontAwesomeIcon
              onClick={() => navigate("/Explore")}
              icon={faSearch}
            />
          </div>
          <div
            className={`${styles.stateDrop} ${
              filteredStates.length > 0 && styles.displayDrop
            }`}
          >
            {filteredStates.map((elem, idx) => {
              return (
                <Link
                  key={elem.abbreviation}
                  to={`/Explore/${elem.abbreviation}`}
                  className={styles.searchedItem}
                >
                  {elem?.name}
                </Link>
              );
            })}
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default Explore;
