import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: null,
};

const reducer = createSlice({
  name: "wallet-reducer",
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.address = action.payload;
    },
  },
});

export default reducer.reducer;
export const { setWalletAddress } = reducer.actions;
