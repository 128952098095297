import React from "react";

import styles from "./Progress.module.css";

const Progress = ({ progress }) => {
  return (
  <div className={styles.container}>
      <h2>Please wait, Interacting with BlockChain.</h2>
      <br></br>
      <div className={styles.progress}>
        <div className={styles.progressbar} style={{ width: progress }}></div>
        {progress}
      </div>
    </div>
  );
};

export default Progress;
