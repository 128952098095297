import React from "react";
import styles from "./styles/Review.module.css";
import img from "../../Assets/profile.png";
function Review() {
  return (
    <div className="container">
      <div className={styles.review}>
        <img src={img} alt="" />
        <p>
          "Realty Swap  NFT Market place is a great way for anyone to list their NFTs on the blockchain. It makes it easy for anyone to register their Home on the BlockChain as an NFT, thereby creating a Unique Token for their Property.The interface was simple and elegant taking the complexites away for most home owners.It is a great way to get your foot in the door of the crypto world.”
        </p>
        <h2>Marc-Antoine Proulx </h2>
        <h3>Manager of Operations</h3>
       </div>
    </div>
  );
}

export default Review;
