import React from "react";
import styles from "./PriceBox.module.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function PriceBox({ desc, head, btn,href }) {
  return (
    <div className={styles.priceBox}>
      <div className={styles.mainBox}>
        <h2>{head}</h2>
        <p>{desc}</p>
        <a href={href} className="cta-btn">
          {btn} <FontAwesomeIcon icon={faArrowRight} />
        </a>
      </div>
    </div>
  );
}

export default PriceBox;
