import React from "react";
import img from "../../Assets/enterprise.svg";
import styles from "./styles/Hero.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaEthereum } from "react-icons/fa";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="container">
      <div className={styles.hero}>
        <h2 className={styles.subHead}>One Time Registration</h2>
        <h1 className={styles.head}>Transparent Pricing Model</h1>
        <div className={styles.boxRow}>
          <div className={styles.box}>
            <div className={`${styles.boxTop} ${styles.orange}`}>
              Minting 
            </div>
            <h1>Residential</h1>
            <p>Register on the blockchain.</p>
            <h2>
              <sup ><FaEthereum/></sup>0.05
            </h2>
            <p>
            + Gas Fees
            </p>
            <Link to={"/Create/" } >
            <button className="cta-btn">Get Started</button>
            </Link>
            <hr />
          </div>
          <div className={styles.box}>
            <div className={`${styles.boxTop} ${styles.blue}`}>
            Listing
            </div>
            <h1>Residential</h1>
            <p>List your property for Sale.</p>
            <h2>
            <sup ><FaEthereum/></sup>0.05
            </h2>
            <p>
            + Gas Fees
            </p>
            <Link to={"/Create/" } >
            <button className="cta-btn">Get Started</button>
            </Link>
            <hr />
           
          </div>
          <div className={styles.box}>
            <div className={`${styles.boxTop} ${styles.orange}`}>
              Enterprice
            </div>
            <h1>Commercial</h1>
            <p>Volume pricing</p>
            <h2>
            <img src={img} alt="" /></h2>
            <Link to={"/Contact/" } >
            <button className="cta-btn">Lets Talk</button>
            </Link>
            <hr />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
