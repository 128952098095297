import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import blogImg from "../../Assets/blog-img.jpg";
import user from "../../Assets/user-placeholder.jpeg";
import styles from "./Blog.module.css";
import BlogBox from "../../Components/BlogBox/BlogBox";
import bg from "../../Assets/blog1.png";
import bg1 from "../../Assets/blog2.png";
import bg2 from "../../Assets/blog3.png";
import bg3 from "../../Assets/blog4.png";
import icon from "../../Assets/logowhite.png";
function Blog4() {
  return (
    <div className="container">
      <div className={styles.blog}>
        <h1>Web3.0 Real Estate - New Age</h1>
        <p className={styles.date}>
          <span>July 18, 2022 By</span> John Wayne . 
        </p>
        <img src={bg2} alt="" />
        <p>
        Real estate is the new Bitcoin. It is all about the location. It is all about the brand. It is all about the art. It is all about the creativity. It is all about customisation. Realty swap NFT market place makes it possible for people to trade their properties as digital assets on blockchain and get paid in cryptocurrency tokens which can be exchanged with fiat money or other cryptocurrencies as well as real estate properties around the world through our online platform.
        </p>
        <p>
        NFT is the next generation of Real estate. It's a new asset class, it's a new way of doing business, and it's the future of real estate. NFTs represent rights to ownership in real estate property; these rights are fully transferable through smart contracts on the blockchain. And unlike traditional properties that require physical possession of keys, NFTs can be traded instantaneously at any time with no middlemen needed (or fees).
        </p>
        <div className={styles.figure}>
          <img src={bg1} alt="" />
          <span>NFT is the next generation of Real estate#</span>
        </div>
        <p>
        In real estate, it's all about the location. That's why you pay a premium for something on a great street or with a water view.

NFTs are digital assets that can be traded globally. For that reason, location is not as important to them as it is to real estate. In fact, an NFT may have no special characteristics except for its rarity and collectability (or lack of).
        </p>
        <h6>NFT packs a punch, in real estate , crypto and more</h6>
        <p>
          Cute, mutant aliens called Zoonies are a highly developed civilization
          living on Planet Zoon. Zoonies lore tells stories of these aliens, who
          chose to stay isolated on their planet for thousands of years, until
          recently allowing humans to visit for the first time. Zoonies utility
          includes a playable arcade, 2D metaverse and more. 8,888 Zoonies will
          be available on RealtySwap starting on July 20, 2022.
        </p>
        <div className={styles.figure}>
          <img src={bg} alt="" />
        </div>
        <h6>It is all about the customisation</h6>
        <p>
        The brand is the face of a company, but it's also something more. A brand is your story, your voice and tone of voice, it’s what sets you apart from competitors and how you want to be perceived by customers.

A good brand can make or break a business. When your brand isn't resonating with customers like it did in the past or when things change in your industry then this could be an indication that rebranding might help boost sales again.

Branding is important because people identify with brands. They associate feelings and emotions with them which makes them easier to buy from again if they are happy with what they have purchased before because of the good feeling associated with their favourite products/services provided by that particular seller - so long as those feelings aren't negative ones!
        </p>
        <div className={styles.figure}>
          <img src={bg3} alt="" />
        </div>
        <h6>It is all about the trading and selling</h6>
        <p>
          With the start of our launchpad, our goal remains to build the best
          Solana experience in the NFT ecosystem.
        </p>
        <p>On RealtySwap, you’ll find: </p>
        <ul>
          <li>
            <p>
              <strong>
                Your Solana collections will automatically appear on RealtySwap:{" "}
              </strong>
              Art is the expression of an artist. It’s a form of expression, communication and self-expression. It allows us to communicate our thoughts and feelings through a variety of mediums including painting, music, sculpture and more.

Reality Check: The world has lost touch with its creative side. We live in a digital world where people spend countless hours on social media scrolling through content that is curated for them by algorithms who are not really interested in their well-being or creativity. In this new digital age we have become disconnected from our inner voice; the voice that comes from within us when we are truly inspired and driven by one thing: passion!
            </p>
          </li>
          <li>
            {" "}
            <p>
            The NFT market place is great for both investors and home sellers. Investors will be able to make money by trading the NFTs on the marketplace, either by selling them or buying them back at a higher price later on. For example, let's say that you want to sell your house for $100K but you don't know how much it will be worth in 10 years' time. You could create an NFT based on your house's value and sell it now, knowing that if someone buys it off of you they can always resell it later at a higher price than what they paid initially (as long as they have access to Ethereum). The seller would also have access to any profits made through trading their property with other users who have bought the tokenized version of their asset.

This method has already been used successfully by several real estate agencies around the world; one example being Zillow which allows people all over North America (and Europe) to buy and sell properties online using cryptocurrency such as Ether or Bitcoin (which are also known as tokens). Another example is LARA Token: an Australian company offering similar services locally across Australia and New Zealand; however due its limited availability this may not yet be considered mainstream enough for global uptake yet - although maybe one day 
            </p>
          </li>
        </ul>
      
        <div className={styles.bottomBlog}>
          <div className={styles.userRow}>
            <img src={user} alt="" />
            <h2>John Wayne </h2>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3>Previous Post</h3>
              <a href="#">House on BlockChain</a>
            </div>
            <div className={styles.col}>
              <h3>Next Post</h3>
              <a href="#">What Are NFT's￼</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cont2}>
        <h1>Related Articles</h1>
        <Splide
          className={styles.splide}
          options={{
            perPage: 3,
            perMove: 1,
            drag: true,
            wdith: "100%",
            pagination: false,
            arrows: false,
            gap: "1rem",
            breakpoints: {
              1300: {
                perPage: 2,
              },
              750: {
                perPage: 1,
                gap: "0rem",
                width: "100%",
              },
            },
          }}
        >
        <SplideSlide>
          <BlogBox icon={icon} heading={"What are NFT's"} bg={bg1} article="what-are-nfts" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Your HOUSE on the BlockChain"} bg={bg2} article="house-on-blockchain" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Understanding Blockchain and Cryptocurrency"} bg={bg3} article="understanding-cryptocurrency" />
          </SplideSlide>
          <SplideSlide>
             <BlogBox icon={icon} heading={"Are we ready for Web3.0 Real Estate"} bg={bg} article="web3-real-estate" />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Blog4;
