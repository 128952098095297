import styles from "./ListNft.module.css";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Card from "../../Components/Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import Marketplace from "../../RealtySwap.json";
import NotifBox from "../../Components/NotifBox/NotifBox";

import {
  faArrowTrendDown,
  faArrowTrendUp,
  faChevronDown,
  faDollarSign,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import ReactGA from 'react-ga4';

const ListNft = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState("");
  const [openDrop1, setOpenDrop1] = useState(false);
  const [dropVal1, setDropVal1] = useState("ETH");
  const [type, setType] = useState("fixed");
  const { address: walletAddress } = useSelector((state) => state.wallet);
  const ethers = require("ethers");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [message, updateMessage] = useState("");
  const [success, setNotif] = useState("");
  const [messagetxt, setMessage] = useState("");

  const [formParams, updateFormParams] = useState({
    minBidAmount: "",
    startTime: "",
    endTime: "",
  });

  async function listAsset(e) {
    e.preventDefault();
    if (!walletAddress) {
      toast.error("Please connect your MetaMask Wallet");
      return;
    }
    setIsFormLoading(true);
    setProgress("10%");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    const networkName = network.name;
    if(networkName != "homestead"){
      setNotif("failed");
      setMessage("Thank you for Visiting us. Please connect wallet to Ethereum Mainnet!!");
      return;
    }
    const signer = provider.getSigner();
    //Pull the deployed contract instance
    let contract = new ethers.Contract(
      Marketplace.address,
      Marketplace.abi,
      signer
    );

    setProgress("30%");
    try {
      updateMessage("Please wait.. Listing NFT for Sale on the BlockChain..");
      let listingPrice = await contract.getListPrice();
      listingPrice = listingPrice.toString();
      if(formParams.minBidAmount <=0){
        setNotif("failed");
        setMessage("Please Enter a valid Minimum Bid Amount");
        return;
      }
      const minBidAmount = ethers.utils.parseUnits(
        formParams.minBidAmount,
        "ether"
      );
      //let putOnSale = await contract.putOnSale(2,price);
      //  startAuction(uint _biddingStartTime,uint _biddingendtime , address payable _beneficiary , uint256 tokenId) public onlyTokenHolders(tokenId) returns(uint256)
            const tokenId = data.asset.tokenId;
            const startTime = formParams.startTime
              ? Date.parse(formParams.startTime)
              : 0;
            const endTime = formParams.endTime ? Date.parse(formParams.endTime) : 0;
            setProgress("40%");
            let putOnSale = await contract.ListNFTForSale(
              startTime,
              endTime,
              walletAddress,
              tokenId,
              minBidAmount,
              {
                value: listingPrice,
              }
            );
            setProgress("60%");
            //console.log("Message " + await contract.methods.getMessage());
            let txReceipt = await putOnSale.wait();
            const [auctionEvent] = txReceipt.events;
            console.log("auctionEvent - " + auctionEvent);
            const { _auctionid } = auctionEvent.args;
            console.log("_auctionid - " + _auctionid);
   
      //API INTEGRATION---------------
      setLoading(true);
      setProgress("80%");
      axios
        .post("/asset/list", {
          listForSale: true,
          minOfferPrice: formParams.minBidAmount,
          offerEnd: endTime,
          offerStart: startTime,
          ownerWalletAddress: walletAddress,
          propAddressId: data.asset.propAddressId,
          priceChain: "ETH",
          tokenId: tokenId,
        })
        .then(() => {
         // toast.success("Successfully listed your NFT For Sale@@");
          setNotif("success");
          setMessage("Successfully listed your NFT For Sale");
       
        })
        .catch(() => {
          toast.error("Uh Oh! an error occured");
          setNotif("failed");
          setMessage("Unable to List NFT on the MarketPlace!.Pls try agian Later");
       
        })
        .finally(() => {
          updateMessage("");
          updateFormParams({
            minBidAmount: "",
            startTime: "",
            endTime: "",
          });
        });
        setProgress("100%");
    } catch (error) {
     
      const endIndex = error.message.search("{");
      if (endIndex >= 0) {
        //toast.error(error.message);
        setNotif("failed");
        setMessage("Unable to List NFT on the MarketPlace!.Pls try agian Later" + error.message);
      }
      return;
      //toast.error("Unbale to list NFT for Sale.");
    } finally {
      setIsFormLoading(false);
      setLoading(false);
      //navigate("/");
    }
  }

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);

    axios
      .get(`/asset/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast.error("Uh Oh! Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className={styles.listNftWrap}>
      {isFormLoading && <Loading progress={progress} />}
      {(success=="success" || success=="failed") &&  <NotifBox success={success=="success"?true:false} text={messagetxt} closeModal={setNotif}/> }
 
      <div className={styles.listNft}>
        <form onSubmit={listAsset}> 
          <h2>List NFT Property For Sale</h2>
          <div className={styles.inputCol}>
            <div className={styles.inputRow}>
              <h5>Type</h5>
              <p>
                <FontAwesomeIcon icon={faInfoCircle} />
              </p>
            </div>
            <div className={styles.typeRow}>
              <div
                className={`${styles.typeBox} ${
                  type === "fixed" ? styles.activeType : ""
                }`}
                onClick={() => {
                  setDropVal1("ETH");
                  setType("fixed");
                }}
              >
                <FontAwesomeIcon icon={faDollarSign} />
                <h2>Fixed Price</h2>
              </div>
              <div
                onClick={() => {
                  setType("timed");
                }}
                className={`${styles.typeBox} ${
                  type === "timed" ? styles.activeType : ""
                }`}
              >
                <FontAwesomeIcon icon={faClock} />
                <h2>Accept Offers</h2>
              </div>
            </div>
          </div>
          {type === "fixed" ? (
            // fixed auction ------------------------
            <>
              <div className={styles.inputCol}>
                <div className={styles.inputRow}>
                  <h5>Price</h5>
                  <p>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </p>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.dropDown}>
                    <button
                      type={"button"}
                      onClick={() => {
                        setOpenDrop1((prev) => {
                          return !prev;
                        });
                      }}
                    >
                      <p>
                        <FontAwesomeIcon icon={faEthereum} /> {dropVal1}
                      </p>{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <div
                      className={`${styles.mainDrop} ${
                        openDrop1 && styles.activeDrop
                      }`}
                    >
                      <ul>
                        <li
                          onClick={(e) => {
                            setDropVal1(e.target.innerText);
                            setOpenDrop1(false);
                          }}
                        >
                          ETH
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  <input type="number" placeholder="Amount" />
                </div>
              </div>
              <div className={styles.flex}>
                <div className={styles.inputCol}>
                  <div className={styles.inputRow}>
                    <h5>Duration Start</h5>
                  </div>
                  <div className={styles.inputRow}>
                    <input type={"datetime-local"} />
                  </div>
                </div>
                <div className={styles.inputCol}>
                  <div className={styles.inputRow}>
                    <h5>Duration End</h5>
                  </div>
                  <div className={styles.inputRow}>
                    <input type={"datetime-local"} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            // timed aution --------------------------------
            <>
              <div className={styles.inputCol}></div>
              <div className={styles.inputCol}>
                <div className={styles.inputRow}>
                  <h5>Minimum Offer Price</h5>
                  <p>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </p>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.dropDown}>
                    <button
                      type={"button"}
                      onClick={() => {
                        setOpenDrop1((prev) => {
                          return !prev;
                        });
                      }}
                    >
                      <p>
                        <FontAwesomeIcon icon={faEthereum} /> {dropVal1}
                      </p>{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <div
                      className={`${styles.mainDrop} ${
                        openDrop1 && styles.activeDrop
                      }`}
                    >
                      <ul>
                        <li
                          onClick={(e) => {
                            setDropVal1(e.target.innerText);
                            setOpenDrop1(false);
                          }}
                        >
                          ETH
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  <input
                    onChange={(e) =>
                      updateFormParams((prev) => {
                        return { ...prev, minBidAmount: e.target.value };
                      })
                    }
                    value={formParams.minBidAmount}
                    type="number"
                    pattern="[0-9]*"
                    required
                    placeholder="Amount"
                  />
                </div>
              </div>
              <div className={styles.flex}>
                <div className={styles.inputCol}>
                  <div className={styles.inputRow}>
                    <h5>Duration Start</h5>
                  </div>
                  <div className={styles.inputRow}>
                    <input
                      onChange={(e) =>
                        updateFormParams((prev) => {
                          return { ...prev, startTime: e.target.value };
                        })
                      }
                      type={"datetime-local"}
                    />
                  </div>
                </div>
                <div className={styles.inputCol}>
                  <div className={styles.inputRow}>
                    <h5>Duration End</h5>
                  </div>
                  <div
                    onChange={(e) =>
                      updateFormParams((prev) => {
                        return { ...prev, endTime: e.target.value };
                      })
                    }
                    className={styles.inputRow}
                  >
                    <input type={"datetime-local"} />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={styles.fees}>
            <div className={styles.inputRow}>
              <h3>Fees</h3>
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className={styles.inputRow}>
              <p>Listing Fees</p>
              <p>0.05 ETH</p>
            </div>
          </div>
          <button  type="submit" className="cta-btn">
            Complete Listing
          </button>
        </form>
        <div className={styles.rightList}>
          <h2>Preview</h2>
          <Card {...data?.asset} />
        </div>
      </div>
    </div>
  );
};

export default ListNft;
