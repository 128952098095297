import { faMailForward, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import styles from "./ShareModal.module.css";
import {
  faArrowUp,
  faShare,
  faHeart as solidHeart,
  faPhone,
  faList,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faReddit, faTwitter } from "@fortawesome/free-brands-svg-icons";
const ShareModal = ({ name, bed, bath, img, price, closeModal }) => {
  const [copied, setCopied] = useState(false);
  const copyUrl = () => {
    const URL = window.location.href;
    navigator.clipboard.writeText(URL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <>
      <div className={styles.overlay} onClick={() => closeModal(false)}></div>
      <div className={styles.shareModal}>
        <div className={styles.shareHead}>
          <h1>Share</h1>
          <FontAwesomeIcon icon={faXmark} onClick={() => closeModal(false)} />
        </div>
        <div className={styles.flex}>
          <img src={img} alt="" />
          <div className={styles.col}>
            <h2>{name}</h2>
            <p>
              {bed} beds, {bath} ba ,{" "}
              <NumericFormat
                prefix="$"
                value={price}
                allowLeadingZeros
                displayType="text"
                thousandSeparator=","
              />
            </p>
          </div>
        </div>
        <div className={styles.flex}>
        <FontAwesomeIcon size={70}
                   icon={faFacebook}
                  />
                  <FontAwesomeIcon
                    icon={faTwitter}
                  />
                  <FontAwesomeIcon
                    icon={faReddit}
                  />
                   <FontAwesomeIcon
                    icon={faInstagram}
                  />
                       <FontAwesomeIcon
                    icon={faMailForward}
                  />
                </div>
        <button onClick={copyUrl} className="cta-btn">
          Copy Link
        </button>
        {copied && <p>Link copied to clipboard</p>}
      </div>
    </>
  );
};

export default ShareModal;
