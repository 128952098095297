//require('dotenv').config();
const key = "9ed50d5ef4762ee42b82";
const secret =
  "06532610eb5927e4e62b1c1fe5276143ad2121def1f67d5f3c084bded161401c";

const axios = require("axios");
const FormData = require("form-data");

export const uploadJSONToIPFS = async (JSONBody) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  //making axios POST request to Pinata ⬇️
  return axios
    .post(url, JSONBody, {
      headers: {
        pinata_api_key: key,
        pinata_secret_api_key: secret,
      },
    })
    .then(function (response) {
      return {
        success: true,
        pinataURL:
          "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash,
      };
    })
    .catch(function (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    });
};

export const uploadFileToIPFS = async (file, address) => {
  // const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  //making axios POST request to Pinata ⬇️
  const type = "IMG";
  let data = new FormData();
  data.append("file", file);

  return axios
    .post(`/uploadFile?accountId=${address}&type=${type}`, data)
    .then(function (response) {
      // console.log("image uploaded", response.data.fileName);
      return {
        success: true,
        url: response.data.fileName,
      };
    })
    .catch(function (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    });
};
