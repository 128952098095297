import React from "react";
import styles from "./Loading.module.css";
import loaderGif from "../../Assets/loader.gif";
import Progress from "../Progress/Progress";
function Loading({ progress }) {
  return (
    <div className={styles.loading}>
      {progress ? (
        <div className={styles.progressWrap}>
          <Progress progress={progress} />
        </div>
       ) : (
        <img src={loaderGif} alt="" />
      )}
    </div>
  );
}

export default Loading;
